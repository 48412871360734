import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import httpsClient, { addAccessTokenInterceptor } from "../../../config/https";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../components/Loader";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InformationCircleIcon, PlayCircleIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";

const isValidUUID = (uuid: string) => {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(uuid);
};

export default function Courses() {
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [durations, setDurations] = useState([]);
  const [courseMetrics, setCourseMetrics] = useState([]);
  const [modulesCount, setModulesCount] = useState(0);
  const bookmarkedValue = localStorage.getItem("bookmarked-courses");
  const [bookmarked, setBookmarked] = useState<boolean>(bookmarkedValue === "true");
  const initialModules = (localStorage.getItem("modules") || "").split(",");
  const [selectedModules, setSelectedModules] = useState<{ [key: string]: boolean }>(
    initialModules.filter((item) => !!item && isValidUUID(item)).reduce((acc, uuid) => ({ ...acc, [uuid]: true }), {})
  );
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const onboardingCourses = localStorage.getItem("onboarding-courses") || "";
  const [onboardingOpen, setOnboardingOpen] = useState<boolean>(onboardingCourses !== "true");

  const getCourses = async () => {
    const modules: string[] =
      selectedModules && Object.keys(selectedModules).filter((key: any) => selectedModules[key] === true);
    setIsFetching(true);

    await httpsClient
      .get(`/api/v1/courses?lang=${currentLanguage}&modules=${modules.join(",")}&bookmarked=${bookmarked}`)
      .then((response) => {
        setCourses(response.data);
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const handleBookmark = async (id: string, isBookmarked: boolean, e: any) => {
    e.preventDefault();
    if (!isBookmarked) {
      await httpsClient
        .post(`/api/v1/courses/bookmark`, { id })
        .then(() => {
          const newCourses: any = courses.map((course: any) =>
            course.id === id ? { ...course, isBookmarked: true } : course
          );
          setCourses(newCourses);
        })
        .catch(() => {});
    } else {
      await httpsClient
        .delete(`/api/v1/courses/bookmark?course_id=${id}`)
        .then(() => {
          const newCourses: any = courses.map((course: any) =>
            course.id === id ? { ...course, isBookmarked: false } : course
          );
          if (bookmarked) {
            const indexToDelete = newCourses.findIndex((e: any) => e.id === id);
            if (indexToDelete !== -1) {
              newCourses.splice(indexToDelete, 1);
            }
          }
          setCourses(newCourses);
        })
        .catch(() => {});
    }
  };

  const handleBookmarkFilter = async () => {
    localStorage.setItem("bookmarked-courses", String(!bookmarked));
    setBookmarked(!bookmarked);
  };

  const getCourseMetrics = async () => {
    await httpsClient
      .get(`/api/v1/courses/metrics`)
      .then((response) => {
        setCourseMetrics(response.data);
      })
      .catch(() => {});
  };

  const getCourseMetric = (course: any): any => {
    return courseMetrics.find((e: any) => e.course_id === course.id);
  };

  const getModules = async () => {
    const response = await httpsClient.get(`/api/v1/courses/modules`);
    setModules(response.data);
    const obj: any = {};
    response.data.forEach((e: any) => (obj[e.id] = true));
    const _selectedModules: string[] =
      selectedModules && Object.keys(selectedModules).filter((key: any) => selectedModules[key] === true);
    if (Object.keys(_selectedModules)?.length === 0) {
      setSelectedModules(obj);
    }
    const localStorageModules = (localStorage.getItem("modules") || "").split(",");
    const validModules = response.data.map((e: any) => e.id);
    const validStorageModules = localStorageModules.filter((item) => validModules.includes(item));
    if (validStorageModules.length === 0) {
      setSelectedModules(obj);
    } else {
      localStorage.setItem("modules", validStorageModules.join(","));
    }
  };

  const handleCheckboxChange = (option: any) => {
    setSelectedModules((prevModules: any) => ({
      ...prevModules,
      [option]: !prevModules[option],
    }));
  };

  const getCourseDuration = (resource: string) => {
    const e: any = durations?.filter((e: any) => e.resource === resource);
    if (e[0]) {
      const absoluteMinutes = Math.floor(e[0]?.duration / 60);
      const absoluteSeconds = e[0]?.duration % 60;
      return `${String(absoluteMinutes).padStart(2, "0")}:${String(absoluteSeconds).padStart(2, "0")}`;
    }
    return "";
  };

  const getCourseDurationInSeconds = (resource: string) => {
    const e: any = durations?.filter((e: any) => e.resource === resource);
    if (e[0]) {
      return e[0]?.duration;
    }
    return 0;
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchResources = async () => {
      const extractedDurations: any = await Promise.all(
        courses
          .map((e: any) => e.resource)
          .filter(Boolean)
          .map(async (resource) => {
            if (resource) {
              const response = await httpsClient.get(
                `${process.env.REACT_APP_RESOURCES_URL}/${resource.replace(/\.(?=[^.]+$)/, "hls.")}`
              );
              const lines = response?.data?.split("\n");
              let duration = 0;
              lines.forEach((line: any) => {
                if (line.startsWith("#EXTINF:")) {
                  const segmentDuration = parseFloat(line.split(":")[1].split(",")[0]);
                  duration += segmentDuration;
                }
              });
              return {
                resource,
                duration,
              };
            }
          })
      );
      setDurations(extractedDurations);
    };

    if (courses?.length > 0) {
      fetchResources();
    }
  }, [courses]);

  useEffect(() => {
    const params = new URLSearchParams();
    const modules: string[] =
      selectedModules && Object.keys(selectedModules).filter((key: any) => selectedModules[key] === true);
    if (modules?.length > 0) {
      params.append("modules", modules.join(","));
    }
    setModulesCount(modules.length);
    localStorage.setItem("modules", modules.join(","));
    getCourses();
  }, [selectedModules]);

  useEffect(() => {
    setModulesCount(Object.keys(selectedModules).length);
    getModules();
  }, [user]);

  useEffect(() => {
    getCourses();
    getCourseMetrics();
  }, [user, currentLanguage, bookmarked]);

  return (
    <div className="bg-white">
      <div className="pb-12 sm:pb-12">
        <div className="mx-auto max-w-2xl text-center flex justify-center items-center">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("common.courses")}</h2>
          <InformationCircleIcon
            type="button"
            className="text-gray-400/70 hover:text-gray-400 w-5 cursor-pointer ml-2"
            aria-hidden="true"
            onClick={() => {
              setOnboardingOpen(true);
            }}
          />
        </div>
      </div>
      {modules.length > 0 && (
        <section aria-labelledby="filter-heading">
          <div className="bg-slate-50 -mx-6 sm:-mx-8 mb-6">
            <div className="px-6 sm:px-8 py-3 sm:flex sm:items-center">
              <div className="mt-2 sm:mt-0">
                <div className="-m-1 flex flex-wrap items-center">
                  {modules.map((module: any) => (
                    <span
                      key={module.id}
                      className="m-1 inline-block sm:inline-flex w-full sm:w-auto items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                    >
                      <input
                        id={`filter-${module.id}`}
                        name={`${module.id}[]`}
                        defaultValue={module.id}
                        type="checkbox"
                        disabled={modulesCount === 1 && selectedModules[module.id] === true}
                        defaultChecked={selectedModules[module.id] === true}
                        onChange={() => handleCheckboxChange(module.id)}
                        className={classNames(
                          modulesCount === 1 && selectedModules[module.id] === true ? "opacity-50" : "opacity=100",
                          "h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-800 cursor-pointer"
                        )}
                      />
                      <label
                        htmlFor={`filter-${module.id}`}
                        className="w-10/12 sm:w-full inline-block ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 cursor-pointer"
                      >
                        {module.name?.filter((e: any) => e.language === currentLanguage)[0]?.name}
                      </label>
                    </span>
                  ))}
                  <div
                    onClick={handleBookmarkFilter}
                    className="flex items-center gap-x-2 px-4 py-2 cursor-pointer group/filter"
                  >
                    <BookmarkIcon
                      className={classNames(
                        bookmarked
                          ? "text-yellow-400 group-hover/filter:text-yellow-300"
                          : "text-gray-300 group-hover/filter:text-gray-400",
                        "w-5 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    <div className="text-sm text-gray-900 font-medium">{t("common.saved")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {(isFetching || isLoading) && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {!isFetching && !isLoading && (
        <ul role="list" className="divide-y divide-gray-100">
          {courses.map((course: any) => (
            <li key={course.id} className="relative flex justify-between gap-x-8 py-8">
              <div className="flex min-w-0 gap-x-6 w-full">
                <img
                  className="w-16 h-12 sm:w-36 sm:h-24 flex-none max-h-full rounded-md bg-gray-50 border border-gray-200"
                  src={`${process.env.REACT_APP_RESOURCES_URL}/${course.preview}`}
                  alt=""
                />
                <div className="min-w-0 flex-auto">
                  <div className="text-sm font-semibold leading-6 text-gray-900">
                    <NavLink to={`/courses/${course.id}`} className="group">
                      <span className="absolute inset-x-0 -top-px bottom-0" />
                      <div className="flex items-start gap-x-3">
                        <p className="text-xl font-bold leading-7 text-gray-900">{course.title}</p>{" "}
                        <div className="inline-block relative group/bookmark ml-auto">
                          <BookmarkIcon
                            onClick={(e: any) => handleBookmark(course.id, course.isBookmarked, e)}
                            className={classNames(
                              course.isBookmarked
                                ? "text-yellow-400 hover:text-yellow-300"
                                : "text-gray-200 hover:text-gray-300",
                              "w-5 flex-shrink-0 z-10 mr-0.5"
                            )}
                            aria-hidden="true"
                          />
                          <div className="absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-20 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                            {t("common.save")}
                            <div className="invisible absolute left-9 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="hidden sm:block mt-1 flex text-xs leading-5 text-gray-500">
                    {course.description.replace(/(<([^>]+)>)/gi, "").match(/[^.?!]+[.?!]/)[0]}
                  </div>
                  <div className="relative pt-1.5 xl:w-1/2">
                    <div className="flex items-center justify-center gap-x-3">
                      {course.resource && (
                        <div className="text-xs leading-5 text-gray-400 w-24">
                          ({getCourseDuration(course.resource)} min)
                        </div>
                      )}
                      {course.resource && getCourseDurationInSeconds(course.resource) > 0 && (
                        <>
                          <div className="w-full overflow-hidden h-1 text-xs flex rounded bg-gray-100">
                            <div
                              style={{
                                width: `${
                                  ((getCourseMetric(course)?.course_time || 0) /
                                    getCourseDurationInSeconds(course.resource)) *
                                  100
                                }%`,
                              }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-400"
                            ></div>
                          </div>
                          {!getCourseMetric(course)?.completed && (
                            <div className="text-xs text-gray-400 pl-2 ml-auto">
                              {Math.round(
                                ((getCourseMetric(course)?.course_time || 0) /
                                  getCourseDurationInSeconds(course.resource)) *
                                  100
                              )}
                              %
                            </div>
                          )}
                          {getCourseMetric(course)?.completed && (
                            <div className="pl-2 ml-auto">
                              <CheckCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mt-1">
                    <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1.5 text-xs font-medium text-gray-600 mr-2 mt-2 border border-transparent">
                      {course.modules?.name?.filter((e: any) => e.language === currentLanguage)[0]?.name}
                    </span>{" "}
                  </div>
                </div>
              </div>
              {/* <div className="flex shrink-0 items-center gap-x-4">
                <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              </div> */}
            </li>
          ))}
        </ul>
      )}
      {!isFetching && !isLoading && courses.length === 0 && (
        <div className="bg-white">
          <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900">{t("courses.noResults.title")}</h1>
            <p className="mt-2 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
              {t("courses.noResults.description")}
            </p>
          </div>
        </div>
      )}
      <Transition.Root show={onboardingOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOnboardingOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <PlayCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t("courses.onboarding.title")}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          <ul
                            role="list"
                            className="text-left mt-4 sm:mt-8 max-w-xl space-y-4 sm:space-y-8 text-gray-600"
                          >
                            <li className="flex gap-x-3">
                              <CheckCircleIcon
                                className="sm:mt-1 h-6 w-6 flex-none text-gray-900 items-start"
                                aria-hidden="true"
                              />
                              <span className="self-center">{t("courses.onboarding.item1")}</span>
                            </li>
                            <li className="flex gap-x-3">
                              <CheckCircleIcon
                                className="sm:mt-1 h-6 w-6 flex-none text-gray-900"
                                aria-hidden="true"
                              />
                              <span className="self-center">{t("courses.onboarding.item2")}</span>
                            </li>
                            <li className="flex gap-x-3">
                              <CheckCircleIcon
                                className="sm:mt-1 h-6 w-6 flex-none text-gray-900"
                                aria-hidden="true"
                              />
                              <span className="self-center">{t("courses.onboarding.item3")}</span>
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus:outline-none"
                      onClick={() => {
                        localStorage.setItem("onboarding-courses", "true");
                        setOnboardingOpen(false);
                      }}
                    >
                      {t("courses.onboarding.button")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
