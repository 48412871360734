import { PaperClipIcon } from "@heroicons/react/24/solid";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import httpsClient, { addAccessTokenInterceptor } from "../../../config/https";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../components/Loader";
import { VideoJS } from "../../../VideoJS";
import React from "react";
import { CheckCircleIcon, BookmarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

function isActiveCourse(id: string, currentId: string | undefined) {
  return id === currentId;
}

export default function Course() {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const { id } = useParams();
  const [course, setCourse] = useState({} as any);
  const [courses, setCourses] = useState([]);
  const [courseMetrics, setCourseMetrics] = useState([]);
  const [courseMetricsLoaded, setCourseMetricsLoaded] = useState(false);
  const [resource, setResource] = useState({} as any);
  const [durations, setDurations] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [currentTime, setCurrentTime] = useState(0 as number);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  const playerRef = React.useRef(null);

  const getCourse = async () => {
    setIsFetching(true);
    const response = await httpsClient.get(`/api/v1/courses/${id}`);
    setIsFetching(false);
    if (response?.data?.resource) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: `${process.env.REACT_APP_RESOURCES_URL}/${response.data.resource}`,
            type: "application/x-mpegURL",
          },
        ],
      };
      setResource(videoJsOptions);
    }
    setCourse(response.data);
  };

  const getCourses = async () => {
    const response = await httpsClient.get(`/api/v1/courses?modules=${course.modules?.id}&lang=${currentLanguage}`);
    setCourses(response.data);
  };

  const getCourseMetrics = async () => {
    setCourseMetricsLoaded(false);
    await httpsClient
      .get(`/api/v1/courses/metrics`)
      .then((response) => {
        setCourseMetrics(response.data);
        setCourseMetricsLoaded(true);
      })
      .catch(() => {});
  };

  const getCourseMetric = (course: any): any => {
    return courseMetrics.find((e: any) => e.course_id === course.id);
  };

  const getCourseDuration = (resource: string) => {
    const e: any = durations?.filter((e: any) => e.resource === resource);
    if (e[0]) {
      const absoluteMinutes = Math.floor(e[0]?.duration / 60);
      const absoluteSeconds = e[0]?.duration % 60;
      return `${String(absoluteMinutes).padStart(2, "0")}:${String(absoluteSeconds).padStart(2, "0")}`;
    }
    return "";
  };

  const getCourseDurationInSeconds = (resource: string) => {
    const e: any = durations?.filter((e: any) => e.resource === resource);
    if (e[0]) {
      return e[0]?.duration;
    }
    return 0;
  };

  const getResourcePresignedUrl = (key: string) => {
    httpsClient
      .post(`/api/v1/courses/resources/presignedUrl`, { key })
      .then((response) => {
        window.open(response.data.signedUrl, "_blank");
      })
      .catch(() => {});
  };

  const upsertCourseMetrics = async (time: number, completed?: boolean) => {
    const data = {
      course_id: course.id,
      course_time: time,
      completed,
    };
    await httpsClient.post(`/api/v1/courses/metrics`, data);
  };

  const throttle = (func: (...args: any[]) => Promise<void>, limit: number, interval: number) => {
    let inThrottle = false;

    return async (...args: any[]) => {
      if (!inThrottle) {
        inThrottle = true;
        await func(...args);
        setTimeout(() => {
          inThrottle = false;
        }, interval);
      }
    };
  };

  const throttledUpsertCourseMetrics = throttle(upsertCourseMetrics, 1, 1000);

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    player.on("ready", () => {
      const currentTime = getCourseMetric(course)?.course_time;
      player.currentTime(currentTime);
      setCurrentTime(currentTime);
    });

    player.on("timeupdate", () => {
      setCurrentTime(player.currentTime());
      throttledUpsertCourseMetrics(player.currentTime());
    });

    player.on("ended", () => {
      upsertCourseMetrics(player.currentTime(), true);
    });

    player.on("dispose", () => {});
  };

  const handleBookmark = async (id: string, isBookmarked: boolean, e: any) => {
    e.preventDefault();
    if (!isBookmarked) {
      await httpsClient
        .post(`/api/v1/courses/bookmark`, { id })
        .then(() => {
          const newCourses: any = courses.map((course: any) =>
            course.id === id ? { ...course, isBookmarked: true } : course
          );
          setCourses(newCourses);
        })
        .catch(() => {});
    } else {
      await httpsClient
        .delete(`/api/v1/courses/bookmark?course_id=${id}`)
        .then(() => {
          const newCourses: any = courses.map((course: any) =>
            course.id === id ? { ...course, isBookmarked: false } : course
          );
          setCourses(newCourses);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchResources = async () => {
      const extractedDurations: any = await Promise.all(
        courses
          .map((e: any) => e.resource)
          .filter(Boolean)
          .map(async (resource) => {
            if (resource) {
              const response = await httpsClient.get(
                `${process.env.REACT_APP_RESOURCES_URL}/${resource.replace(/\.(?=[^.]+$)/, "hls.")}`
              );
              const lines = response?.data?.split("\n");
              let duration = 0;
              lines.forEach((line: any) => {
                if (line.startsWith("#EXTINF:")) {
                  const segmentDuration = parseFloat(line.split(":")[1].split(",")[0]);
                  duration += segmentDuration;
                }
              });
              return {
                resource,
                duration,
              };
            }
          })
      );
      setDurations(extractedDurations);
    };

    if (courses?.length > 0) {
      fetchResources();
    }
  }, [courses]);

  useEffect(() => {
    const onLanguageChange = () => {
      navigate("/courses");
    };

    i18n.on("languageChanged", onLanguageChange);

    return () => {
      i18n.off("languageChanged", onLanguageChange);
    };
  }, [i18n]);

  useEffect(() => {
    if (course?.modules?.id) {
      getCourseMetrics();
      getCourses();
    }
  }, [course]);

  useEffect(() => {
    getCourseMetrics();
    getCourse();
  }, [id]);

  return (
    <div className="grid grid-cols-1 lg:gap-8 lg:grid-cols-6">
      <div className="col-span-4">
        {(isFetching || isLoading) && (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        )}
        {!isFetching && !isLoading && (
          <>
            {course.resource && courseMetricsLoaded && (
              <div className="pb-6">
                <VideoJS options={resource} onReady={handlePlayerReady} />
              </div>
            )}
            <div className="flex flex-col-reverse">
              <div className="mb-6">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900">{course.title}</h1>
                <p className="mt-1">
                  <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1.5 text-xs font-medium text-gray-600 mr-2 mt-2 border border-transparent">
                    {course.modules?.name?.filter((e: any) => e.language === currentLanguage)[0]?.name}
                  </span>{" "}
                </p>
                <p
                  className="course-description my-6 text-sm text-gray-900"
                  dangerouslySetInnerHTML={{ __html: course.description }}
                />
                {course.course_attachments && course.course_attachments.length > 0 && (
                  <div className="border-t border-gray-100 -mt-3 py-6 sm:col-span-2 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">{t("common.attachments")}</dt>
                    <dd className="mt-2 text-sm text-gray-900">
                      <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-100">
                        {course.course_attachments.map((attachment: any) => (
                          <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-5">
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                <span className="truncate font-medium">
                                  {attachment.filename}
                                  <br />
                                  <span className="text-gray-400 text-xs font-base">
                                    {attachment.key.split("/")?.pop()}
                                  </span>
                                </span>
                                {/* <span className="flex-shrink-0 text-gray-400">2.4mb</span> */}
                              </div>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <button
                                onClick={() => getResourcePresignedUrl(attachment.key)}
                                className="font-medium text-gray-900 hover:text-gray-800"
                              >
                                {t("common.download")}
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="col-span-2">
        <h2 className="text-base font-bold tracking-tight text-gray-900 mb-6">
          {course.modules?.name?.filter((e: any) => e.language === currentLanguage)[0]?.name}
        </h2>
        <ul role="list" className="divide-y divide-gray-100 [&>*:first-child]:pt-0">
          {courses.map((item: any) => (
            <li key={item.id} className="py-6">
              <NavLink to={`/courses/${item.id}`} className="group">
                <div className="flex min-w-0 gap-x-6">
                  <img
                    className="w-16 h-12 xl:w-24 xl:h-16 flex-none rounded-md bg-gray-50 border border-gray-200"
                    src={`${process.env.REACT_APP_RESOURCES_URL}/${item.preview}`}
                    alt=""
                  />{" "}
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-start gap-x-3">
                      <h3
                        className={classNames(
                          isActiveCourse(item.id, id) ? "text-gray-900" : "text-gray-900",
                          "text-sm font-semibold leading-5 group-hover:text-gray-900"
                        )}
                      >
                        {item.title}
                      </h3>
                      <div className="inline-block relative group/bookmark ml-auto">
                        <BookmarkIcon
                          onClick={(e: any) => handleBookmark(item.id, item.isBookmarked, e)}
                          className={classNames(
                            item.isBookmarked
                              ? "text-yellow-400 hover:text-yellow-300"
                              : "text-gray-200 hover:text-gray-300",
                            "w-5 flex-shrink-0 z-10 mr-0.5"
                          )}
                          aria-hidden="true"
                        />
                        <div className="absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-20 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                          {t("common.save")}
                          <div className="invisible absolute left-9 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center gap-x-2 pt-1.5">
                      {item.resource && (
                        <p className="text-xs leading-5 text-gray-400 w-32">({getCourseDuration(item.resource)} min)</p>
                      )}
                      {item.resource &&
                        item.id === course.id &&
                        courseMetricsLoaded &&
                        getCourseDurationInSeconds(item.resource) > 0 && (
                          <>
                            <div className="w-full overflow-hidden h-1 text-xs flex rounded bg-gray-100">
                              <div
                                style={{
                                  width: `${(currentTime / getCourseDurationInSeconds(item.resource)) * 100}%`,
                                }}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-400"
                              ></div>
                            </div>
                            {!getCourseMetric(course)?.completed &&
                              Math.round((currentTime / getCourseDurationInSeconds(item.resource)) * 100) < 100 && (
                                <div className="text-xs text-gray-400 pl-2 h-6 pt-1 ml-auto">
                                  {Math.round((currentTime / getCourseDurationInSeconds(item.resource)) * 100)}%
                                </div>
                              )}
                            {(getCourseMetric(course)?.completed ||
                              Math.round((currentTime / getCourseDurationInSeconds(item.resource)) * 100) === 100) && (
                              <div className="text-xs text-gray-400 pl-2 ml-auto">
                                <CheckCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                              </div>
                            )}
                          </>
                        )}
                      {item.resource &&
                        item.id !== course.id &&
                        courseMetricsLoaded &&
                        getCourseDurationInSeconds(item.resource) > 0 && (
                          <>
                            <div className="w-full overflow-hidden h-1 text-xs flex rounded bg-gray-100">
                              <div
                                style={{
                                  width: `${
                                    ((getCourseMetric(item)?.course_time || 0) /
                                      getCourseDurationInSeconds(item.resource)) *
                                    100
                                  }%`,
                                }}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-400"
                              ></div>
                            </div>
                            {!getCourseMetric(item)?.completed && (
                              <div className="text-xs text-gray-400 pl-2 h-6 pt-1 font-medium ml-auto">
                                {Math.round(
                                  ((getCourseMetric(item)?.course_time || 0) /
                                    getCourseDurationInSeconds(item.resource)) *
                                    100
                                )}
                                %
                              </div>
                            )}
                            {getCourseMetric(item)?.completed && (
                              <div className="pl-2 ml-auto">
                                <CheckCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
