import { CameraIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Controller, useForm } from "react-hook-form";
import { array, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Combobox, Dialog, DialogPanel, Radio, RadioGroup, Transition, TransitionChild } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { useMessagesContext, MessageType } from "../../contexts/messagesContext";
import { CurrentUserContext } from "../../contexts/userContext";
import { languages } from "../../models";
import ConfirmationDialog, { ConfirmationDialogType } from "src/components/ConfirmationDialog";
import Modal from "src/components/Modal";

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

const profileNetworkProfileRegex = /^(https?:\/\/)?(www\.)?(linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+|xing\.com\/profile\/[a-zA-Z0-9_-]+)\/?$/;

const researchNetworkProfileRegex = /^(https?:\/\/)?(www\.)?researchgate\.net\/profile\/[a-zA-Z0-9_-]+\/?$/

export default function Supervisor() {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [supervisor, setSupervisor] = useState({
    id: "",
    picture: "",
    image: "",
    email: "",
    first_name: "",
    last_name: "",
    language: "en",
    research_area: "",
    title: "",
    university: {},
    professional_network_profile: "",
    research_network_profile: "",
    user_fields: [],
  });
  const [query, setQuery] = useState("");
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState({});
  const { t, i18n } = useTranslation();
  const [profileImage, setProfileImage] = useState<any>();
  const profileImageInputRef = useRef(null);
  const [openProfile, setOpenProfile] = useState(false);
  const { addMessage } = useMessagesContext();
  const [profiles, setProfiles] = useState([]);
  const [activeProfile, setActiveProfile] = useState('supervisor');
  const [isDeleteAccountConfirmationOpen, setIsDeleteAccountConfirmationOpen] = useState<boolean>(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [fields, setFields] = useState<any>([]);

  const validationSchema = object().shape({
    email: string().required(),
    language: string().required(),
    first_name: string().required(t("common.required")),
    last_name: string().required(t("common.required")),
    title: string().nullable(),
    research_area: string().nullable(),
    university: object(),
    professional_network_profile: string()
      .notRequired()
      .matches(profileNetworkProfileRegex, {
        message: t("profile.supervisor.expertise.professionalNetworkProfile.error"),
        excludeEmptyString: true,
      }),
    research_network_profile: string()
      .notRequired()
      .matches(researchNetworkProfileRegex, {
        message: t("profile.supervisor.expertise.researchNetworkProfile.error"),
        excludeEmptyString: true,
      }),
    fields: array().min(1).max(3).required(t("common.required")),
  });
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const getUser = async () => {
    const response = await httpsClient.get(`/api/v1/user`);
    response.data.title = response.data.supervisors[0]?.title;
    response.data.research_area = response.data.supervisors[0]?.research_area;
    response.data.professional_network_profile = response.data.supervisors[0]?.professional_network_profile;
    response.data.research_network_profile = response.data.supervisors[0]?.research_network_profile;
    response.data.university =
      universities && universities.filter((e: any) => e.id === response.data.supervisors[0]?.university_id)[0];
    i18n.changeLanguage(response.data.language);
    document.documentElement.lang = response.data.language;
    const _profiles = [];
    if (response.data.students[0]) {
      _profiles.push('student');
    }
    if (response.data.supervisors[0]) {
      _profiles.push('supervisor');
    }
    setProfiles(_profiles);
    setSelectedUniversity(response.data.university);
    setSupervisor(response.data);
    setIsFetching(false);
  };

  const getUniversities = async () => {
    const response = await httpsClient.get(`/api/v1/universities`);
    setUniversities(response.data);
  };

  const getFields = async () => {
    await httpsClient
      .get(`/api/v2/fields`)
      .then((response) => {
        setFields(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const updateUser = async (data: any) => {
    try {
      setIsUpdating(true);
      await httpsClient.patch("/api/v1/user", {
        email: data?.email,
        first_name: data?.first_name,
        last_name: data?.last_name,
        language: data?.language,
        user_fields: {
          deleteMany: {
            user_id: currentUser?.id,
          },
          createMany: {
            data: data?.fields.map((e: any) => ({ field_id: e })),
            skipDuplicates: true,
          },
        },
        supervisors: {
          update: {
            data: {
              research_area: data?.research_area,
              title: data?.title,
              professional_network_profile: data?.professional_network_profile,
              research_network_profile: data?.research_network_profile
            },
            where: { id: currentUser?.entityId },
          },
        },
      });
      if (currentUser?.id) {
        getUser();
      }
      setCurrentUser({
        ...currentUser,
        first_name: data?.first_name,
        last_name: data?.last_name,
        fields: data?.fields,
        research_area: data?.research_area,
      });
      setIsUpdating(false);
      addMessage({
        type: MessageType.Success,
        title: "profile.notification.update.title",
        description: "profile.notification.update.description",
      });
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const onCancel = () => {
    if (currentUser?.id && universities.length > 0) {
      getUser();
    }
  };

  const onSubmit = (data: any) => {
    updateUser(data);
  };

  const closeDeleteAccountConfirmation = () => {
    setIsDeleteAccountConfirmationOpen(false);
  };

  const deleteAccount = async () => {
    try {
      closeDeleteAccountConfirmation();
      setIsDeletingAccount(true);
      await httpsClient.delete("/api/v2/supervisor");
      window.location.reload();
    } catch {
      setIsDeletingAccount(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getFields();
    getUniversities();
  }, []);

  useEffect(() => {
    if (currentUser?.id && universities.length > 0) {
      getUser();
    }
  }, [currentUser, universities]);

  useEffect(() => {
    const defaultValues: any = {};
    defaultValues.first_name = supervisor?.first_name;
    defaultValues.last_name = supervisor?.last_name;
    defaultValues.email = supervisor?.email;
    defaultValues.language = supervisor?.language;
    defaultValues.title = supervisor?.title;
    defaultValues.research_area = supervisor?.research_area;
    defaultValues.professional_network_profile = supervisor?.professional_network_profile;
    defaultValues.research_network_profile = supervisor?.research_network_profile;
    defaultValues.university = supervisor?.university;
    defaultValues.fields = supervisor?.user_fields.map((e: any) => e.field_id);
    reset({ ...defaultValues });
  }, [supervisor]);

  useEffect(() => {
    if (profileImage) {
      uploadProfileImageToS3("profile/supervisors", profileImage.name, profileImage.type);
    }
  }, [profileImage]);

  useEffect(() => {
    const updateActiveProfile = async () => {
      try {
        setIsUpdating(true);
        await httpsClient.patch("/api/v1/user", {
          students: {
            update: {
              data: {
                is_primary: true,
              },
              where: { id: supervisor?.students[0]?.id },
            },
          },
        });
        window.location.reload();
      } catch {
        setIsUpdating(false);
        addMessage({
          type: MessageType.Error,
          title: "common.error.title",
          description: "common.error.description",
        });
      }
    }
    if (activeProfile === "student") {
      updateActiveProfile();
    }
  }, [activeProfile]);

  const handleLanguageChange = (event: any) => {
    i18n.changeLanguage(event.currentTarget.value);
    document.documentElement.lang = event.currentTarget.value;
  };

  const handleProfileImageChange = (event: any) => {
    setProfileImage(event.target.files[0]);
  };

  const handleProfileImageClick = () => {
    if (profileImageInputRef.current) {
      (profileImageInputRef.current as HTMLInputElement).click();
    }
  };

  const uploadProfileImageToS3 = async (path: string, name: string, type: string) => {
    try {
      setIsUpdating(true);
      const _response = await httpsClient.post(`/api/v1/presignedUrl`, {
        path,
        name,
        type,
      });
      await axios.put(_response.data.signedUrl, profileImage, {
        headers: {
          "Content-Type": profileImage.type,
        },
      });
      setIsUpdating(true);
      await httpsClient.patch("/api/v1/user", {
        image: _response.data?.key,
      });
      setIsUpdating(false);
      getUser();
      setCurrentUser({
        ...currentUser,
        image: _response.data?.key,
      });
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
      setOpenProfile(false);
    } catch (error) {
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
      setIsUpdating(false);
    }
  };

  const handleRemoveProfileImage = async () => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.patch("/api/v1/user", {
        image: null,
      });
      setIsUpdating(false);
      getUser();
      setCurrentUser({
        ...currentUser,
        image: null,
      });
      setIsUpdating(false);
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
    } catch (error) {
      setIsUpdating(false);
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
    }
  };

  return (
    <>
      <div className="pb-12 sm:pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("common.myProfile")}</h2>
        </div>
      </div>
      {(isFetching || isDeletingAccount) && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <>
          {!isDeletingAccount && (
            <form autoComplete="off">
              <div className="space-y-8">
                <div className="border-b border-gray-900/10 pb-8">
                  <div className="md:flex md:items-center md:justify-between md:space-x-5">
                    <div className="flex items-center space-x-5">
                      <div className="flex-shrink-0">
                        <div
                          className="relative cursor-pointer hover:opacity-75"
                          onClick={() => {
                            setOpenProfile(true);
                          }}
                        >
                          {(supervisor?.image || supervisor?.picture) &&
                            (supervisor?.image ? (
                              <img
                                className="w-16 h-16 rounded-full border object-cover"
                                src={`https://resources.thesisnavigator.com/${supervisor.image}`}
                                alt=""
                              />
                            ) : (
                              <img className="w-16 h-16 rounded-full" src={supervisor?.picture} alt="" />
                            ))}
                          {!supervisor?.image && !supervisor?.picture && (
                            <img className="w-16 h-16 rounded-full border object-cover" src={user?.picture} alt="" />
                          )}
                          {!supervisor?.image && (
                            <button
                              type="button"
                              className="absolute bottom-0 -right-1 ring-1 ring-gray-100 p-1 rounded-full bg-white hover:opacity-75"
                            >
                              <CameraIcon className="text-gray-900 h-4" />
                            </button>
                          )}
                          <input
                            type="file"
                            ref={profileImageInputRef}
                            onChange={handleProfileImageChange}
                            className="hidden"
                            accept="image/*"
                          />
                          <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="pt-1.5">
                        <h1 className="text-xl font-bold text-gray-900">
                          {(supervisor?.first_name || supervisor?.last_name) && (
                            <>
                              {supervisor?.first_name} {supervisor?.last_name}
                            </>
                          )}
                          {!supervisor?.first_name && !supervisor?.last_name && <>{t("common.myProfile")}</>}
                        </h1>
                        {/* <p className="text-sm font-medium text-gray-500">
                      {user?.city} ({user?.country})
                    </p> */}
                      </div>
                    </div>
                  </div>

                  <h2 className="mt-12 mb-6 text-lg font-bold leading-7 text-gray-900">{t("profile.user.personalInformation.title")}</h2>
                  <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <fieldset aria-label={t("common.activeProfile")}>
                        <RadioGroup value={activeProfile} onChange={setActiveProfile} className="-space-y-px rounded-md bg-white">
                          <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                            {t("common.activeProfile")}
                          </label>
                          {profiles.map((profile, profileIdx) => (
                            <Radio
                              key={profile}
                              value={profile}
                              aria-label={profile}
                              disabled={isUpdating}
                              className={classNames(
                                profileIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                profileIdx === profiles.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                'group relative flex cursor-pointer border border-gray-300 p-3 focus:outline-none data-[checked]:z-10 data-[checked]:border-gray-300 data-[checked]:bg-gray-50 data-[disabled]:opacity-75',
                              )}
                            >
                              <span className="flex flex-col">
                                <span className="block text-sm font-medium text-gray-500 group-data-[checked]:text-gray-900 group-data-[disabled]:opacity-75">
                                  {t(`university.team.${profile}`)}
                                </span>
                              </span>
                              {isUpdating && profile === activeProfile && (
                                <div
                                  className="ml-auto flex w-5 h-5 items-center"
                                >
                                  <Loader />
                                </div>
                              )}
                              {!isUpdating && (
                                <span
                                  aria-hidden="true"
                                  className="ml-auto mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white group-data-[checked]:border-transparent group-data-[checked]:bg-gray-900 group-data-[focus]:ring-2 group-data-[focus]:ring-gray-900 group-data-[focus]:ring-offset-2 group-data-[disabled]:opacity-75"
                                >
                                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                                </span>
                              )}
                            </Radio>
                          ))}
                        </RadioGroup>
                      </fieldset>
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.user.firstName")}
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          autoComplete="given-name"
                          placeholder={t("profile.user.firstName")}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
                          {...register("first_name")}
                        />
                        {errors.first_name && (
                          <p className="mt-2 text-xs text-red-600">{errors.first_name?.message?.toString()}</p>
                        )}
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.user.lastName")}
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder={t("profile.user.lastName")}
                          autoComplete="family-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
                          {...register("last_name")}
                        />
                        {errors.last_name && (
                          <p className="mt-2 text-xs text-red-600">{errors.last_name?.message?.toString()}</p>
                        )}
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.user.email")}
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                          {...register("email")}
                          disabled
                        />
                        {errors.email && <p className="mt-2 text-xs text-red-600">{errors.email?.message?.toString()}</p>}
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.user.language.title")}
                      </label>
                      <fieldset className="mt-2">
                        <legend className="sr-only">{t("profile.user.language.title")}</legend>
                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                          {languages.map((e) => (
                            <div key={e.id} className="flex items-center">
                              <input
                                id={e.id}
                                type="radio"
                                value={e.id}
                                className="h-4 w-4 border-gray-300 text-gray-900 focus:ring-gray-900"
                                {...register("language")}
                                onChange={handleLanguageChange}
                              />
                              <label htmlFor={e.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                {t(`languages.${e.id}`)}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div className="border-b border-gray-900/10 pb-8">
                  <h2 className="text-lg font-bold leading-7 text-gray-900">{t("profile.supervisor.expertise.title")}</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-500">{t("profile.supervisor.expertise.subtitle")}</p>

                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.supervisor.expertise.university.title")}
                      </label>
                      <Controller
                        control={control}
                        defaultValue={selectedUniversity}
                        name="university"
                        render={({ field: { onChange } }) => (
                          <Combobox
                            as="div"
                            value={selectedUniversity}
                            onChange={(e) => {
                              onChange(e);
                              setSelectedUniversity(e);
                            }}
                            disabled
                          >
                            <div className="relative mt-2">
                              <Combobox.Input
                                placeholder={t("profile.supervisor.expertise.university.title")}
                                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 placeholder:text-gray-400 disabled:opacity-75 disabled:bg-gray-100"
                                onChange={(event) => setQuery(event.target.value)}
                                displayValue={(university: any) => university?.name}
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </Combobox.Button>
                            </div>
                          </Combobox>
                        )}
                      />
                      {errors.university && (
                        <p className="mt-2 text-xs text-red-600">{errors.university?.message?.toString()}</p>
                      )}
                    </div>

                    <div className="sm:col-span-4">
                      <label htmlFor="job-title" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.supervisor.expertise.job.title")}
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder={t("profile.supervisor.expertise.job.title")}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
                          {...register("title")}
                        />
                        {errors.title && <p className="mt-2 text-xs text-red-600">{errors.title?.message?.toString()}</p>}
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.supervisor.expertise.research.title")}
                      </label>
                      <p className="text-sm text-gray-500">{t("profile.supervisor.expertise.research.subtitle")}</p>
                      <div className="mt-2">
                        <textarea
                          rows={4}
                          className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
                          placeholder={t("profile.supervisor.expertise.research.title")}
                          defaultValue={""}
                          {...register("research_area")}
                        />
                        {errors.research_area && <p className="mt-2 text-xs text-red-600">{errors.research_area?.message?.toString()}</p>}
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.supervisor.fields.title")}
                      </label>
                      <p className="text-sm text-gray-500">{t("profile.supervisor.fields.subtitle")}</p>

                      <div className="mt-4 whitespace-pre-line">
                        <Controller
                          name="fields"
                          control={control}
                          render={({ field }) => {
                            const fieldValue = Array.isArray(field.value) ? field.value : [];

                            return (
                              <div className="w-full flex flex-wrap gap-2">
                                {fields?.map((e) => {
                                  const isSelected = fieldValue.includes(e.id);
                                  const isDisabled = !isSelected && fieldValue.length >= 3;

                                  const handleClick = () => {
                                    if (!isDisabled) {
                                      const newValue = isSelected
                                        ? fieldValue.filter((id) => id !== e.id)
                                        : [...fieldValue, e.id];
                                      field.onChange(newValue);
                                    }
                                  };

                                  return (
                                    <button
                                      key={e.id}
                                      type="button"
                                      onClick={handleClick}
                                      disabled={isDisabled}
                                      className={`items-center rounded-full px-3 py-1.5 text-xs font-medium border
                  ${isSelected ? 'bg-gray-900 text-white border-transparent' : 'text-gray-900 border-gray-300'}
                  ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                      {t(`fields.${e.name}`)}
                                    </button>
                                  );
                                })}
                              </div>
                            );
                          }}
                        />
                        {errors.fields && <p className="mt-2 text-xs text-red-600">{errors.fields.message}</p>}
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.supervisor.expertise.professionalNetworkProfile.title")}
                      </label>
                      <p className="text-sm text-gray-500">{t("profile.supervisor.expertise.professionalNetworkProfile.subtitle")}</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder={t("profile.supervisor.expertise.professionalNetworkProfile.placeholder")}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
                          defaultValue={""}
                          {...register("professional_network_profile")}
                        />
                      </div>
                      {errors.professional_network_profile && (
                        <p className="mt-2 text-xs text-red-600">{errors.professional_network_profile?.message?.toString()}</p>
                      )}
                    </div>

                    <div className="sm:col-span-4">
                      <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("profile.supervisor.expertise.researchNetworkProfile.title")}
                      </label>
                      <p className="text-sm text-gray-500">{t("profile.supervisor.expertise.researchNetworkProfile.subtitle")}</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder={t("profile.supervisor.expertise.researchNetworkProfile.placeholder")}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
                          defaultValue={""}
                          {...register("research_network_profile")}
                        />
                      </div>
                      {errors.research_network_profile && (
                        <p className="mt-2 text-xs text-red-600">{errors.research_network_profile?.message?.toString()}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                {isUpdating && <Loader />}
                {!isUpdating && (
                  <>
                    <button
                      type="button"
                      onClick={handleSubmit(onCancel)}
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      {t("common.cancel")}
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                      {t("common.save")}
                    </button>
                  </>
                )}
              </div>
              {Object.keys(errors).length > 0 && (
                <p className="mt-2 text-right text-xs text-red-600">{t("form.error.generic.required")}</p>
              )}
            </form>
          )}
          <div className="relative mt-16 mb-12">
            <div aria-hidden="true" className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-3 text-sm font-medium text-gray-500">Settings</span>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-bold leading-7 text-gray-900">{t("profile.privacy.title")}</h3>
            <div className="mt-2 text-sm text-gray-500">
              <p>
                {t("profile.privacy.subtitle")}
              </p>
            </div>
            <div className="mt-6 flex items-center justify-start gap-x-10">
              <a id="privacy" href="#privacy" className="cky-banner-element text-sm/6 font-semibold text-gray-900">
                {t("profile.privacy.cookieSettings")}
              </a>
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:opacity-50"
                onClick={() => setIsDeleteAccountConfirmationOpen(true)}
                disabled={isDeletingAccount}
              >
                {t("profile.privacy.deleteAccount")}
              </button>
            </div>
          </div>
        </>
      )}
      <Transition show={openProfile} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpenProfile}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-6">
                  <div className="absolute right-0 top-0 p-6 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpenProfile(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="text-center">
                    <Dialog.Title as="h3" className="text-xl font-bold leading-6 text-gray-900 mb-6">
                      {t("common.myProfile")}
                    </Dialog.Title>
                    {isUpdating && (
                      <div className="h-32 flex items-center justify-center">
                        <Loader />
                      </div>
                    )}
                    {!isUpdating && (
                      <div className="bg-white ring-1 ring-gray-100 rounded-full inline-block">
                        {supervisor?.image && (
                          <img
                            className="h-24 w-24 rounded-full sm:h-28 sm:w-28 border object-cover"
                            src={`https://resources.thesisnavigator.com/${supervisor.image}`}
                            alt=""
                          />
                        )}
                        {!supervisor?.image && (
                          <img
                            className="h-24 w-24 rounded-full sm:h-28 sm:w-28 object-contain"
                            src={supervisor?.picture}
                            alt=""
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 sm:col-start-2 mb-2 sm:mb-0"
                      onClick={handleProfileImageClick}
                    >
                      {t("common.upload")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-50"
                      onClick={handleRemoveProfileImage}
                      disabled={!supervisor?.image}
                    >
                      {t("common.delete")}
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Modal isOpen={isDeleteAccountConfirmationOpen} onClose={closeDeleteAccountConfirmation}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Delete}
          title={t("profile.privacy.delete.confirmation.title")}
          body={t("profile.privacy.delete.confirmation.description")}
          cancel={t("common.cancel")}
          save={t("common.delete")}
          onCancel={closeDeleteAccountConfirmation}
          onSave={deleteAccount}
        />
      </Modal>
    </>
  );
}
