import { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import {
  HomeIcon,
  UserCircleIcon,
  PlayCircleIcon,
  BuildingOffice2Icon,
  ChevronRightIcon,
  ChatBubbleLeftEllipsisIcon,
  Cog8ToothIcon,
  ChevronUpIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowLeftEndOnRectangleIcon,
  ShieldCheckIcon,
  AcademicCapIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/solid";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CurrentUserContext } from "../contexts/userContext";
import { UserType } from "../models";
import { useTranslation } from "react-i18next";
import SidebarLayout from "./SidebarLayout";

const navigation = [
  { name: "common.dashboard", href: "/", icon: HomeIcon, current: false },
  { name: "common.topics", href: "topics", icon: RectangleGroupIcon, current: false },
  { name: "common.courses", href: "courses", icon: PlayCircleIcon, current: false },
];

const expertsNavigation: any = [
  {
    name: "common.company",
    icon: BuildingOffice2Icon,
    current: false,
    children: [
      { name: "company.profile", href: "/company/profile" },
      { name: "company.experts", href: "/company/experts" },
      { name: "company.topics", href: "/company/topics" },
      { name: "company.thesisProjects", href: "/company/thesis-projects" },
    ],
  },
];

const supervisorsNavigation: any = [
  {
    name: "common.university",
    icon: AcademicCapIcon,
    current: false,
    children: [
      { name: "university.profile", href: "/university/profile" },
      { name: "university.supervisors", href: "/university/supervisors" }
    ],
  },
];

const sysadminNavigation: any = [
  {
    name: "common.company",
    icon: BuildingOffice2Icon,
    current: false,
    children: [
      { name: "common.companies", href: "/admin/companies" },
      { name: "common.topics", href: "/admin/topics" },
      { name: "common.thesisProjects", href: "/admin/thesis-projects" },
    ],
  },
];

const profile: any = [
  {
    name: "common.profile",
    icon: UserCircleIcon,
    current: false,
    children: [
      { name: "common.myProfile", href: "/user/profile" },
      { name: "common.myTopics", href: "/user/topics" },
      { name: "common.myThesisProjects", href: "/user/thesis-projects" },
    ],
  },
];

const supervisorsProfile: any = [
  {
    name: "common.profile",
    icon: UserCircleIcon,
    current: false,
    children: [
      { name: "common.myProfile", href: "/user/profile" },
      { name: "common.myTopics", href: "/supervisor/topics" },
      { name: "common.myThesisProjects", href: "/supervisor/thesis-projects" },
    ],
  },
];

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export default function Layout() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const { user, isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();
  const { currentUser } = useContext(CurrentUserContext);
  const isStudent = currentUser?.type === UserType.Student;
  const isSupervisor = currentUser?.type === UserType.Supervisor;
  const isExpert = currentUser?.type === UserType.Expert;
  const isSysadmin = currentUser?.sysadmin;
  const isAdminExpert = currentUser?.type === UserType.Expert && ["owner", "admin"].includes(currentUser?.role);
  const location = useLocation();
  const currentLanguage = i18n.language;
  const isCompanyRoute = location.pathname.includes("/company/");
  const isUniversityRoute = location.pathname.includes("/university/");
  const isSupervisorRoute = location.pathname.includes("/supervisor/");
  const isUserRoute = location.pathname.includes("/user/");
  const isAdminRoute = location.pathname.includes("/admin/");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const handleLanguageChange = async (language: string) => {
    await i18n.changeLanguage(language);
    setLanguage(language);
    document.documentElement.lang = language;
  };

  useEffect(() => {
    setLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    if (window['dataLayer']) {
      window['dataLayer'].push({
        event: 'pageview',
        page: location.pathname + location.search
      });
    }
  }, [location]);

  return (
    <SidebarLayout
      navbar={
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) =>
                  (isExpert && item.name !== "common.courses") || (isSupervisor && item.name !== "common.courses") ||
                    isStudent ||
                    item.name === "common.dashboard" ? (
                    <li
                      key={item.name}
                      className={isAuthenticated || item.name === "common.dashboard" ? "block" : "hidden"}
                    >
                      <NavLink
                        to={item.href}
                        onClick={() => setSidebarOpen(false)}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:text-gray-900 hover:bg-gray-100",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <item.icon
                              className={classNames(
                                isActive ? "text-gray-900" : "text-gray-400 group-hover:text-gray-900",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t(item.name)}
                          </>
                        )}
                      </NavLink>
                    </li>
                  ) : null
                )}
                {isExpert &&
                  expertsNavigation.map((item: any) => (
                    <li key={item.name} className="group">
                      <Disclosure as="div" defaultOpen={isCompanyRoute}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                isCompanyRoute ? "text-gray-900" : "hover:text-gray-900 group-hover:text-gray-900 text-gray-600",
                                "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold hover:bg-gray-100"
                              )}
                            >
                              <item.icon className={classNames(
                                isCompanyRoute ? "text-gray-900" : "hover:bg-gray-100 hover:text-gray-900 group-hover:text-gray-900",
                                "h-6 w-6 shrink-0 text-gray-400"
                              )} aria-hidden="true" />
                              <div className="">{currentUser.company ? currentUser.company : t(item.name)}</div>
                              <ChevronRightIcon
                                className={classNames(
                                  open ? "rotate-90" : "",
                                  "ml-auto h-5 w-5 shrink-0 text-gray-400"
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel as="ul" className="px-2">
                              {item.children.map(
                                (subItem: any) =>
                                  ((isAdminExpert && subItem.name === "company.thesisProjects") ||
                                    subItem.name !== "company.thesisProjects") && (
                                    <li key={subItem.name}>
                                      <NavLink
                                        to={subItem.href}
                                        onClick={() => setSidebarOpen(false)}
                                        className={({ isActive }) =>
                                          classNames(
                                            isActive
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-500 hover:text-gray-900 hover:bg-gray-100",
                                            "group flex gap-x-3 font-medium rounded-md py-2 pr-2 pl-9 text-sm mt-0.5"
                                          )
                                        }
                                      >
                                        {t(subItem.name)}
                                      </NavLink>
                                    </li>
                                  )
                              )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </li>
                  ))}
                {isSupervisor &&
                  supervisorsNavigation.map((item: any) => (
                    <li key={item.name} className="group">
                      <Disclosure as="div" defaultOpen={isUniversityRoute}>
                        {({ open }) => (
                          <>
                            <DisclosureButton
                              className={classNames(
                                isUniversityRoute ? "text-gray-900" : "hover:text-gray-900 group-hover:text-gray-900 text-gray-600",
                                "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-900 hover:bg-gray-100"
                              )}
                            >
                              {currentUser?.universityPicture ? (
                                <img
                                  className="w-6"
                                  src={`https://resources.thesisnavigator.com/${currentUser.universityPicture}`}
                                  alt=""
                                />
                              ) : (
                                <item.icon
                                  className={classNames(
                                    isUniversityRoute
                                      ? "text-gray-900"
                                      : "hover:bg-gray-100 hover:text-gray-900 group-hover:text-gray-900",
                                    "h-6 w-6 shrink-0 text-gray-400"
                                  )}
                                  aria-hidden="true"
                                />
                              )}
                              <div className="">{currentUser.university ? currentUser.university : t(item.name)}</div>
                              <ChevronRightIcon
                                className={classNames(
                                  open ? "rotate-90" : "",
                                  "ml-auto h-5 w-5 shrink-0 text-gray-400"
                                )}
                                aria-hidden="true"
                              />
                            </DisclosureButton>
                            <DisclosurePanel as="ul" className="px-2">
                              {item.children.map(
                                (subItem: any) =>
                                (
                                  <li key={subItem.name}>
                                    <NavLink
                                      to={subItem.href}
                                      onClick={() => setSidebarOpen(false)}
                                      className={({ isActive }) =>
                                        classNames(
                                          isActive
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-500 hover:text-gray-900 hover:bg-gray-100",
                                          "group flex gap-x-3 font-medium rounded-md py-2 pr-2 pl-9 text-sm mt-0.5"
                                        )
                                      }
                                    >
                                      {t(subItem.name)}
                                    </NavLink>
                                  </li>
                                )
                              )}
                            </DisclosurePanel>
                          </>
                        )}
                      </Disclosure>
                    </li>
                  ))}
              </ul>
            </li>
            {isAuthenticated && !isLoading && (
              <>
                <hr className='mt-3 mb-1 border-t border-zinc-950/5 -mx-6 dark:border-white/5' />
                <li>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {(isStudent || isExpert) && profile.map((item: any) => (
                      <li key={item.name} className="group">
                        <Disclosure as="div" defaultOpen={isUserRoute}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  isUserRoute ? "text-gray-900" : "hover:text-gray-900 group-hover:text-gray-900 text-gray-600",
                                  "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold hover:bg-gray-100"
                                )}
                              >
                                {(currentUser?.image || user?.picture) &&
                                  (currentUser?.image ? (
                                    <img
                                      className="w-6 h-6 border rounded-full object-cover"
                                      src={`https://resources.thesisnavigator.com/${currentUser.image}`}
                                      alt=""
                                    />
                                  ) : (
                                    <img className="w-6 h-6 rounded-full" src={user?.picture} alt="" />
                                  ))}
                                {!currentUser?.image && !user?.picture && (
                                  <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                )}
                                {currentUser?.first_name ? currentUser?.first_name : t(item.name)}
                                <ChevronRightIcon
                                  className={classNames(
                                    open ? "rotate-90" : "",
                                    "ml-auto h-5 w-5 shrink-0 text-gray-400"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="px-2">
                                {item.children.map(
                                  (subItem: any, index: any) =>
                                    (index === 0 || (index === 1 && isExpert) || index === 2) && (
                                      <li key={subItem.name}>
                                        <NavLink
                                          to={subItem.href}
                                          onClick={() => setSidebarOpen(false)}
                                          className={({ isActive }) =>
                                            classNames(
                                              isActive
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-500 hover:text-gray-900 hover:bg-gray-100",
                                              "group flex gap-x-3 font-medium rounded-md py-2 pr-2 pl-9 text-sm mt-0.5"
                                            )
                                          }
                                        >
                                          {t(subItem.name)}
                                        </NavLink>
                                      </li>
                                    )
                                )}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </li>
                    ))}
                    {isSupervisor && supervisorsProfile.map((item: any) => (
                      <li key={item.name} className="group">
                        <Disclosure as="div" defaultOpen={isUserRoute || isSupervisorRoute}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  isUserRoute ? "text-gray-900" : "hover:text-gray-900 group-hover:text-gray-900 text-gray-600",
                                  "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold hover:bg-gray-100"
                                )}
                              >
                                {(currentUser?.image || user?.picture) &&
                                  (currentUser?.image ? (
                                    <img
                                      className="w-6 h-6 border rounded-full object-cover"
                                      src={`https://resources.thesisnavigator.com/${currentUser.image}`}
                                      alt=""
                                    />
                                  ) : (
                                    <img className="w-6 h-6 rounded-full" src={user?.picture} alt="" />
                                  ))}
                                {!currentUser?.image && !user?.picture && (
                                  <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                )}
                                {currentUser?.first_name ? currentUser?.first_name : t(item.name)}
                                <ChevronRightIcon
                                  className={classNames(
                                    open ? "rotate-90" : "",
                                    "ml-auto h-5 w-5 shrink-0 text-gray-400"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="px-2">
                                {item.children.map(
                                  (subItem: any, index: any) =>
                                    (index === 0 || (index === 1) || index === 2) && (
                                      <li key={subItem.name}>
                                        <NavLink
                                          to={subItem.href}
                                          onClick={() => setSidebarOpen(false)}
                                          className={({ isActive }) =>
                                            classNames(
                                              isActive
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-500 hover:text-gray-900 hover:bg-gray-100",
                                              "group flex gap-x-3 font-medium rounded-md py-2 pr-2 pl-9 text-sm mt-0.5"
                                            )
                                          }
                                        >
                                          {t(subItem.name)}
                                        </NavLink>
                                      </li>
                                    )
                                )}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </li>
                    ))}
                  </ul>
                </li>
              </>
            )}
            {isAuthenticated && !isLoading && (
              <li className="mt-auto -mx-2">
                <ul>
                  <li>
                    <NavLink
                      to={"feedback"}
                      onClick={() => setSidebarOpen(false)}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:text-gray-900 hover:bg-gray-100",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                        )
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <ChatBubbleLeftEllipsisIcon
                            className={classNames(
                              isActive ? "text-gray-900" : "text-gray-400 group-hover:text-gray-900",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {t("common.feedback")}
                        </>
                      )}
                    </NavLink>
                  </li>
                  {isSysadmin && !isLoading && (
                    <li>
                      <ul role="list" className="space-y-1">
                        {sysadminNavigation.map((item: any) => (
                          <li key={item.name} className="group">
                            <Disclosure as="div" defaultOpen={isAdminRoute}>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      isAdminRoute ? "text-gray-900" : "hover:text-gray-900 group-hover:text-gray-900 text-gray-600",
                                      "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold hover:bg-gray-100"
                                    )}
                                  >
                                    <Cog8ToothIcon
                                      className={classNames(
                                        isAdminRoute ? "text-gray-900" : "hover:text-gray-900 group-hover:text-gray-900",
                                        "h-6 w-6 shrink-0 text-gray-400"
                                      )}
                                      aria-hidden="true"
                                    />
                                    Admin
                                    <ChevronRightIcon
                                      className={classNames(
                                        open ? "rotate-90" : "",
                                        "ml-auto h-5 w-5 shrink-0 text-gray-400"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel as="ul" className="px-2">
                                    {item.children.map((subItem: any, index: any) => (
                                      <li key={subItem.name}>
                                        <NavLink
                                          to={subItem.href}
                                          onClick={() => setSidebarOpen(false)}
                                          className={({ isActive }) =>
                                            classNames(
                                              isActive
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-500 hover:text-gray-900 hover:bg-gray-100",
                                              "group flex gap-x-3 font-medium rounded-md py-2 pr-2 pl-9 text-sm mt-0.5"
                                            )
                                          }
                                        >
                                          {t(subItem.name)}
                                        </NavLink>
                                      </li>
                                    ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )}
                  {currentUser?.email && (
                    <li>
                      <hr className='my-4 border-t border-zinc-950/5 -mx-4 dark:border-white/5' />
                      <Menu as="div" className="rounded-md -mx-2 mt-2.5 overflow-hidden">
                        <MenuButton className="flex items-center p-2 rounded-md mx-2 w-64 overflow-hidden hover:bg-gray-100">
                          <div className="flex items-center w-full overflow-hidden">
                            <div className="flex-shrink-0">
                              {(currentUser?.image || user?.picture) &&
                                (currentUser?.image ? (
                                  <img
                                    className="inline-block h-9 w-9 rounded-full object-cover"
                                    src={`https://resources.thesisnavigator.com/${currentUser.image}`}
                                    alt=""
                                  />
                                ) : (
                                  <img className="h-9 w-9 rounded-full" src={user?.picture} alt="" />
                                ))}
                              {!currentUser?.image && !user?.picture && (
                                <UserCircleIcon className="inline-block h-9 w-9 shrink-0 text-gray-400" aria-hidden="true" />
                              )}
                            </div>
                            <div className="ml-3 text-left flex-1 overflow-hidden">
                              <p className="text-sm font-semibold text-gray-700 group-hover:text-gray-900">{currentUser?.first_name}</p>
                              <p className="text-xs truncate font-medium text-gray-500 group-hover:text-gray-700 mt-1">
                                {currentUser?.email}
                              </p>
                            </div>
                            <ChevronUpIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                        </MenuButton>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <MenuItems className="absolute left-4 bottom-20 z-20 w-72 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100">
                            <MenuItem>
                              <NavLink to="/" onClick={() => setSidebarOpen(false)}>
                                <div
                                  className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-100">
                                  {t("common.dashboard")}
                                </div>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <NavLink to="/user/profile" onClick={() => setSidebarOpen(false)}>
                                <span
                                  className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-100">
                                  {t("common.myProfile")}
                                </span>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <NavLink to="feedback" onClick={() => setSidebarOpen(false)}>
                                <span
                                  className="block px-4 py-2 text-sm text-gray-900 hover:bg-gray-100">
                                  {t("common.feedback")}
                                </span>
                              </NavLink>
                            </MenuItem>
                            <MenuItem>
                              <a href="https://thesisnavigator.com/privacy-policy" target="_blank" className="group flex items-center gap-x-3 px-4 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 hover:text-gray-600 w-full">
                                <ShieldCheckIcon
                                  className="h-5 shrink-0 text-gray-400 group-hover:text-gray-600"
                                  aria-hidden="true"
                                />
                                {t("Privacy Policy")}
                              </a>
                            </MenuItem>
                            <MenuItem>
                              <button
                                onClick={() => logoutWithRedirect()}
                                className="group flex items-center gap-x-3 px-4 py-2 text-sm leading-6 text-gray-900 hover:bg-gray-100 hover:text-gray-600 w-full"
                              >
                                <ArrowLeftEndOnRectangleIcon
                                  className="h-5 shrink-0 text-gray-400 group-hover:text-gray-600"
                                  aria-hidden="true"
                                />
                                {t("common.sign_out")}
                              </button>
                            </MenuItem>
                          </MenuItems>
                        </Transition>
                      </Menu>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {!isAuthenticated && !isLoading && (
              <li className="mt-auto">
                <button
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: { ui_locales: language },
                    })
                  }
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 hover:text-gray-600 w-full"
                >
                  <ArrowRightEndOnRectangleIcon
                    className="h-6 shrink-0 text-gray-400 group-hover:text-gray-600"
                    aria-hidden="true"
                  />
                  {t("common.login")}
                </button>
              </li>
            )}
          </ ul>
        </nav>
      }
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
    >
    </SidebarLayout >
  );
}
