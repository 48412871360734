import axios from 'axios';
import {Auth0Client} from '@auth0/auth0-spa-js';
import {
  AdminCompaniesApiFactory,
  AdminCompanyExpertsApiFactory,
  AdminTopicsApiFactory,
  CommonFieldsApiFactory,
  Configuration, StudentThesisProjectsApiFactory, StudentTopicsApiFactory, SupervisorThesisProjectsApiFactory,
  SupervisorTopicsApiFactory
} from "./generated";
import {getConfig} from "../config";

const config = getConfig();

const auth0 = new Auth0Client({
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? {audience: config.audience} : null),
  },
  cookieDomain: process.env.REACT_APP_AUTH0_COOKIE_DOMAIN,
});
// configuration, base path, axios instance
const configuration = new Configuration({
  accessToken: async () => {
    return await auth0.getTokenSilently();
  },
});
const basePath = process.env.REACT_APP_API_URL;
export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  AdminCompanies: AdminCompaniesApiFactory(configuration, basePath, axiosInstance),
  AdminCompanyExperts: AdminCompanyExpertsApiFactory(configuration, basePath, axiosInstance),
  AdminTopics: AdminTopicsApiFactory(configuration, basePath, axiosInstance),
  CommonFields: CommonFieldsApiFactory(configuration, basePath, axiosInstance),
  SupervisorTopics: SupervisorTopicsApiFactory(configuration, basePath, axiosInstance),
  SupervisorThesisProjects: SupervisorThesisProjectsApiFactory(configuration, basePath, axiosInstance),
  StudentTopics: StudentTopicsApiFactory(configuration, basePath, axiosInstance),
  StudentThesisProjects: StudentThesisProjectsApiFactory(configuration, basePath, axiosInstance),
}
