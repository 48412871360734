import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { PlusIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Trans, useTranslation } from "react-i18next";
import { LightBulbIcon, PencilIcon, RectangleGroupIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";

export default function ExpertDashboard() {
  const { currentUser } = useContext(CurrentUserContext);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [publishedTopics, setPublishedTopics] = useState([]);
  const [draftTopics, setDraftTopics] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getSupervisorTopics = async () => {
    try {
      setIsFetching(true);
      const response = await httpsClient.get(`/api/v2/supervisor/topics?type=me`);
      setPublishedTopics(response.data.items?.filter((e: any) => e.published && (e.internship === "no" || e.internship === "open")));
      setDraftTopics(response.data.items?.filter((e: any) => !e.published));
      setIsFetching(false);
    } catch {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getSupervisorTopics();
  }, []);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl text-center pb-12">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {t("dashboard.supervisors.welcome.title")}
        </h2>
        <p className="mx-auto mt-6 leading-6 text-gray-500">
          {t("dashboard.supervisors.welcome.description")}
        </p>
      </div>
      <div className="text-center py-12">
        <div className="mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-1 lg:items-start justify-start lg:gap-12">

            <div className="">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {t("dashboard.supervisors.activity.title")}
              </h2>
              {isAuthenticated && !isLoading && isFetching && (
                <div className="my-6 flex justify-center items-center">
                  <Loader />
                </div>
              )}
              {isAuthenticated && !isLoading && !isFetching && (
                <div className="text-left pt-8">
                  <ul role="list" className="divide-y divide-gray-200">
                    <li key={"1"}>
                      <div className="group relative flex items-start space-x-4 py-4">
                        <div className="flex-shrink-0">
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500">
                            <PlusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-semibold text-gray-900">
                            <NavLink to="/supervisor/topics/new">
                              <span className="absolute inset-0" aria-hidden="true" />
                              {t("dashboard.supervisors.activity.publishedTopics.title")}
                            </NavLink>
                          </div>
                          {publishedTopics.length === 0 && (
                            <p className="text-sm text-gray-500">
                              {t("dashboard.supervisors.activity.publishedTopics.create.description")}
                            </p>
                          )}
                          {publishedTopics.length > 0 && (
                            <p className="text-sm text-gray-500">
                              <Trans
                                i18nKey={
                                  publishedTopics.length === 1
                                    ? "dashboard.supervisors.activity.publishedTopics.preview.single.description"
                                    : "dashboard.supervisors.activity.publishedTopics.preview.multiple.description"
                                }
                                components={[<span className="font-semibold" />]}
                                values={{ count: publishedTopics.length }}
                              />
                            </p>
                          )}
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                    {draftTopics.length > 0 && (
                      <li key={"2"}>
                        <div className="group relative flex items-start space-x-4 py-4">
                          <div className="flex-shrink-0">
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-purple-500">
                              <PencilIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div className="text-sm font-semibold text-gray-900">
                              <NavLink to="/supervisor/topics?state=draft">
                                <span className="absolute inset-0" aria-hidden="true" />
                                {t("dashboard.supervisors.activity.draftTopics.title")}
                              </NavLink>
                            </div>
                            <p className="text-sm text-gray-500">
                              <Trans
                                i18nKey={
                                  draftTopics.length === 1
                                    ? "dashboard.supervisors.activity.draftTopics.preview.single.description"
                                    : "dashboard.supervisors.activity.draftTopics.preview.multiple.description"
                                }
                                components={[<span className="font-semibold" />]}
                                values={{ count: draftTopics.length }}
                              />
                            </p>
                          </div>
                          <div className="flex-shrink-0 self-center">
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </li>
                    )}
                    <li key={"3"}>
                      <div className="group relative flex items-start space-x-4 py-4">
                        <div className="flex-shrink-0">
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-500">
                            <UserGroupIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-semibold text-gray-900">
                            <NavLink to="/university/supervisors">
                              <span className="absolute inset-0" aria-hidden="true" />
                              {t("dashboard.supervisors.activity.inviteSupervisors.title")}
                            </NavLink>
                          </div>
                          <p className="text-sm text-gray-500">
                            {t("dashboard.supervisors.activity.inviteSupervisors.description")}
                          </p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                    <li key={"4"}>
                      <div className="group relative flex items-start space-x-4 py-4">
                        <div className="flex-shrink-0">
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-fuchsia-500">
                            <RectangleGroupIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-semibold text-gray-900">
                            <NavLink
                              onClick={() => localStorage.setItem("fields", currentUser.fields.join(","))}
                              to="/topics"
                            >
                              <span className="absolute inset-0" aria-hidden="true" />
                              {t("dashboard.supervisors.activity.previewTopics.title")}
                            </NavLink>
                          </div>
                          <p className="text-sm text-gray-500">
                            {t("dashboard.supervisors.activity.previewTopics.description")}
                          </p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                    <li key={"5"}>
                      <div className="group relative flex items-start space-x-4 py-4">
                        <div className="flex-shrink-0">
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-pink-500">
                            <LightBulbIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-semibold text-gray-900">
                            <NavLink to="/user/profile#interviews">
                              <span className="absolute inset-0" aria-hidden="true" />
                              {t(`dashboard.supervisors.activity.researchArea.${currentUser.research_area ? "enabled" : "disabled"}.title`)}
                            </NavLink>
                          </div>
                          <p className="text-sm text-gray-500">
                            {t(`dashboard.supervisors.activity.researchArea.${currentUser.research_area ? "enabled" : "disabled"}.description`)}
                          </p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
