import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Dashboard from "./containers/dashboard/Dashboard";
import Courses from "./containers/student/courses/Courses";
import Course from "./containers/student/courses/Course";
import { CurrentUserContext, CurrentUserContextType } from "./contexts/userContext";
import { useAuth0 } from "@auth0/auth0-react";
import { UserType } from "./models";
import httpsClient, { addAccessTokenInterceptor } from "./config/https";
import Topics from "./containers/topics/Topics";
import ScrollToTop from "./scrollToTop";
import Feedback from "./containers/Feedback";
import CompanyProfile from "./containers/company/CompanyProfile";
import Login from "./containers/Login";
import CompanyExperts from "./containers/company/CompanyExperts";
import CompanyTopics from "./containers/company/CompanyTopics";
import ExpertTopics from "./containers/expert/ExpertTopics";
import { useTranslation } from "react-i18next";
import ExpertTopic from "./containers/expert/ExpertTopic";
import { ExpertTopicForm } from "./containers/expert/ExpertTopicForm";
import { ThesisProjectFormStudents } from "./containers/thesis-projects/ThesisProjectFormStudents";
import Thesisproject from "./containers/thesis-projects/ThesisProject";
import { ThesisProjectFormExperts } from "./containers/thesis-projects/ThesisProjectFormExperts";
import Company from "./containers/company/Company";
import { MessagesProvider } from "./contexts/messagesContext";
import Messages from "./components/Messages";
import CompanyThesisProjects from "./containers/company/CompanyThesisProjects";
import Forbidden from "./components/errors/Forbidden";
import AdminThesisProjects from "./containers/admin/thesis-projects/AdminThesisProjects";
import AdminTopics from "./containers/admin/topics/AdminTopics";
import AdminThesisProject from "./containers/admin/thesis-projects/AdminThesisProject";
import AdminTopic from "./containers/admin/topics/AdminTopic";
import { jwtDecode } from "jwt-decode";
import UniversitySupervisors from "./containers/university/UniversitySupervisors";
import ExpertThesisProjects from "./containers/expert/ExpertThesisProjects";
import StudentThesisProjects from "./containers/student/StudentThesisProjects";
import Profile from "./containers/Profile";
import AdminCompanies from "./containers/admin/companies/AdminCompanies";
import AdminCompanyEdit from "./containers/admin/companies/AdminCompanyEdit";
import AdminCompanyNew from "./containers/admin/companies/AdminCompanyNew";
import TagManager from "react-gtm-module";
import SupervisorTopics from "./containers/supervisor/SupervisorTopics";
import { SupervisorTopicForm } from "./containers/supervisor/SupervisorTopicForm";
import SupervisorTopic from "./containers/supervisor/SupervisorTopic";
import LayoutOnboarding from "./components/LayoutOnboarding";
import Onboarding from "./containers/dashboard/Onboarding";
import OnboardUser from "./onboardUser";
import SupervisorThesisProjects from "./containers/supervisor/SupervisorThesisProjects";
import Loader from "./components/Loader";
import { ThesisProjectFormSupervisors } from "./containers/thesis-projects/ThesisProjectFormSupervisors";
import University from "./containers/university/University";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || ""
}

export default function App() {
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [accessTokenUser, setAccessTokenUser] = useState<any>();
  const [currentUser, setCurrentUser] = useState<CurrentUserContextType["currentUser"]>({
    id: "",
    entityId: "",
    role: null,
    type: null,
    image: null,
    company: null,
    companyId: null,
    sysadmin: false
  });
  const [isStudent, setIsStudent] = useState(false);
  const [isExpert, setIsExpert] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [isOwnerOrAdminExpert, setIsOwnerOrAdminExpert] = useState(false);
  const [isSysadmin, setIsSysadmin] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingAccessTokenUser, setIsLoadingAccessTokenUser] = useState(true);

  const contextValue: any = {
    currentUser,
    setCurrentUser,
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = jwtDecode(token);
        setAccessTokenUser(decodedToken['https://auth.thesisnavigator.com/user']);
        setIsLoadingAccessTokenUser(false);
      } catch {
        setIsLoadingAccessTokenUser(false);
        if (window.location.pathname !== "/login" && window.location.pathname !== "/") {
          window.location.replace("/");
        }
      }
    };
    getToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const id = accessTokenUser?.id;
    let entityId = accessTokenUser?.student?.id || accessTokenUser?.expert?.id || accessTokenUser?.supervisor?.id;
    if (accessTokenUser?.student && accessTokenUser?.supervisor) {
      entityId = accessTokenUser?.student.is_primary ? accessTokenUser?.student?.id : accessTokenUser?.supervisor?.id;
    }
    let type = accessTokenUser?.student ? UserType.Student : accessTokenUser?.expert ? UserType.Expert : accessTokenUser?.supervisor ? UserType.Supervisor : null;
    if (accessTokenUser?.student && accessTokenUser?.supervisor) {
      type = accessTokenUser?.student.is_primary ? UserType.Student : accessTokenUser?.supervisor.is_primary ? UserType.Supervisor : null;
    }
    const email = accessTokenUser?.email;
    const first_name = accessTokenUser?.first_name;
    const last_name = accessTokenUser?.last_name;
    const sysadmin = accessTokenUser?.sysadmin || false
    const companyId = accessTokenUser?.expert?.company_id;
    const universityId = accessTokenUser?.supervisor?.university_id;
    setIsStudent(type === UserType.Student);
    setIsExpert(type === UserType.Expert);
    setIsSupervisor(type === UserType.Supervisor);
    setIsSysadmin(sysadmin);
    setCurrentUser({ id, entityId, type, email, first_name, last_name, sysadmin, companyId, universityId });
    const language = accessTokenUser?.language;
    i18n.changeLanguage(language);
    document.documentElement.lang = language;

    if (process.env.NODE_ENV === "production") {
      if (!isLoading && !isLoadingAccessTokenUser) {
        if (isAuthenticated && accessTokenUser?.id) {
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({
            event: 'login',
            userId: accessTokenUser?.id,
          })
          TagManager.initialize(tagManagerArgs);
        }
        else {
          TagManager.initialize(tagManagerArgs);
        }
      }
    }

    const getUser = async () => {
      try {
        const response = await httpsClient.get(`/api/v1/user`);
        const role = response.data.experts[0]?.company_experts[0]?.role || null;
        const image = response.data.image;
        const first_name = response.data.first_name;
        const last_name = response.data.last_name;
        const company = response.data.experts[0]?.company_experts[0]?.companies?.name || null;
        const university = response.data.supervisors[0]?.universities?.name || null;
        const universityPicture = response.data.supervisors[0]?.universities?.picture || null;
        const universityId = response.data.supervisors[0]?.university_id || null;
        const research_area = response.data.supervisors[0]?.research_area || null;
        const fields = response.data.user_fields?.map((e: any) => e.field_id) || [];
        const offer_interviews = response.data.experts[0]?.offer_interviews || false;
        setIsOwnerOrAdminExpert(type === UserType.Expert && ["owner", "admin"].includes(role));
        setCurrentUser({
          id,
          entityId,
          type,
          email,
          first_name,
          last_name,
          sysadmin,
          companyId,
          role,
          company,
          image,
          university,
          universityId,
          universityPicture,
          fields,
          offer_interviews,
          research_area,
        });
        if (window.location.pathname !== "/" && id && type === null) {
          window.location.replace("/");
        }
        if (window.location.pathname !== "/onboarding" && fields.length === 0) {
          window.location.replace("/onboarding");
        }
        setIsLoadingUser(false);
      } catch {
        setIsLoadingUser(false);
      }
    };
    if (isAuthenticated && accessTokenUser?.id) {
      getUser();
    }

  }, [isLoadingAccessTokenUser, isAuthenticated, accessTokenUser]);

  return (
    <div className="App">
      <CurrentUserContext.Provider value={contextValue}>
        <MessagesProvider>
          <BrowserRouter>
            <ScrollToTop />
            {/* <OnboardUser /> */}
            <Routes>
              <Route element={<LayoutOnboarding />}>
                <Route path="/onboarding" element={<Onboarding />} />
              </Route>
              <Route element={<Layout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="courses" element={isStudent || isLoadingUser ? <Courses /> : <Navigate to="/" />} />
                <Route path="courses/:id" element={isStudent || isLoadingUser ? <Course /> : <Navigate to="/" />} />
                <Route
                  path="/user/thesis-projects"
                  element={isStudent || isExpert || isLoadingUser ? (isStudent ? <StudentThesisProjects /> : <ExpertThesisProjects />) : <Navigate to="/" />}
                />
                <Route
                  path="/user/thesis-projects"
                  element={
                    isStudent || isLoadingUser ? (
                      <StudentThesisProjects />
                    ) : isExpert || isLoadingUser ? (
                      <ExpertThesisProjects />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/company/thesis-projects"
                  element={isOwnerOrAdminExpert || isLoadingUser ? <CompanyThesisProjects /> : <Navigate to="/" />}
                />
                <Route
                  path="/user/thesis-projects/:id"
                  element={isAuthenticated || isLoadingUser ? <Thesisproject /> : <Navigate to="/" />}
                />
                <Route
                  path="/company/thesis-projects/:id"
                  element={isExpert || isLoadingUser ? <Thesisproject /> : <Navigate to="/" />}
                />
                <Route
                  path="/user/thesis-projects/new"
                  element={isStudent || isLoadingUser ? <ThesisProjectFormStudents /> : <Navigate to="/" />}
                />
                <Route
                  path="/user/thesis-projects/:id/edit"
                  element={
                    isLoadingUser ? (
                      <div className="flex justify-center items-center"><Loader /></div>
                    ) : isStudent ? (
                      <ThesisProjectFormStudents />
                    ) : isExpert ? (
                      <ThesisProjectFormExperts />
                    ) : isSupervisor ? (
                      <ThesisProjectFormSupervisors />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/company/thesis-projects/:id/edit"
                  element={isExpert || isLoadingUser ? <ThesisProjectFormExperts /> : <Navigate to="/" />}
                />
                <Route path="user/profile" element={isAuthenticated || isLoadingUser ? <Profile /> : <Navigate to="/" />} />
                <Route path="user/topics" element={isExpert || isLoadingUser ? <ExpertTopics /> : <Navigate to="/" />} />
                <Route
                  path="company/profile"
                  element={isExpert || isLoadingUser ? <CompanyProfile /> : <Navigate to="/" />}
                />
                <Route
                  path="companies/:id"
                  element={isAuthenticated || isLoadingUser ? <Company /> : <Navigate to="/" />}
                />
                <Route
                  path="company/topics/:id"
                  element={isExpert || isLoadingUser ? <ExpertTopic /> : <Navigate to="/" />}
                />
                <Route
                  path="user/topics/:id"
                  element={isExpert || isLoadingUser ? <ExpertTopic /> : <Navigate to="/" />}
                />
                <Route
                  path="company/experts"
                  element={isExpert || isLoadingUser ? <CompanyExperts /> : <Navigate to="/" />}
                />
                <Route
                  path="company/topics"
                  element={isExpert || isLoadingUser ? <CompanyTopics /> : <Navigate to="/" />}
                />
                <Route
                  path="user/topics/new"
                  element={isExpert || isLoadingUser ? <ExpertTopicForm /> : <Navigate to="/" />}
                />
                <Route
                  path="user/topics/:id/edit"
                  element={isExpert || isLoadingUser ? <ExpertTopicForm /> : <Navigate to="/" />}
                />
                <Route
                  path="company/topics/new"
                  element={isExpert || isLoadingUser ? <ExpertTopicForm /> : <Navigate to="/" />}
                />
                <Route
                  path="company/topics/:id/edit"
                  element={isExpert || isLoadingUser ? <ExpertTopicForm /> : <Navigate to="/" />}
                />
                <Route path="topics" element={isAuthenticated || isLoadingUser ? <Topics /> : <Navigate to="/" />} />
                <Route
                  path="feedback"
                  element={isAuthenticated || isLoadingUser ? <Feedback /> : <Navigate to="/" />}
                />
                <Route
                  path="/admin/companies"
                  element={isSysadmin || isLoadingUser ? <AdminCompanies /> : <Navigate to="/" />}
                />
                <Route
                  path="/admin/companies/new"
                  element={isSysadmin || isLoadingUser ? <AdminCompanyNew /> : <Navigate to="/" />}
                />
                <Route
                  path="/admin/companies/:id"
                  element={isSysadmin || isLoadingUser ? <AdminCompanyEdit /> : <Navigate to="/" />}
                />
                <Route
                  path="/admin/thesis-projects"
                  element={isSysadmin || isLoadingUser ? <AdminThesisProjects /> : <Navigate to="/" />}
                />
                <Route
                  path="/admin/thesis-projects/:id"
                  element={isSysadmin || isLoadingUser ? <AdminThesisProject /> : <Navigate to="/" />}
                />
                <Route
                  path="/admin/topics"
                  element={isSysadmin || isLoadingUser ? <AdminTopics /> : <Navigate to="/" />}
                />
                <Route
                  path="/admin/topics/:id"
                  element={isSysadmin || isLoadingUser ? <AdminTopic /> : <Navigate to="/" />}
                />
                <Route
                  path="/university/supervisors"
                  element={isSupervisor || isLoadingUser ? <UniversitySupervisors /> : <Navigate to="/" />}
                />
                <Route
                  path="/supervisor/topics"
                  element={isSupervisor || isLoadingUser ? <SupervisorTopics /> : <Navigate to="/" />}
                />
                <Route
                  path="/supervisor/topics/new"
                  element={isSupervisor || isLoadingUser ? <SupervisorTopicForm /> : <Navigate to="/" />}
                />
                <Route
                  path="/supervisor/topics/:id"
                  element={isSupervisor || isLoadingUser ? <SupervisorTopic /> : <Navigate to="/" />}
                />
                <Route
                  path="/supervisor/topics/:id/edit"
                  element={isSupervisor || isLoadingUser ? <SupervisorTopicForm /> : <Navigate to="/" />}
                />
                <Route
                  path="/supervisor/thesis-projects"
                  element={isSupervisor || isLoadingUser ? <SupervisorThesisProjects /> : <Navigate to="/" />}
                />
                <Route
                  path="universities/:id"
                  element={isAuthenticated || isLoadingUser ? <University /> : <Navigate to="/" />}
                />
                <Route
                  path="University/profile"
                  element={isSupervisor || isLoadingUser ? <University /> : <Navigate to="/" />}
                />
                <Route path="error/forbidden" element={isAuthenticated || isLoadingUser ? <Forbidden /> : <Navigate to="/" />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
            <Messages />
          </BrowserRouter>
        </MessagesProvider>
      </CurrentUserContext.Provider>
    </div>
  );
}
