import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../../contexts/userContext";
import Loader from "../../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../../config/https";
import { useTranslation } from "react-i18next";
import {
  CalendarIcon,
  CheckBadgeIcon,
  CheckCircleIcon as CheckCircleIconSolid,
  XCircleIcon,
  PaperClipIcon,
  AcademicCapIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/solid";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import { ThesisProjectState, UserType } from "../../../models";
import Linkify from "linkify-react";
import { thesisProjectStates } from "../../../models/index";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import TruncateText from "../../../components/TruncateText";
import { isMobile } from "react-device-detect";
import ThesisProjectStep from "../../thesis-projects/ThesisProjectStep";

export default function ThesisProject() {
  const { currentUser } = useContext(CurrentUserContext);
  const { id } = useParams();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(false);
  const [token, setToken] = useState("");
  const [thesisProject, setThesisProject] = useState<any>({});
  const [activity, setActivity] = useState<any>([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const [topicTitle, setTopicTitle] = useState("");

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  const getThesisProject = async (silently?: boolean) => {
    if (!silently) {
      setIsFetching(true);
    }
    await httpsClient
      .get(`/api/v2/admin/thesis-projects/${id}`)
      .then((response) => {
        setThesisProject(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        }
        console.error("Error fetching data:", error);
      });
  };

  const getThesisProjectActivity = async (silently?: boolean) => {
    if (!silently) {
      setIsFetching(true);
    }
    await httpsClient
      .get(`/api/v2/admin/thesis-projects/${id}/activity`)
      .then((response) => {
        setActivity(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        console.error("Error fetching data:", error);
      });
  };

  const getUserImage = function (user: any) {
    return user?.image ? `https://resources.thesisnavigator.com/${user.image}` : user?.picture;
  };

  const getResourcePresignedUrl = async (key: string) => {
    await httpsClient
      .post(`/api/v1/admin/thesis-projects/resources/presignedUrl`, { key })
      .then((response) => {
        window.open(response.data.signedUrl, "_blank");
      })
      .catch(() => { });
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getThesisProject();
    getThesisProjectActivity();
  }, [currentUser]);

  useEffect(() => {
    if (thesisProject && activity) {
      const title =
        thesisProject?.topics?.title ||
        activity.find((e: any) => e.type === "thesis-project")?.thesis_project?.title ||
        thesisProject?.title;
      setTopicTitle(title);
    }
  }, [thesisProject, activity]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    const pollingId = setInterval(() => {
      getThesisProject(true);
      getThesisProjectActivity(true);
    }, 5000);
    return () => clearInterval(pollingId);
  }, []);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex whitespace-nowrap items-center">
              <NavLink to={"/admin/thesis-projects"} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                {t("common.thesisProjects")}
              </NavLink>
            </div>
          </li>
        </ol>
      </nav>
      {isFetching && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <>
          <div className="lg:flex lg:items-start lg:justify-between lg:space-x-6">
            <div className="min-w-0 flex-1">
              <div className="mt-6 flex items-start gap-x-6">
                {thesisProject?.company && (
                  <NavLink
                    to={`/companies/${thesisProject.company?.id}`}
                    className="whitespace-nowrap flex flex-shrink-0 items-center gap-x-1 hover:opacity-75"
                  >
                    {thesisProject.company?.picture && (
                      <img
                        className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                        src={`https://resources.thesisnavigator.com/${thesisProject.company?.picture}`}
                        alt=""
                      />
                    )}
                    {!thesisProject.company?.picture && (
                      <div className="h-14 w-14 sm:h-20 sm:w-20 p-2 rounded-md">
                        <BuildingOffice2Icon className="fill-gray-200" />
                      </div>
                    )}
                  </NavLink>
                )}
                {thesisProject?.university && (
                  <NavLink
                    to={`/universities/${thesisProject.university?.id}`}
                    className="whitespace-nowrap flex flex-shrink-0 items-center gap-x-1 hover:opacity-75"
                  >
                    {thesisProject.university?.picture && (
                      <img
                        className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                        src={`https://resources.thesisnavigator.com/${thesisProject.university?.picture}`}
                        alt=""
                      />
                    )}
                    {!thesisProject.university?.picture && (
                      <div className="h-14 w-14 sm:h-20 sm:w-20 p-2 rounded-md">
                        <AcademicCapIcon className="fill-gray-200" />
                      </div>
                    )}
                  </NavLink>
                )}
                <div>
                  <>
                    {thesisProject?.company && (
                      <NavLink
                        to={`/companies/${thesisProject.company.id}`}
                        className="whitespace-nowrap flex items-center gap-x-2 mb-1 hover:opacity-75">
                        <span className="font-semibold text-xs text-gray-900">{thesisProject.company.name}</span>{" "}
                        <CheckBadgeIcon className="h-5 text-yellow-400" />
                      </NavLink>
                    )}
                    {thesisProject?.university && (
                      <NavLink
                        to={`/universities/${thesisProject.university.id}`}
                        className="whitespace-nowrap flex items-center gap-x-2 mb-1 hover:opacity-75">
                        <span className="font-semibold text-xs text-gray-900">{thesisProject.university.name}</span>{" "}
                        <CheckBadgeIcon className="h-5 text-yellow-400" />
                      </NavLink>
                    )}
                    <h1 className="text-2xl font-bold leading-8 text-gray-900 tracking-tight">
                      {thesisProject?.title}
                    </h1>
                  </>
                  <div className="mt-2 flex items-center text-sm text-gray-400 space-x-4">
                    <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {!thesisProject.updated && (
                      <>{moment(thesisProject.created).locale(currentLanguage).format("lll")}</>
                    )}
                    {thesisProject.updated && (
                      <>
                        {t("common.updated")} {moment(thesisProject.updated).locale(currentLanguage).format("lll")}
                      </>
                    )}
                    {thesisProject.experts?.length > 0 && (
                      <div className="ml-2 flex -space-x-1.5 overflow-hidden flex-shrink-0">
                        {thesisProject.experts?.map((e: any) =>
                          e?.user?.image ? (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={`https://resources.thesisnavigator.com/${e.user.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={e.user.picture}
                              alt=""
                            />
                          )
                        )}
                      </div>
                    )}
                    {thesisProject.supervisors?.length > 0 && (
                      <div className="ml-2 flex -space-x-1.5 overflow-hidden flex-shrink-0">
                        {thesisProject.supervisors?.map((supervisor) =>
                          supervisor.user?.image ? (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={`https://resources.thesisnavigator.com/${supervisor.user.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={supervisor.user?.picture}
                              alt=""
                            />
                          )
                        )}
                      </div>
                    )}
                    <span
                      className={classNames(
                        thesisProjectStates[thesisProject.state],
                        "inline-flex items-center rounded-full px-3 py-1.5 text-xs font-medium ml-2 hidden sm:block border border-transparent"
                      )}
                    >
                      {t(`thesisProjects.state.${thesisProject.state}`)}
                    </span>
                  </div>
                  <span
                    className={classNames(
                      thesisProjectStates[thesisProject.state],
                      "items-center rounded-full px-3 py-1.5 text-xs font-medium mt-3 truncate sm:hidden inline-block"
                    )}
                  >
                    {t(`thesisProjects.state.${thesisProject.state}`)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {(thesisProject.state === ThesisProjectState.Applied ||
            thesisProject.state === ThesisProjectState.Agreed ||
            thesisProject.state === ThesisProjectState.Completed) && (
              <div className="mt-12">
                <ThesisProjectStep state={thesisProject.state} />
              </div>
            )}

          <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-12">
            <div className="col-span-3">
              <h2 className="pt-12 pb-6 font-semibold leading-6 text-gray-900">{t("thesisProjects.project.title")}</h2>
              <div className="shadow-sm ring-1 ring-gray-900/5 rounded-lg p-6 sm:p-8">
                <div className="text-sm leading-6 text-gray-600 lg:col-span-7 whitespace-pre-line linkify">
                  <TruncateText maxLength={isMobile ? 100 : 600} text={thesisProject.description} />
                </div>
              </div>
              <h2 className="pt-12 pb-6 font-semibold leading-6 text-gray-900">
                {t("thesisProjects.application.title")}
              </h2>
              <div className="shadow-sm ring-1 ring-gray-900/5 rounded-lg p-6 sm:p-8">
                {thesisProject.student && (
                  <>
                    <div className="border-b border-gray-200 pb-5 mb-6">
                      <h3 className="text-sm font-semibold leading-6 text-gray-900">Student</h3>
                    </div>
                    <div className="relative sm:flex justify-between gap-x-6">
                      <div className="flex min-w-0 gap-x-4 flex-1">
                        <img
                          className="h-12 w-12 flex-none rounded-full border object-cover"
                          src={getUserImage(thesisProject.student.user)}
                          alt=""
                        />
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            <span className="absolute inset-x-0 -top-px bottom-0" />
                            {thesisProject.student.user.first_name} {thesisProject.student.user.last_name}
                          </p>
                          <p className="mt-1 flex text-xs leading-5 text-gray-500">
                            <span className="relative">{thesisProject.student.user.email}</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex shrink-0 items-center gap-x-4 flex-1 sm:text-right mt-6 sm:mt-0">
                        <div className="sm:flex sm:flex-col sm:items-end w-full">
                          <p className="text-sm leading-6 text-gray-900">{thesisProject.student.university.name}</p>
                          <div className="mt-1 flex items-center text-xs leanding-5 text-gray-500">
                            {thesisProject.student.field_of_study}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {thesisProject?.experts?.length > 0 && (
                  <>
                    <div className="border-b border-gray-200 pb-5 mt-12 mb-6">
                      <h3 className="text-sm font-semibold leading-6 text-gray-900">{t("common.experts")}</h3>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                      {thesisProject?.experts.map((expert: any) => (
                        <div key={expert.id} className="relative flex items-center space-x-3">
                          <div className="flex-shrink-0">
                            <img
                              className="h-12 w-12 rounded-full border object-cover"
                              src={getUserImage(expert.user)}
                              alt=""
                            />
                          </div>
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {expert.user.first_name} {expert.user.last_name}
                            </p>
                            {expert.title && (
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <span className="relative truncate">{expert.title}</span>
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {thesisProject?.supervisors?.length > 0 && (
                  <>
                    <div className="border-b border-gray-200 pb-5 mt-12 mb-6">
                      <h3 className="text-sm font-semibold leading-6 text-gray-900">{t("common.supervisors")}</h3>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                      {thesisProject?.supervisors.map((supervisor: any) => (
                        <div key={supervisor.id} className="relative sm:flex justify-between gap-x-6">
                          <div className="flex min-w-0 gap-x-4 flex-1">
                            <img
                              className="h-12 w-12 flex-none rounded-full border object-cover"
                              src={getUserImage(supervisor.user)}
                              alt=""
                            />
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                <span className="absolute inset-x-0 -top-px bottom-0" />
                                {supervisor.user.first_name} {supervisor.user.last_name}
                              </p>
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <span className="relative">{supervisor.user.email}</span>
                              </p>
                              {supervisor.title && (
                                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                  <span className="relative">{supervisor.title}</span>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex shrink-0 items-center gap-x-4 flex-1 sm:text-right mt-6 sm:mt-0">
                            <div className="sm:flex sm:flex-col sm:items-end w-full">
                              <p className="text-sm leading-6 text-gray-900">{supervisor.university.name}</p>
                              <div className="mt-1 flex items-center text-xs leanding-5 text-gray-500">
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div className="border-b border-gray-200 pb-5 mt-12 mb-6">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{t("thesisProjects.cv.title")}</h3>
                </div>
                <div className="flex items-center">
                  <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <button
                    onClick={() => getResourcePresignedUrl(thesisProject.cv_file)}
                    className="font-medium text-sm text-gray-900 hover:text-gray-800 ml-2 text-left underline"
                  >
                    {thesisProject.cv_filename}
                  </button>
                </div>
                <div className="border-b border-gray-200 pb-5 mt-12 mb-6">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    {t("thesisProjects.motivation.title")}
                  </h3>
                </div>
                <div className="text-sm leading-6 text-gray-600 whitespace-pre-line linkify">
                  <p className="editor" dangerouslySetInnerHTML={{ __html: t(thesisProject.motivation) }} />
                </div>
                <div className="border-b border-gray-200 pb-5 mt-12 mb-6">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{t("thesisProjects.notes.title")}</h3>
                </div>
                <div className="text-sm leading-6 text-gray-600 whitespace-pre-line linkify">
                  <p className="editor" dangerouslySetInnerHTML={{ __html: t(thesisProject.notes) }} />
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <h3 className="pt-12 pb-6 text-base font-semibold leading-6 text-gray-900">
                {t("thesisProjects.activity.title")}
              </h3>
              <ul role="list" className="space-y-6">
                <li key={thesisProject.id} className="relative flex gap-x-4">
                  {activity?.length > 0 && (
                    <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                      <div className="w-px bg-gray-200" />
                    </div>
                  )}
                  <>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    </div>
                    <div className="flex-auto rounded-md px-3">
                      <div className="flex justify-between gap-x-4">
                        <div className="py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-semibold text-gray-900">
                            {thesisProject?.student?.user?.first_name}
                          </span>{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t("thesisProjects.activity.applied", {
                                value: topicTitle,
                              }),
                            }}
                          />
                        </div>
                        <time className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                          {moment(thesisProject?.created).locale(currentLanguage).fromNow()}
                        </time>
                      </div>
                    </div>
                  </>
                </li>
                {activity?.map((e: any, index: any) => (
                  <>
                    {e.type === "history" && e.history?.changes?.length > 0 && (
                      <li key={e.id} className="relative flex gap-x-4">
                        {index !== activity.filter((item: any) => !(item.history?.changes?.length === 0)).length - 1 && (
                          <div className="-bottom-6 absolute left-0 top-0 flex w-6 justify-center">
                            <div className="w-px bg-gray-200" />
                          </div>
                        )}
                        <>
                          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            {e.history?.changes?.find((change: any) => change.field === "state")?.new ===
                              ThesisProjectState.Agreed ? (
                              <CheckCircleIcon className="h-7 w-7 text-green-600" aria-hidden="true" />
                            ) : e.history?.changes?.find((change: any) => change.field === "state")?.new ===
                              ThesisProjectState.Completed ? (
                              <CheckCircleIconSolid className="h-7 w-7 text-green-600" aria-hidden="true" />
                            ) : e.history?.changes?.find((change: any) => change.field === "state")?.new ===
                              ThesisProjectState.Rejected ? (
                              <XCircleIcon className="h-7 w-7 text-red-500" aria-hidden="true" />
                            ) : e.history?.changes?.find((change: any) => change.field === "state")?.new ===
                              ThesisProjectState.Withdrawn ||
                              e.history?.changes?.find((change: any) => change.field === "state")?.new ===
                              ThesisProjectState.Canceled ? (
                              <XCircleIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
                            ) : (
                              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                            )}
                          </div>
                          <div className="flex-auto rounded-md px-3">
                            <div className="flex justify-between gap-x-4">
                              <div className="py-0.5 text-xs leading-5 text-gray-500">
                                <span className="font-semibold text-gray-900">
                                  {e.history?.user?.first_name
                                    ? e.history?.user?.first_name
                                    : t("common.user")}
                                </span>{" "}
                                {e.history?.changes?.every((change: any) => change.field !== "state") && (
                                  <>
                                    {t("thesisProjects.activity.updated.title")}{" "}
                                    {e.history?.changes
                                      .filter((change: any) => change.field !== "cv_filename")
                                      .map((change: any) => t(`thesisProjects.activity.updated.fields.${change.field}`))
                                      .join(", ")}
                                  </>
                                )}
                                {e.history?.changes?.some((change: any) => change.field === "state") && (
                                  <>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: t("thesisProjects.activity.updatedState", {
                                          value: t(
                                            `thesisProjects.state.${e.history?.changes.find((change: any) => change.field === "state")
                                              ?.new
                                            }`
                                          ),
                                        }),
                                      }}
                                    />
                                  </>
                                )}
                                {e.history
                                  ?.new_state_reason && (
                                    <span className="font-semibold text-gray-900">
                                      {" "}
                                      (
                                      {t(
                                        `thesisProjects.updateState.reason.${e.history?.changes.find((change: any) => change.field === "state")?.new
                                        }.${e.history
                                          ?.new_state_reason
                                          .reason
                                        }`
                                      )}
                                      )
                                    </span>
                                  )}
                              </div>
                              <time className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                {moment(e.date).locale(currentLanguage).fromNow()}
                              </time>
                            </div>
                            {e.history?.changes?.some(
                              (change: any) => change.field === "state_reason_notes"
                            ) && (
                                <p className="text-xs leading-5 text-gray-600 whitespace-pre-line py-2 linkify">
                                  <Linkify options={{ target: "blank" }}>
                                    {
                                      e.history?.changes.find(
                                        (change: any) => change.field === "state_reason_notes"
                                      )?.new
                                    }
                                  </Linkify>
                                </p>
                              )}
                          </div>
                        </>
                      </li>
                    )}
                    {e.type === "comment" && (
                      <li key={e.id} className="relative flex gap-x-4">
                        <div
                          className={classNames(
                            index === activity.length - 1 ? "h-6" : "-bottom-6",
                            "absolute left-0 top-0 flex w-6 justify-center"
                          )}
                        >
                          <div className="w-px bg-gray-200" />
                        </div>
                        <>
                          {e.comment.expert?.user || e.comment.supervisor?.user || e.comment.student?.user ? (
                            <img
                              src={
                                e.comment.expert?.user
                                  ? getUserImage(e.comment.expert?.user)
                                  : e.comment.supervisor?.user
                                  ? getUserImage(e.comment.supervisor?.user)
                                  : getUserImage(e.comment.student?.user)
                              }
                              alt=""
                              className="relative mt-3 h-6 w-6 flex-none rounded-full border object-cover"
                            />
                          ) : (
                            <div className="relative mt-3 h-6 w-6 flex-none" aria-hidden="true" />
                          )}
                          <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                            <div className="flex justify-between gap-x-4">
                              <div className="py-0.5 text-xs leading-5 text-gray-500">
                                <span className="font-semibold text-gray-900">
                                  {e.comment.expert?.user?.first_name ||
                                    e.comment.student?.user?.first_name ||
                                    e.comment.supervisor?.user?.first_name ||
                                    t("common.user")}
                                </span>{" "}
                                {t("thesisProjects.activity.commented")}
                              </div>
                              <time className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                {moment(e.comment.created).locale(currentLanguage).fromNow()}
                              </time>
                            </div>
                            <p className="text-xs leading-5 text-gray-600 whitespace-pre-line py-2 linkify">
                              <Linkify options={{ target: "blank" }}>{e.comment.description}</Linkify>
                            </p>
                          </div>
                        </>
                      </li>
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
