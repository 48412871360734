import React, { PropsWithChildren } from "react";
import { AcademicCapIcon, BuildingOffice2Icon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";

export enum AvatarType {
  Company = "Company",
  University = "University",
}

interface ListItemAvatarProps {
  avatar?: string | null;
  avatarLink?: string | null;
  className?: string;
  avatarType?: AvatarType | null;
}

const ListItemAvatar: React.FC<PropsWithChildren<ListItemAvatarProps>> = ({ avatar, avatarLink, avatarType, className = '', children }) => {
  return (
    <div className={`mr-6 sm:mr-8 flex-shrink-0 ${className}`}>
      <div className="whitespace-nowrap flex items-center gap-x-1 hover:opacity-75">
        {avatar && (
          avatarLink ? (
            <NavLink to={avatarLink}>
              <img
                className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                src={`https://resources.thesisnavigator.com/${avatar}`}
                alt=""
              />
            </NavLink>
          ) : (
            <img
              className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
              src={`https://resources.thesisnavigator.com/${avatar}`}
              alt=""
            />
          )
        )}
        {!avatar && (
          <div className="h-14 w-14 sm:h-20 sm:w-20 rounded-md">
            {
              avatarLink ? (
                <NavLink to={avatarLink}>
                  
                  {avatarType === AvatarType.Company ? <BuildingOffice2Icon className="fill-gray-200" /> : <AcademicCapIcon className="fill-gray-200" />}
                </NavLink>
              ) : (
                avatarType === AvatarType.Company ? <BuildingOffice2Icon className="fill-gray-200" /> : <AcademicCapIcon className="fill-gray-200" />
              )
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ListItemAvatar);
