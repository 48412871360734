/* tslint:disable */
/* eslint-disable */
/**
 * Thesis Navigator API
 * All documentation for the Thesis Navigator API.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { StudentCreateThesisProject } from '../model';
// @ts-ignore
import type { StudentUpdateThesisProject } from '../model';
// @ts-ignore
import type { ThesisProject } from '../model';
// @ts-ignore
import type { ThesisProjectPage } from '../model';
/**
 * StudentThesisProjectsApi - axios parameter creator
 * @export
 */
export const StudentThesisProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Thesis Project.
         * @param {StudentCreateThesisProject} studentCreateThesisProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentThesisProject: async (studentCreateThesisProject: StudentCreateThesisProject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentCreateThesisProject' is not null or undefined
            assertParamExists('createStudentThesisProject', 'studentCreateThesisProject', studentCreateThesisProject)
            const localVarPath = `/api/v2/student/thesis-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studentCreateThesisProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Student Thesis Projects
         * @param {Array<FindAllStudentThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllStudentThesisProjects: async (state?: Array<FindAllStudentThesisProjectsStateEnum>, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/student/thesis-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStudentThesisProject: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findStudentThesisProject', 'id', id)
            const localVarPath = `/api/v2/student/thesis-projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a new Thesis Project.
         * @param {string} id 
         * @param {StudentUpdateThesisProject} studentUpdateThesisProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentThesisProject: async (id: string, studentUpdateThesisProject: StudentUpdateThesisProject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateStudentThesisProject', 'id', id)
            // verify required parameter 'studentUpdateThesisProject' is not null or undefined
            assertParamExists('updateStudentThesisProject', 'studentUpdateThesisProject', studentUpdateThesisProject)
            const localVarPath = `/api/v2/student/thesis-projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studentUpdateThesisProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentThesisProjectsApi - functional programming interface
 * @export
 */
export const StudentThesisProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentThesisProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Thesis Project.
         * @param {StudentCreateThesisProject} studentCreateThesisProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudentThesisProject(studentCreateThesisProject: StudentCreateThesisProject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStudentThesisProject(studentCreateThesisProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentThesisProjectsApi.createStudentThesisProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Student Thesis Projects
         * @param {Array<FindAllStudentThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllStudentThesisProjects(state?: Array<FindAllStudentThesisProjectsStateEnum>, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllStudentThesisProjects(state, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentThesisProjectsApi.findAllStudentThesisProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStudentThesisProject(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStudentThesisProject(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentThesisProjectsApi.findStudentThesisProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a new Thesis Project.
         * @param {string} id 
         * @param {StudentUpdateThesisProject} studentUpdateThesisProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudentThesisProject(id: string, studentUpdateThesisProject: StudentUpdateThesisProject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudentThesisProject(id, studentUpdateThesisProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentThesisProjectsApi.updateStudentThesisProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StudentThesisProjectsApi - factory interface
 * @export
 */
export const StudentThesisProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentThesisProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Thesis Project.
         * @param {StudentThesisProjectsApiCreateStudentThesisProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudentThesisProject(requestParameters: StudentThesisProjectsApiCreateStudentThesisProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProject> {
            return localVarFp.createStudentThesisProject(requestParameters.studentCreateThesisProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Student Thesis Projects
         * @param {StudentThesisProjectsApiFindAllStudentThesisProjectsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllStudentThesisProjects(requestParameters: StudentThesisProjectsApiFindAllStudentThesisProjectsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProjectPage> {
            return localVarFp.findAllStudentThesisProjects(requestParameters.state, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {StudentThesisProjectsApiFindStudentThesisProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStudentThesisProject(requestParameters: StudentThesisProjectsApiFindStudentThesisProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProject> {
            return localVarFp.findStudentThesisProject(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a new Thesis Project.
         * @param {StudentThesisProjectsApiUpdateStudentThesisProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudentThesisProject(requestParameters: StudentThesisProjectsApiUpdateStudentThesisProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProject> {
            return localVarFp.updateStudentThesisProject(requestParameters.id, requestParameters.studentUpdateThesisProject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createStudentThesisProject operation in StudentThesisProjectsApi.
 * @export
 * @interface StudentThesisProjectsApiCreateStudentThesisProjectRequest
 */
export interface StudentThesisProjectsApiCreateStudentThesisProjectRequest {
    /**
     * 
     * @type {StudentCreateThesisProject}
     * @memberof StudentThesisProjectsApiCreateStudentThesisProject
     */
    readonly studentCreateThesisProject: StudentCreateThesisProject
}

/**
 * Request parameters for findAllStudentThesisProjects operation in StudentThesisProjectsApi.
 * @export
 * @interface StudentThesisProjectsApiFindAllStudentThesisProjectsRequest
 */
export interface StudentThesisProjectsApiFindAllStudentThesisProjectsRequest {
    /**
     * The state of the Thesis Project. Possible values: applied, agreed
     * @type {Array<'applied' | 'withdrawn' | 'rejected' | 'agreed' | 'canceled' | 'completed'>}
     * @memberof StudentThesisProjectsApiFindAllStudentThesisProjects
     */
    readonly state?: Array<FindAllStudentThesisProjectsStateEnum>

    /**
     * Page number, default is 1
     * @type {number}
     * @memberof StudentThesisProjectsApiFindAllStudentThesisProjects
     */
    readonly page?: number

    /**
     * Page size, default is 50
     * @type {number}
     * @memberof StudentThesisProjectsApiFindAllStudentThesisProjects
     */
    readonly size?: number
}

/**
 * Request parameters for findStudentThesisProject operation in StudentThesisProjectsApi.
 * @export
 * @interface StudentThesisProjectsApiFindStudentThesisProjectRequest
 */
export interface StudentThesisProjectsApiFindStudentThesisProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof StudentThesisProjectsApiFindStudentThesisProject
     */
    readonly id: string
}

/**
 * Request parameters for updateStudentThesisProject operation in StudentThesisProjectsApi.
 * @export
 * @interface StudentThesisProjectsApiUpdateStudentThesisProjectRequest
 */
export interface StudentThesisProjectsApiUpdateStudentThesisProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof StudentThesisProjectsApiUpdateStudentThesisProject
     */
    readonly id: string

    /**
     * 
     * @type {StudentUpdateThesisProject}
     * @memberof StudentThesisProjectsApiUpdateStudentThesisProject
     */
    readonly studentUpdateThesisProject: StudentUpdateThesisProject
}

/**
 * StudentThesisProjectsApi - object-oriented interface
 * @export
 * @class StudentThesisProjectsApi
 * @extends {BaseAPI}
 */
export class StudentThesisProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Thesis Project.
     * @param {StudentThesisProjectsApiCreateStudentThesisProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentThesisProjectsApi
     */
    public createStudentThesisProject(requestParameters: StudentThesisProjectsApiCreateStudentThesisProjectRequest, options?: RawAxiosRequestConfig) {
        return StudentThesisProjectsApiFp(this.configuration).createStudentThesisProject(requestParameters.studentCreateThesisProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Student Thesis Projects
     * @param {StudentThesisProjectsApiFindAllStudentThesisProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentThesisProjectsApi
     */
    public findAllStudentThesisProjects(requestParameters: StudentThesisProjectsApiFindAllStudentThesisProjectsRequest = {}, options?: RawAxiosRequestConfig) {
        return StudentThesisProjectsApiFp(this.configuration).findAllStudentThesisProjects(requestParameters.state, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Thesis Project by ID.
     * @param {StudentThesisProjectsApiFindStudentThesisProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentThesisProjectsApi
     */
    public findStudentThesisProject(requestParameters: StudentThesisProjectsApiFindStudentThesisProjectRequest, options?: RawAxiosRequestConfig) {
        return StudentThesisProjectsApiFp(this.configuration).findStudentThesisProject(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a new Thesis Project.
     * @param {StudentThesisProjectsApiUpdateStudentThesisProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentThesisProjectsApi
     */
    public updateStudentThesisProject(requestParameters: StudentThesisProjectsApiUpdateStudentThesisProjectRequest, options?: RawAxiosRequestConfig) {
        return StudentThesisProjectsApiFp(this.configuration).updateStudentThesisProject(requestParameters.id, requestParameters.studentUpdateThesisProject, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllStudentThesisProjectsStateEnum = {
    Applied: 'applied',
    Withdrawn: 'withdrawn',
    Rejected: 'rejected',
    Agreed: 'agreed',
    Canceled: 'canceled',
    Completed: 'completed'
} as const;
export type FindAllStudentThesisProjectsStateEnum = typeof FindAllStudentThesisProjectsStateEnum[keyof typeof FindAllStudentThesisProjectsStateEnum];
