import React, { PropsWithChildren } from "react";
import {FolderIcon, RectangleGroupIcon, BuildingOffice2Icon} from "@heroicons/react/24/solid";

// Define the prop types
interface ListNoDataProps {
  type?: 'company' | 'topic' | 'thesis-project';
  className?: string;
}

// Functional component with props
const ListNoData: React.FC<PropsWithChildren<ListNoDataProps>> = ({ type, className = '', children }) => {
  let icon = <RectangleGroupIcon className="mx-auto h-12 w-12 text-gray-400" />;
  switch (type) {
    case "company": {
      icon = <BuildingOffice2Icon className="mx-auto h-12 w-12 text-gray-400" />;
      break;
    }
    case "topic": {
      icon = <RectangleGroupIcon className="mx-auto h-12 w-12 text-gray-400" />;
      break;
    }
    case "thesis-project": {
      icon = <FolderIcon className="mx-auto h-12 w-12 text-gray-400" />;
      break;
    }
  }

  return (
    <div className={`text-center ${className}`}>
      {icon}
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        {children}
      </h3>
    </div>
  );
};

// Export the component
export default React.memo(ListNoData);
