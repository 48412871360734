import {NavLink, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment";
import "moment/locale/de";
import {thesisProjectStates, UserType} from "../../models";
import {AcademicCapIcon, BuildingOffice2Icon, CalendarIcon, CheckBadgeIcon} from "@heroicons/react/24/solid";
import React, {useContext} from "react";
import {CurrentUserContext} from "../../contexts/userContext";
import {ThesisProject} from "@api/generated";

interface Props {
  thesisProjects: ThesisProject[];
}

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

const ThesisProjects: React.FC<Props> = ({thesisProjects}) => {
  const {t, i18n} = useTranslation();
  const {currentUser} = useContext(CurrentUserContext);
  const currentLanguage = i18n.language;
  const location = useLocation();
  const isAdminRoute = location.pathname.includes("/admin/");
  const isCompanyRoute = location.pathname.includes("/company/");
  const isUniversityRoute = location.pathname.includes("/university/");
  const isStudent = currentUser?.type === UserType.Student;
  const isExpert = currentUser?.type === UserType.Expert;
  const isSupervisor = currentUser?.type === UserType.Supervisor;
  return (
    <ul role="list" className="divide-y divide-gray-100 [&>*:first-child]:pt-0">
      {thesisProjects?.map((thesisProject) => (
        <li key={thesisProject.id} className="gap-x-12 gap-y-8 py-8 overflow-hidden">
          <div className="flex min-w-0 gap-x-4">
            <div className="min-w-0 flex-auto">
              <div className="flex items-start gap-x-8">
                {thesisProject?.company && (
                  <NavLink
                    to={`/companies/${thesisProject.company?.id}`}
                    className="whitespace-nowrap flex flex-shrink-0 items-center gap-x-1 hover:opacity-75"
                  >
                    {thesisProject.company?.picture && (
                      <img
                        className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                        src={`https://resources.thesisnavigator.com/${thesisProject.company?.picture}`}
                        alt=""
                      />
                    )}
                    {!thesisProject.company?.picture && (
                      <div className="h-14 w-14 sm:h-20 sm:w-20 p-2 rounded-md">
                        <BuildingOffice2Icon className="fill-gray-200" />
                      </div>
                    )}
                  </NavLink>
                )}
                {thesisProject?.university && (
                  <NavLink
                  to={`/universities/${thesisProject.university?.id}`}
                  className="whitespace-nowrap flex flex-shrink-0 items-center gap-x-1 hover:opacity-75"
                  >
                    {thesisProject.university?.picture && (
                      <img
                        className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                        src={`https://resources.thesisnavigator.com/${thesisProject.university?.picture}`}
                        alt=""
                      />
                    )}
                    {!thesisProject.university?.picture && (
                      <div className="h-14 w-14 sm:h-20 sm:w-20 p-2 rounded-md">
                        <AcademicCapIcon className="fill-gray-200" />
                      </div>
                    )}
                  </NavLink>
                )}
                <div className="flex-1">
                  <>
                    {thesisProject?.company && (
                      <NavLink
                        to={`/companies/${thesisProject.company.id}`}
                        className="whitespace-nowrap flex items-center gap-x-2 mb-1 hover:opacity-75">
                        <span className="font-semibold text-xs text-gray-900">{thesisProject.company.name}</span>{" "}
                        <CheckBadgeIcon className="h-5 text-yellow-400"/>
                      </NavLink>
                    )}
                    {thesisProject?.university && (
                       <NavLink
                        to={`/universities/${thesisProject.university.id}`}
                        className="whitespace-nowrap flex items-center gap-x-2 mb-1 hover:opacity-75">
                        <span className="font-semibold text-xs text-gray-900">{thesisProject.university.name}</span>{" "}
                        <CheckBadgeIcon className="h-5 text-yellow-400"/>
                      </NavLink>
                    )}
                    <h2>
                      <NavLink
                        to={`/${isAdminRoute ? 'admin' : (isCompanyRoute ? 'company' : 'user')}/thesis-projects/${thesisProject.id}`}
                        className="font-bold text-xl leading-7 text-gray-900 hover:opacity-75">
                        {thesisProject?.title}
                      </NavLink>
                    </h2>
                  </>
                  <div className="mt-2 flex items-center text-sm text-gray-400 space-x-4">
                    <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400 " aria-hidden="true"/>
                    {!thesisProject.updated && (
                      <>{moment(thesisProject.created).locale(currentLanguage).format("lll")}</>
                    )}
                    {thesisProject.updated && (
                      <>
                        {t("common.updated")} {moment(thesisProject.updated).locale(currentLanguage).format("lll")}
                      </>
                    )}
                    {thesisProject.experts?.length > 0 && (
                      <div className="ml-2 flex -space-x-1.5 overflow-hidden flex-shrink-0">
                        {thesisProject.experts?.map((expert) =>
                          expert.user?.image ? (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={`https://resources.thesisnavigator.com/${expert.user.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={expert.user?.picture}
                              alt=""
                            />
                          )
                        )}
                      </div>
                    )}                    
                    {thesisProject.supervisors?.length > 0 && (
                      <div className="ml-2 flex -space-x-1.5 overflow-hidden flex-shrink-0">
                        {thesisProject.supervisors?.map((supervisor) =>
                          supervisor.user?.image ? (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={`https://resources.thesisnavigator.com/${supervisor.user.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={supervisor.user?.picture}
                              alt=""
                            />
                          )
                        )}
                      </div>
                    )}
                    <span
                      className={classNames(
                        thesisProjectStates[thesisProject.state],
                        "items-center rounded-full px-3 py-1.5 text-xs font-medium ml-2 truncate hidden sm:block"
                      )}
                    >
                      {t(`thesisProjects.state.${thesisProject.state}`)}
                    </span>
                  </div>
                  <span
                    className={classNames(
                      thesisProjectStates[thesisProject.state],
                      "items-center rounded-full px-3 py-1.5 text-xs font-medium mt-3 truncate sm:hidden inline-block"
                    )}
                  >
                    {t(`thesisProjects.state.${thesisProject.state}`)}
                  </span>
                  {(isExpert || isSupervisor) && thesisProject.student && (
                    <div className="relative sm:flex justify-between gap-x-6 pt-6">
                      <div className="flex min-w-0 gap-x-4 flex-1">
                        {thesisProject.student.user.image || thesisProject.student.user.picture ? (
                          <img
                            className="h-12 w-12 flex-none rounded-full border object-cover"
                            src={
                              thesisProject.student.user.image
                                ? `https://resources.thesisnavigator.com/${thesisProject.student.user.image}`
                                : thesisProject.student.user.picture
                            }
                            alt=""
                          />
                        ) : (
                          <span className="h-12 w-12 flex-none rounded-full border object-cover bg-emerald-500"/>
                        )}
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            <span className="absolute inset-x-0 -top-px bottom-0"/>
                            {thesisProject.student.user.first_name} {thesisProject.student.user.last_name}
                          </p>
                          <p className="mt-1 flex text-xs leading-5 text-gray-500">
                            <span className="relative">{thesisProject.student.user.email}</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex shrink-0 items-center gap-x-4 flex-1 sm:text-right mt-6 sm:mt-0">
                        <div className="sm:flex sm:flex-col sm:items-end w-full">
                          <p className="text-sm leading-6 text-gray-900">
                            {thesisProject.student?.university?.name}
                          </p>
                          <div className="mt-1 flex items-center text-xs leanding-5 text-gray-500">
                            {thesisProject.student.field_of_study}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isStudent && thesisProject.experts?.length > 0 && (
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 pt-6">
                      {thesisProject.experts.map((expert) => (
                        <div key={expert.id} className="relative flex items-center space-x-3">
                          <div className="flex-shrink-0">
                            <img
                              className="h-12 w-12 rounded-full border object-cover"
                              src={
                                expert.user?.image
                                  ? `https://resources.thesisnavigator.com/${expert.user?.image}`
                                  : expert.user?.picture
                              }
                              alt=""
                            />
                          </div>
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {expert.user.first_name} {expert.user.last_name}
                            </p>
                            <p className="mt-1 flex text-xs leading-5 text-gray-500">
                              <span className="relative truncate">{expert.title}</span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {isStudent && thesisProject.supervisors?.length > 0 && (
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 pt-6">
                      {thesisProject.supervisors.map((supervisor) => (
                        <div key={supervisor.id} className="relative flex items-center space-x-3">
                          <div className="flex-shrink-0">
                            <img
                              className="h-12 w-12 rounded-full border object-cover"
                              src={
                                supervisor.user?.image
                                  ? `https://resources.thesisnavigator.com/${supervisor.user?.image}`
                                  : supervisor.user?.picture
                              }
                              alt=""
                            />
                          </div>
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {supervisor.user.first_name} {supervisor.user.last_name}
                            </p>
                            <p className="mt-1 flex text-xs leading-5 text-gray-500">
                              <span className="relative truncate">{supervisor.title}</span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ThesisProjects;
