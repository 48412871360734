import {useAuth0} from "@auth0/auth0-react";
import {useContext, useEffect, useState} from "react";
import {CurrentUserContext} from "../../contexts/userContext";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import {ArrowPathIcon, ChevronRightIcon} from "@heroicons/react/24/solid";
import "moment/locale/de";
import {ThesisProjectState} from "../../models";
import {Popover, PopoverButton, PopoverGroup, PopoverPanel,} from "@headlessui/react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import ThesisProjects from "../thesis-projects/ThesisProjects";
import Api from "../../api/client";
import {FindAllSupervisorThesisProjectsStateEnum, ThesisProjectPage} from "@api/generated";
import {ListNoData, ListPagination} from "../../components/list";

export default function SupervisorThesisProjects() {
  const {currentUser} = useContext(CurrentUserContext);
  const {isAuthenticated, isLoading} = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [thesisProjectPage, setThesisProjectPage] = useState<ThesisProjectPage | undefined>(undefined);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialStatesFromURL = searchParams.getAll("state");
  const initialStatesFromLocalStorage = JSON.parse(localStorage.getItem("supervisor-thesis-project-states") || "[]");
  const initialStates = initialStatesFromURL?.length > 0 ? initialStatesFromURL : initialStatesFromLocalStorage;
  const [selectedStates, setSelectedStates] = useState<FindAllSupervisorThesisProjectsStateEnum[]>(initialStates);
  const {t, i18n} = useTranslation();
  const pageParam = searchParams.get("page");
  const parsedPage = pageParam ? parseInt(pageParam, 10) || 1 : 1;
  const [page, setPage] = useState<number>(parsedPage);

  const getThesisProjects = async () => {
    setIsFetching(true);
    const params = new URLSearchParams();
    selectedStates.forEach((state) => params.append("state", state));
    params.append("page", page.toString());

    await Api.SupervisorThesisProjects.findAllSupervisorThesisProjects({
      me: true,
      page,
      state: selectedStates
    })
      .then((response) => {
        setThesisProjectPage(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        }
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (currentUser?.id) {
      getThesisProjects();
    }
  }, [currentUser, selectedStates, page]);

  useEffect(() => {
    localStorage.setItem("supervisor-thesis-project-states", JSON.stringify(selectedStates));
    const params = new URLSearchParams();
    selectedStates?.forEach((state) => params.append("state", state));
    params.append("page", page.toString());
    navigate(`?${params.toString()}`, {replace: true});
  }, [navigate, selectedStates, page]);

  const handleCheckboxChange = (option: string) => {
    setPage(1);
    setSelectedStates((prevStates) =>
      prevStates.includes(option) ? prevStates.filter((state) => state !== option) : [...prevStates, option]
    );
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  const handleReloadThesisProjects = async () => {
    if (page > 1) {
      setPage(1);
    } else {
      await getThesisProjects();
    }
  };

  return (
    <div className="bg-white">
      <div className="pb-6">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <NavLink to={`/user/profile`} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  {t("common.myProfile")}
                </NavLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                <NavLink
                  to={`/supervisor/thesis-projects`}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {t("common.myThesisProjects")}
                </NavLink>
              </div>
            </li>
          </ol>
        </nav>
        <div className="mt-2 flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl tracking-tight">
              {t("common.myThesisProjects")}
            </h2>
          </div>
        </div>
      </div>
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          {t("common.filters")}
        </h2>
        <div className="border-b border-gray-100 bg-white pb-6 mb-6">
          <div className="flex justify-end">
            <PopoverGroup className="-mx-4 flex items-center">
              <Popover className="relative inline-block pr-4 text-left">
                <PopoverButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>State</span>
                  {selectedStates?.length > 0 && (
                    <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                      {selectedStates.length}
                    </span>
                  )}
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </PopoverButton>
                <PopoverPanel
                  transition
                  className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <form className="space-y-4">
                    {Object.values(ThesisProjectState).map((state) => (
                      <div key={state} className="flex items-center">
                        <input
                          id={`filter-${state}`}
                          name={`${state}`}
                          type="checkbox"
                          checked={selectedStates.includes(state)}
                          onChange={() => handleCheckboxChange(state)}
                          className="h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-800 cursor-pointer"
                        />
                        <label
                          htmlFor={`filter-${state}`}
                          className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 cursor-pointer"
                        >
                          {t(`thesisProjects.state.${state}`)}
                        </label>
                      </div>
                    ))}
                  </form>
                </PopoverPanel>
              </Popover>
            </PopoverGroup>
            <button
              type="button"
              className="ml-6 h-6 w-6 text-gray-400 hover:text-gray-600"
              onClick={() => handleReloadThesisProjects()}
            >
              <ArrowPathIcon/>
            </button>
          </div>
        </div>
        {(selectedStates?.length > 0) && (
          <div className="bg-gray-50 -mx-6 sm:-mx-8 mb-6">
            <div className="mx-auto px-6 sm:px-8 py-3 sm:flex sm:items-center">
              <h3 className="text-sm font-medium text-gray-500 hidden sm:block flex-shrink-0">
                {t("common.filters")}
                <span className="sr-only">, active</span>
              </h3>
              <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"/>
              <div className="mt-0 sm:ml-4">
                <div className="-m-1 flex flex-wrap items-center">
                  <span></span>
                  {selectedStates.map((state) => (
                    <span
                      key={state}
                      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                    >
                      <span>{t(`thesisProjects.state.${state}`)}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                        onClick={() => handleCheckboxChange(state)}
                      >
                        <span className="sr-only">Remove filter for {state}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7"/>
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader/>
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <ThesisProjects thesisProjects={thesisProjectPage.items}/>
      )}
      {isAuthenticated && !isLoading && !isFetching && thesisProjectPage?.items.length === 0 && (
        <ListNoData type="thesis-project">
          {t("common.noThesisProjects")}
        </ListNoData>
      )}
      {
        !isLoading && !isFetching && thesisProjectPage && thesisProjectPage.total > 0 && (
          <ListPagination
            page={thesisProjectPage.page}
            size={thesisProjectPage.size}
            total={thesisProjectPage.total}
            totalPage={thesisProjectPage.totalPages}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            singularKey="common.thesisProject"
            pluralKey="common.thesisProjects"
          />
        )
      }
    </div>
  );
}
