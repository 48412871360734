import { AcademicCapIcon, BuildingOffice2Icon } from "@heroicons/react/24/solid";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { useParams } from "react-router-dom";
import React from "react";

export default function University() {
  const { id } = useParams();
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [token, setToken] = useState("");
  const [university, setUniversity] = useState<any>();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  const getUniversity = async () => {
    const url = id ? `/api/v1/universities/${id ? id : currentUser.universityId}` : `/api/v1/university`;
    const response = await httpsClient.get(url);
    response.data.name = response.data.name;
    response.data.description = response.data.description;
    response.data.uid = response.data.uid;
    setUniversity(response.data);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getUniversity();
  }, [currentUser]);

  return (
    <>
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <>
          <div className="-mx-6 sm:-mx-8 p-6 sm:p-6 lg:p-8 -mt-10">
            <div className="pb-6 rounded-md">
              <div className="relative h-32 w-full lg:h-48 xl:h-60 2xl:h-72 rounded-t-md bg-gray-100">
              </div>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex items-start sm:space-x-5">
                  <div className="sm:flex -mt-14 sm:-mt-16 relative bg-white ring-1 ring-gray-100 rounded-md p-2 inline-block">
                    {university.picture && (
                      <img
                        className="h-24 w-24 sm:h-28 sm:w-28 object-contain"
                        src={`https://resources.thesisnavigator.com/${university.picture}`}
                        alt=""
                      />
                    )}
                    {!university.picture && (
                      <div className="h-24 w-24 sm:h-28 sm:w-28 p-6 rounded-md">
                        <AcademicCapIcon className="fill-gray-900" />
                      </div>
                    )}
                  </div>
                  <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-start sm:space-x-6">
                    <div className="min-w-0 flex-1">
                      <h1 className="truncate text-2xl font-bold text-gray-900">{university.name}</h1>
                    </div>
                  </div>
                </div>
                {university.fields?.length > 0 && (
                  <div className="mt-10">
                    <div className="flex flex-wrap gap-x-3 gap-y-2">
                      {university.fields?.map((field: any) =>
                        <span
                          key={field.id}
                          className="items-center rounded-full px-3 py-1.5 text-xs font-medium border border-gray-300 text-gray-900"
                        >
                          {t(`fields.${field.name}`)} <span className="bg-gray-200 text-gray-900 ml-1.5 rounded-full px-2 py-0.5 text-xs font-medium inline-block">{field.count}</span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <dl className="mt-10 grid grid-cols-1">
                  <div className="col-span-1 text-sm">
                    <dd className="text-gray-500 leading-6">
                      <span className="font-semibold text-gray-900">{university.name}</span>
                      <br />
                      {university.university_domains?.length > 0 && (
                        <div>
                          {university.university_domains.map((domain, index) => (
                            <span key={domain?.domain}>
                              <a
                                href={`https://${domain?.domain}`}
                                target="_blank"
                                rel="noreferrer"
                                className="font-medium text-gray-900 underline"
                              >
                                {`https://${domain?.domain}`}
                              </a>
                              {index < university.university_domains.length - 1 && ", "}
                            </span>
                          ))}
                        </div>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
              {university?.supervisors?.length > 0 && (
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="mt-12">
                    <h2 className="text-lg font-bold tracking-tight text-gray-900">{t("common.supervisors")}</h2>
                  </div>
                  <ul
                    role="list"
                    className="mx-auto mt-12 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 lg:mx-0 lg:max-w-none"
                  >
                    {university?.supervisors?.map((e: any) => (
                      <li key={e.id}>
                        {e.users.image ? (
                          <img
                            className="mx-auto h-20 w-20 rounded-full border object-cover"
                            src={`https://resources.thesisnavigator.com/${e.users.image}`}
                            alt=""
                          />
                        ) : (
                          <img
                            className="mx-auto h-20 w-20 rounded-full border object-cover"
                            src={e.users.picture}
                            alt=""
                          />
                        )}
                        <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                          {(e.users.first_name || e.users.last_name) && (
                            <>
                              {e.users.first_name} {e.users.last_name}
                            </>
                          )}
                          {!e.users.first_name && !e.users.last_name && (
                            <>{e.users.email.split("@")[0]}</>
                          )}
                        </h3>
                        <p className="text-sm leading-6 text-gray-600">{e.title}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
