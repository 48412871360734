import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { useTranslation } from "react-i18next";
import { CheckIcon, ChevronUpDownIcon, MinusCircleIcon, PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { BuildingOffice2Icon, CameraIcon, TrashIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";
import { TextEditor } from "../../components/TipTap/TextEditor";
import { NavLink } from "react-router-dom";

const sizes = ["a", "b", "c", "d", "e", "f", "g", "h", "i"];

export default function CompanyProfile() {
  const { currentUser } = useContext(CurrentUserContext);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { addMessage } = useMessagesContext();

  const validationSchema = object().shape({
    name: string().required(t("common.required")),
    address: object().shape({
      street: string().required(t("common.required")),
      number: string().required(t("common.required")),
      city: string().required(t("common.required")),
      postal_code: string().required(t("common.required")),
    }),
  });

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [token, setToken] = useState("");
  const [company, setCompany] = useState<any>({});
  const [countries, setCountries] = useState<any>([]);
  const [domains, setDomains] = useState<any>([]);
  const [size, setSize] = useState<any>();
  const [country, setCountry] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();
  const [backgroundImage, setBackgroundImage] = useState<any>();
  const profileImageInputRef = useRef(null);
  const backgroundImageInputRef = useRef(null);
  const [openProfile, setOpenProfile] = useState(false);

  const handleDomainChange = (index: any, value: any) => {
    const newDomains = [...domains];
    newDomains[index] = value;
    setDomains(newDomains);
  };

  const handleAddDomain = () => {
    setDomains([...domains, ""]);
  };

  const handleRemoveDomain = (index: any) => {
    const newDomains = [...domains];
    newDomains.splice(index, 1);
    setDomains(newDomains);
  };

  const handleProfileImageChange = (event: any) => {
    setProfileImage(event.target.files[0]);
  };

  const handleBackgroundImageChange = (event: any) => {
    setBackgroundImage(event.target.files[0]);
  };

  const handleBackgroundImageClick = () => {
    if (backgroundImageInputRef.current) {
      (backgroundImageInputRef.current as HTMLInputElement).click();
    }
  };

  const handleProfileImageClick = () => {
    if (profileImageInputRef.current) {
      (profileImageInputRef.current as HTMLInputElement).click();
    }
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (currentUser?.id) {
      getCompany(currentUser.id);
    }
  }, [currentUser]);

  useEffect(() => {
    getCountries();
  }, [currentLanguage]);

  useEffect(() => {
    if (company?.id) {
      const defaultValues: any = {};
      defaultValues.name = company?.name;
      defaultValues.description = company?.description;
      defaultValues.uid = company?.uid;
      defaultValues.address = {};
      defaultValues.address.street = company?.addresses?.street;
      defaultValues.address.number = company?.addresses?.number;
      defaultValues.address.city = company?.addresses?.city;
      defaultValues.address.postal_code = company?.addresses?.postal_code;
      defaultValues.address.country = company?.addresses?.country;
      reset({ ...defaultValues });
    }
  }, [company]);

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    if (backgroundImage) {
      uploadBackgroundImageToS3("company/background", backgroundImage.name, backgroundImage.type);
    }
  }, [backgroundImage]);

  useEffect(() => {
    if (profileImage) {
      uploadProfileImageToS3("company/profile", profileImage.name, profileImage.type);
    }
  }, [profileImage]);

  const getCompany = async (id: string) => {
    const response = await httpsClient.get(`/api/v1/company`);
    setCompany(response.data);
    setSize(response.data.size);
    setCountry(response.data.addresses?.country || "CH");
    const _domains: any = response.data.company_domains.map((e: any) => e.domain) || [];
    setDomains(_domains);
    setIsFetching(false);
  };

  const getCountries = async () => {
    const response = await httpsClient.get(`/api/v1/countries?lang=${currentLanguage}`);
    setCountries(response.data);
  };

  const updateCompany = async (data: any) => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.patch("/api/v1/company", {
        name: data?.name,
        description: data?.description,
        uid: data?.uid,
        domains: domains.filter((item: string) => item !== ""),
        size,
        address: data?.address,
      });
      setCompany(response.data);
      setIsUpdating(false);
      addMessage({
        type: MessageType.Success,
        title: "company.notification.update.title",
        description: "company.notification.update.description",
      });
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const uploadBackgroundImageToS3 = async (path: string, name: string, type: string) => {
    try {
      setIsUpdating(true);
      const _response = await httpsClient.post(`/api/v1/presignedUrl`, {
        path,
        name,
        type,
      });
      await axios.put(_response.data.signedUrl, backgroundImage, {
        headers: {
          "Content-Type": backgroundImage.type,
        },
      });
      const response = await httpsClient.patch("/api/v1/company", {
        background: _response.data?.key,
      });
      setCompany(response.data);
      setIsUpdating(false);
      setBackgroundImage(null);
      if (backgroundImageInputRef.current) {
        (backgroundImageInputRef.current as HTMLInputElement).value = "";
      }
    } catch (error) {
      setBackgroundImage(null);
      if (backgroundImageInputRef.current) {
        (backgroundImageInputRef.current as HTMLInputElement).value = "";
      }
      setIsUpdating(false);
    }
  };

  const uploadProfileImageToS3 = async (path: string, name: string, type: string) => {
    try {
      setIsUpdating(true);
      const _response = await httpsClient.post(`/api/v1/presignedUrl`, {
        path,
        name,
        type,
      });
      await axios.put(_response.data.signedUrl, profileImage, {
        headers: {
          "Content-Type": profileImage.type,
        },
      });
      const response = await httpsClient.patch("/api/v1/company", {
        picture: _response.data?.key,
      });
      setCompany(response.data);
      setIsUpdating(false);
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
      setOpenProfile(false);
    } catch (error) {
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
      setIsUpdating(false);
    }
  };

  const handleRemoveBackgroundImage = async () => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.patch("/api/v1/company", {
        background: null,
      });
      setCompany(response.data);
      setIsUpdating(false);
      setBackgroundImage(null);
      if (backgroundImageInputRef.current) {
        (backgroundImageInputRef.current as HTMLInputElement).value = "";
      }
    } catch (error) {
      setIsUpdating(false);
      setBackgroundImage(null);
      if (backgroundImageInputRef.current) {
        (backgroundImageInputRef.current as HTMLInputElement).value = "";
      }
    }
  };

  const handleRemoveProfileImage = async () => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.patch("/api/v1/company", {
        picture: null,
      });
      setCompany(response.data);
      setIsUpdating(false);
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
    } catch (error) {
      console.log(error);
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
    }
  };

  const onCancel = () => {
    if (currentUser?.id) {
      getCompany(currentUser.id);
    }
  };

  const onSubmit = (data: any) => {
    updateCompany(data);
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <div>
          <div className="relative h-32 w-full lg:h-48 xl:h-60 2xl:h-72 rounded-t-md bg-gray-100">
            {company.background && (
              <img
                src={`https://resources.thesisnavigator.com/${company.background}`}
                className="h-32 w-full lg:h-48 xl:h-60 2xl:h-72 object-cover rounded-t-md"
                alt=""
              />
            )}
            {["owner", "admin"].includes(currentUser?.role) && (
              <button
                type="button"
                className="absolute top-5 right-5 rounded-full ring-1 ring-gray-100 bg-white p-2 hover:opacity-75"
                onClick={handleBackgroundImageClick}
              >
                <CameraIcon className="text-gray-900 h-6" />
              </button>
            )}
            {company.background && ["owner", "admin"].includes(currentUser?.role) && (
              <button
                type="button"
                className="absolute top-5 right-20 rounded-full ring-1 ring-gray-100 bg-white p-2 hover:opacity-75"
                onClick={handleRemoveBackgroundImage}
              >
                <TrashIcon className="text-gray-900 h-6" />
              </button>
            )}
            <input
              type="file"
              ref={backgroundImageInputRef}
              onChange={handleBackgroundImageChange}
              className="hidden"
              accept="image/*"
            />
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="-mt-14 sm:-mt-16 sm:flex items-end sm:space-x-5">
              <div
                className="sm:flex relative cursor-pointer bg-white ring-1 ring-gray-100 rounded-md p-2 hover:opacity-75 inline-block"
                onClick={() => {
                  setOpenProfile(true);
                }}
              >
                {company.picture && (
                  <img
                    className="h-24 w-24 sm:h-28 sm:w-28 object-contain"
                    src={`https://resources.thesisnavigator.com/${company.picture}`}
                    alt=""
                  />
                )}
                {!company.picture && (
                  <div className="h-24 w-24 sm:h-28 sm:w-28 p-6 rounded-md">
                    <BuildingOffice2Icon className="fill-gray-900" />
                  </div>
                )}
                {!company?.picture && (
                  <button
                    type="button"
                    className="absolute bottom-0 -right-3 ring-1 ring-gray-100 p-2 rounded-full bg-white hover:opacity-75"
                  >
                    <CameraIcon className="text-gray-900 h-6" />
                  </button>
                )}
                <input
                  type="file"
                  ref={profileImageInputRef}
                  onChange={handleProfileImageChange}
                  className="hidden"
                  accept="image/*"
                />
              </div>
              <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
                  <div className="flex justify-between gap-x-2">
                    <h1 className="text-2xl font-bold text-gray-900 sm:truncate">{company.name}</h1>
                    {!isUpdating && (
                      <NavLink
                        to={`/companies/${company.id}`}
                        className="self-center inline-block rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        {t("common.preview")}
                      </NavLink>
                    )}
                    {isUpdating && (
                      <div className="pl-4">
                        <Loader />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
              <h1 className="truncate text-2xl font-bold text-gray-900">{company.name}</h1>
            </div>
          </div>

          <form autoComplete="off">
            <div className="space-y-8">
              <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.name")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="given-name"
                      placeholder={t("company.name")}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                      {...register("name")}
                      disabled={!["owner", "admin"].includes(currentUser?.role)}
                    />
                    {errors.name && <p className="mt-2 text-xs text-red-600">{errors.name?.message?.toString()}</p>}
                  </div>
                </div>

                <div className="sm:col-span-full">
                  <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.description")}
                  </label>
                  <div className="mt-2">

                    <Controller
                      control={control}
                      render={({ field }) => (
                        <TextEditor
                          content={field.value}
                          placeholder={t("company.description")}
                          onChange={field.onChange}
                          disabled={!["owner", "admin"].includes(currentUser?.role)}
                        />
                      )}
                      name="description"
                    />
                  </div>
                </div>

                <div className="relative sm:col-span-full">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-100" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="bg-white pr-2 text-sm font-semibold text-gray-900">
                      {t("company.address.title")}
                    </span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.address.country.title")}
                  </label>
                  <div className="mt-2">
                    <Controller
                      control={control}
                      defaultValue={country}
                      name="address.country"
                      render={({ field: { onChange } }) => (
                        <Listbox
                          value={country}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          disabled={!["owner", "admin"].includes(currentUser?.role)}
                        >
                          {({ open }) => (
                            <>
                              <div className="relative mt-2">
                                <Listbox.Button className="truncate relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-900 text-sm sm:leading-6 cursor-pointer disabled:opacity-75 disabled:bg-gray-100">
                                  <span className="block truncate">
                                    {country ? (
                                      <>{countries.filter((e: any) => e.code === country)[0]?.value}</>
                                    ) : (
                                      t("company.address.country.placeholder")
                                    )}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                    {countries.map((e: any) => (
                                      <Listbox.Option
                                        key={e.code}
                                        className={({ active }) =>
                                          classNames(
                                            active ? "bg-gray-900 text-white" : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                                          )
                                        }
                                        value={e.code}
                                        onClick={() => setCountry(e.code)}
                                      >
                                        {({ country, active }: any) => (
                                          <>
                                            <span
                                              className={classNames(
                                                country ? "font-semibold" : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {e.value}
                                            </span>
                                            {country ? (
                                              <span
                                                className={classNames(
                                                  active ? "text-white" : "text-indigo-900",
                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.address.city")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="city"
                      placeholder={t("company.address.city")}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                      {...register("address.city")}
                      disabled={!["owner", "admin"].includes(currentUser?.role)}
                    />
                    {errors.address && (
                      <p className="mt-2 text-xs text-red-600">{(errors.address as any).city?.message.toString()}</p>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="street" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.address.street")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="street"
                      placeholder={t("company.address.street")}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                      {...register("address.street")}
                      disabled={!["owner", "admin"].includes(currentUser?.role)}
                    />
                    {errors.address && (
                      <p className="mt-2 text-xs text-red-600">{(errors.address as any).street?.message.toString()}</p>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-1">
                  <label htmlFor="number" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.address.number")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="number"
                      placeholder={t("company.address.number")}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                      {...register("address.number")}
                      disabled={!["owner", "admin"].includes(currentUser?.role)}
                    />
                    {errors.address && (
                      <p className="mt-2 text-xs text-red-600">{(errors.address as any).number?.message.toString()}</p>
                    )}
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="postal_code" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.address.postalCode")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="postal_code"
                      placeholder={t("company.address.postalCode")}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                      {...register("address.postal_code")}
                      disabled={!["owner", "admin"].includes(currentUser?.role)}
                    />
                    {errors.address && (
                      <p className="mt-2 text-xs text-red-600">
                        {(errors.address as any).postal_code?.message.toString()}
                      </p>
                    )}
                  </div>
                </div>

                <div className="relative mt-2 sm:col-span-full">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-100" />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label htmlFor="uid" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.uid")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="uid"
                      placeholder={t("company.uid")}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                      {...register("uid")}
                      disabled={!["owner", "admin"].includes(currentUser?.role)}
                    />
                    {errors.uid && <p className="mt-2 text-xs text-red-600">{errors.uid?.message?.toString()}</p>}
                  </div>
                </div>

                {company.fields?.length > 0 && (
                  <div className="sm:col-span-full">
                    <label htmlFor="size" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("company.fields.title")}
                    </label>
                    <label htmlFor="size" className="block text-xs leading-6 text-gray-500">
                      {t("company.fields.subtitle")}
                    </label>
                    <div className="mt-6">
                      <div className="flex flex-wrap gap-x-3 gap-y-2">
                        {company.fields?.map((field: any) =>
                          <span
                            key={field.id}
                            className="items-center rounded-full px-3 py-1.5 text-xs font-medium border border-gray-300 text-gray-900"
                          >
                            {t(`fields.${field.name}`)} <span className="bg-gray-200 text-gray-900 ml-1.5 rounded-full px-2 py-0.5 text-xs font-medium inline-block">{field.count}</span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="sm:col-span-4">
                  <label htmlFor="size" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.size.title")}
                  </label>
                  <div className="mt-2">
                    <Listbox value={size} disabled={!["owner", "admin"].includes(currentUser?.role)}>
                      {({ open }) => (
                        <>
                          <div className="relative mt-3">
                            <Listbox.Button className="truncate relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-900 text-sm sm:leading-6 cursor-pointer disabled:opacity-75 disabled:bg-gray-100">
                              <span className="block truncate">
                                {size ? <>{t(`company.size.type.${size}`)}</> : t("company.size.select")}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                <Listbox.Option
                                  key={"noSize"}
                                  className={({ active }) =>
                                    classNames(
                                      active ? "bg-gray-900 text-white" : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                                    )
                                  }
                                  value={null}
                                  onClick={() => setSize(null)}
                                >
                                  <>
                                    <span className="font-normal block truncate">{t("company.size.empty")}</span>
                                  </>
                                </Listbox.Option>
                                {sizes.map((e: any) => (
                                  <Listbox.Option
                                    key={e}
                                    className={({ active }) =>
                                      classNames(
                                        active ? "bg-gray-900 text-white" : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                                      )
                                    }
                                    value={e}
                                    onClick={() => setSize(e)}
                                  >
                                    {({ size, active }: any) => (
                                      <>
                                        <span
                                          className={classNames(
                                            size ? "font-semibold" : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {t(`company.size.type.${e}`)}
                                        </span>

                                        {size ? (
                                          <span
                                            className={classNames(
                                              active ? "text-white" : "text-indigo-900",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label htmlFor="domain" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("company.domain.title")}
                  </label>
                  <div className="mt-2">
                    {domains.map((value: any, index: any) => (
                      <div key={index} className="flex items-center space-x-6 pb-4">
                        <input
                          type="text"
                          value={value}
                          placeholder={t("company.domain.title")}
                          onChange={(e) => handleDomainChange(index, e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                          // disabled={!["owner", "admin"].includes(currentUser?.role)}
                          disabled
                        />
                        {["owner", "admin"].includes(currentUser?.role) && (
                          <button
                            type="button"
                            onClick={() => handleRemoveDomain(index)}
                            disabled
                            className="disabled:opacity-75"
                          >
                            <MinusCircleIcon className="text-gray-300 h-6" />
                          </button>
                        )}
                      </div>
                    ))}
                    {["owner", "admin"].includes(currentUser?.role) && (
                      <button
                        type="button"
                        onClick={handleAddDomain}
                        className="inline-flex items-center rounded-md text-sm font-semibold text-gray-900 mt-1 disabled:opacity-75"
                        disabled
                      >
                        <PlusCircleIcon className="-mt-0.5 mr-1.5 h-6" aria-hidden="true" />
                        {t("company.domain.button")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {["owner", "admin"].includes(currentUser?.role) && (
              <div className="mt-6 flex items-center justify-end gap-x-6 pt-6 border-t border-gray-900/10">
                {isUpdating && <Loader />}
                {!isUpdating && (
                  <>
                    <button
                      type="button"
                      onClick={handleSubmit(onCancel)}
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      {t("common.cancel")}
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                      {t("common.save")}
                    </button>
                  </>
                )}
              </div>
            )}
          </form>
        </div>
      )}
      <>
        <Transition.Root show={openProfile} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setOpenProfile}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-6">
                    <div className="absolute right-0 top-0 p-6 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => setOpenProfile(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="text-center">
                      <Dialog.Title as="h3" className="text-xl font-bold leading-6 text-gray-900 mb-6">
                        {t("company.logo")}
                      </Dialog.Title>
                      {isUpdating && (
                        <div className="h-32 flex items-center justify-center">
                          <Loader />
                        </div>
                      )}
                      {!isUpdating && (
                        <div className="bg-white ring-1 ring-gray-100 rounded-md p-2 inline-block">
                          {company.picture && (
                            <img
                              className="h-24 w-24 sm:h-28 sm:w-28 object-contain"
                              src={`https://resources.thesisnavigator.com/${company.picture}`}
                              alt=""
                            />
                          )}
                          {!company.picture && (
                            <div className="h-24 w-24 sm:h-28 sm:w-28 p-6 rounded-md">
                              <BuildingOffice2Icon className="fill-gray-900" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {["owner", "admin"].includes(currentUser?.role) && (
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 sm:col-start-2 mb-2 sm:mb-0"
                          onClick={handleProfileImageClick}
                        >
                          {t("common.upload")}
                        </button>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-50"
                          onClick={handleRemoveProfileImage}
                          disabled={!company.picture}
                        >
                          {t("common.delete")}
                        </button>
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    </div>
  );
}
