import { useAuth0 } from "@auth0/auth0-react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  BookmarkIcon,
  CheckCircleIcon,
  FunnelIcon,
  CheckBadgeIcon,
  RectangleGroupIcon,
  BuildingOffice2Icon,
  AcademicCapIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/solid";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import Loader from "../../components/Loader";
import { Transition, Dialog, Disclosure, Listbox, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, DisclosurePanel } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import Topic from "./Topic";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { UserType } from "../../models";
import { CurrentUserContext } from "../../contexts/userContext";
import moment from "moment";
import "moment/locale/de";
import ConfirmationDialog, { ConfirmationDialogType } from "../../components/ConfirmationDialog";
import Modal from "../../components/Modal";

const PAGE_SIZE = 50;

const filters = {
  levels: [
    { value: "bsc", label: "bsc", checked: false },
    { value: "msc", label: "msc", checked: false },
    { value: "phd", label: "phd", checked: false },
  ],
};

export interface Faculty {
  name: string;
  abbreviation: string;
}

const Topics = () => {
  const [selectedTopic, setSelectedTopic] = useState<{
    id: string;
    title: string;
    description?: string;
    level?: string[];
    faculty: Faculty;
    university: string;
    research_field?: string;
    deeplink?: string;
    keywords?: string[];
    supervisors?: any[];
    company?: any;
  } | null>(null);

  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  const { currentUser } = useContext(CurrentUserContext);
  const isStudent = currentUser?.type === UserType.Student;
  const isSupervisor = currentUser?.type === UserType.Supervisor;
  const isExpert = currentUser?.type === UserType.Expert;
  const [topics, setTopics] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const initialLevels = (searchParams.get("levels") || localStorage.getItem("levels"))?.split(",") || [];
  const [universityId, setUniversityId] = useState(
    searchParams.get("university") || localStorage.getItem("university") || ""
  );
  const [facultyId, setFacultyId] = useState(searchParams.get("faculty") || localStorage.getItem("faculty") || "");
  const [levels, setLevels] = useState({
    bsc: initialLevels.includes("bsc"),
    msc: initialLevels.includes("msc"),
    phd: initialLevels.includes("phd"),
  });
  const [universities, setUniversities] = useState([]);
  const [university, setUniversity] = useState<any>();
  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState<any>();
  const [fields, setFields] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const initialFields =
    (searchParams.get("fields") || localStorage.getItem("fields"))?.split(",")?.filter((e: any) => e !== "") ||
    [];
  const [fieldIds, setFieldIds] = useState(initialFields);
  const [query, setQuery] = useState("");
  const [filtersCount, setFiltersCount] = useState(0);
  if (searchParams.get("bookmarked") === "false") {
    localStorage.removeItem("bookmarked-topics");
  }
  const bookmarkedValue = localStorage.getItem("bookmarked-topics");
  const bookmarkedParamValue = searchParams.get("bookmarked");
  const [bookmarked, setBookmarked] = useState<boolean>(bookmarkedParamValue === "true" || bookmarkedValue === "true");
  if (searchParams.get("supervised") === "false") {
    localStorage.removeItem("supervised-topics");
  }
  const supervisedValue = localStorage.getItem("supervised-topics");
  const supervisedParamValue = searchParams.get("supervised");
  const [supervised, setSupervised] = useState<boolean>(supervisedParamValue === "true" || supervisedValue === "true");
  if (searchParams.get("match") === "false") {
    localStorage.removeItem("match");
  }
  const matchValue = localStorage.getItem("match");
  const matchParamValue = searchParams.get("match");
  const [match, setMatch] = useState<boolean>(matchParamValue === "true" || matchValue === "true");
  const topicId = searchParams.get("id");
  const onboardingTopics = localStorage.getItem("onboarding-topics") || "";
  const [onboardingOpen, setOnboardingOpen] = useState<boolean>(onboardingTopics !== "true");
  const [count, setCount] = useState(0);
  const pageParam = searchParams.get("page");
  const parsedPage = pageParam ? parseInt(pageParam, 10) || 1 : 1;
  const [page, setPage] = useState<number>(parsedPage);
  const [topicIdToSupervise, setTopicIdToSupervise] = useState<string>();
  const [isSupervisorConfirmationOpen, setIsSupervisorConfirmationOpen] = useState<boolean>(false);

  const scrollRef: any = useRef(null);
  const searchRef: any = useRef(null);

  const scrollToTop = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const getUniversities = async () => {
    const response = await httpsClient.get(`/api/v1/universities`);
    const filteredUniversities = response.data.filter(
      (e: any) =>
        e.name === "Universität St. Gallen (HSG)" ||
        e.name === "Universität Zürich" ||
        e.name === "Eidgenössische Technische Hochschule Zürich (ETHZ)" ||
        e.name === "Universität Bern" ||
        e.name === "Universität Luzern" ||
        e.name === "Universität Basel"
    );
    setUniversities(filteredUniversities);
    if (universityId) {
      setUniversity(response.data.filter((e: any) => e.id === universityId)[0]);
      setUniversityId("");
    }
  };

  const getFaculties = async () => {
    const response = await httpsClient.get(`/api/v1/faculties?university_id=${university?.id}`);
    setFaculties(response.data);
    if (facultyId) {
      setFaculty(response.data.filter((e: any) => e.id === facultyId)[0]);
      setFacultyId("");
    }
  };

  const getFields = async () => {
    const response = await httpsClient.get(`/api/v2/fields`);
    setFields(response.data);
    if (fieldIds?.length > 0) {
      setSelectedFields(response.data.filter((e: any) => fieldIds.includes(e.id)));
      setFieldIds([]);
    }
  };

  const removeField = (id: string) => {
    const _fields = [...selectedFields];
    setSelectedFields(_fields.filter((e: any) => e.id !== id));
  };

  const filteredFields =
    query === ""
      ? fields
      : fields.filter((e: any) => {
        return t(`fields.${e.name}`).toLowerCase().includes(query.toLowerCase());
      });

  const handleBookmark = async (id: string, isBookmarked: boolean, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isBookmarked) {
      await httpsClient
        .post(`/api/v1/topics/bookmark`, { id })
        .then(() => {
          const newTopics: any = topics.map((topic: any) =>
            topic.id === id ? { ...topic, isBookmarked: true } : topic
          );
          setTopics(newTopics);
        })
        .catch(() => { });
    } else {
      await httpsClient
        .delete(`/api/v1/topics/bookmark?topic_id=${id}`)
        .then(() => {
          const newTopics: any = topics.map((topic: any) =>
            topic.id === id ? { ...topic, isBookmarked: false } : topic
          );
          if (bookmarked) {
            const indexToDelete = newTopics.findIndex((e: any) => e.id === id);
            if (indexToDelete !== -1) {
              newTopics.splice(indexToDelete, 1);
            }
          }
          setTopics(newTopics);
          setSelectedTopic(null);
        })
        .catch(() => { });
    }
  };

  const applySupervisorTopicInterest = async () => {
    setIsSupervisorConfirmationOpen(false);
    await httpsClient
      .post(`/api/v2/supervisor/topics/interests`, { topic_id: topicIdToSupervise })
      .then(() => {
        const newTopics: any = topics.map((topic: any) =>
          topic.id === topicIdToSupervise ? { ...topic, interestedInSupervising: true } : topic
        );
        setTopics(newTopics);
      })
      .catch(() => { });
    await httpsClient
      .get(`/api/v1/topics/${topicIdToSupervise}`)
      .then((response) => {
        const newTopics: any = topics.map((topic: any) =>
          topic.id === topicIdToSupervise ? response.data : topic
        );
        setTopics(newTopics);
        setSelectedTopic(response.data);
      })
      .catch(() => { });
  };

  const handleSupervisorTopicInterest = async (topic_id: string, interestedInSupervising: boolean, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!interestedInSupervising) {
      setTopicIdToSupervise(topic_id);
      setIsSupervisorConfirmationOpen(true);
    } else {
      await httpsClient
        .delete(`/api/v2/supervisor/topics/interests?topic_id=${topic_id}`)
        .catch(() => { });
      await httpsClient
        .get(`/api/v1/topics/${topic_id}`)
        .then((response) => {
          const newTopics: any = topics.map((topic: any) =>
            topic.id === topic_id ? response.data : topic
          );
          if (supervised) {
            const indexToDelete = newTopics.findIndex((e: any) => e.id === topic_id);
            if (indexToDelete !== -1) {
              newTopics.splice(indexToDelete, 1);
            }
            setSelectedTopic(null);
          } else {
            setSelectedTopic(response.data);
          }
          setTopics(newTopics);
        })
        .catch(() => { });
    }
  };

  const handleBookmarkFilter = async () => {
    if (!bookmarked) {
      localStorage.setItem("bookmarked-topics", String(!bookmarked));
    } else {
      localStorage.removeItem("bookmarked-topics");
    }
    setPage(1);
    setBookmarked(!bookmarked);
  };

  const handleMatchFilter = async () => {
    if (!match) {
      localStorage.setItem("match", String(!match));
    } else {
      localStorage.removeItem("match");
    }
    setPage(1);
    setMatch(!match);
  };

  const handleSupervisedFilter = async () => {
    if (!supervised) {
      localStorage.setItem("supervised-topics", String(!supervised));
    } else {
      localStorage.removeItem("supervised-topics");
    }
    setPage(1);
    setSupervised(!supervised);
  };

  const bookmarkTopic = async (id: string) => {
    await httpsClient
      .post(`/api/v1/topics/bookmark`, { id })
      .then(() => {
        const newTopics: any = topics.map((topic: any) => (topic.id === id ? { ...topic, isBookmarked: true } : topic));
        setTopics(newTopics);
      })
      .catch(() => { });
  };

  const handleFacultyChange = (faculty: any) => {
    setPage(1);
    setFaculty(faculty);
  };

  const handleUniversityChange = (university: any) => {
    setUniversity(university);
    if (!university) {
      setFaculty(null);
    }
  };

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleSearch = async () => {
    setIsFetching(true);
    setSelectedTopic(null);

    const selectedLevels = Object.keys(levels).filter((key) => {
      const option = key as keyof typeof levels;
      return levels[option];
    });
    const _levels = selectedLevels.join(",");

    const params = new URLSearchParams();
    if (search) {
      params.append("search", search.trim());
    }

    if (_levels) {
      params.append("levels", _levels);
      localStorage.setItem("levels", _levels);
    } else {
      localStorage.removeItem("levels");
    }

    if (isExpert && university) {
      params.append("university", university?.id);
      localStorage.setItem("university", university?.id);
    } else if (isExpert && universityId) {
      params.append("university", universityId);
      localStorage.setItem("university", universityId);
    } else {
      localStorage.removeItem("university");
    }

    if (faculty) {
      params.append("faculty", faculty?.id);
      localStorage.setItem("faculty", faculty?.id);
    } else if (facultyId) {
      params.append("faculty", facultyId);
      localStorage.setItem("faculty", facultyId);
    } else {
      localStorage.removeItem("faculty");
    }
    if (bookmarked) {
      params.append("bookmarked", String(bookmarked));
      localStorage.setItem("bookmarked-topics", "true");
    } else {
      localStorage.removeItem("bookmarked-topics");
    }
    if (supervised) {
      params.append("supervised", String(supervised));
      localStorage.setItem("supervised-topics", "true");
    } else {
      localStorage.removeItem("supervised-topics");
    }
    if (match) {
      params.append("match", String(match));
      localStorage.setItem("match", "true");
    } else {
      localStorage.removeItem("match");
    }

    if (selectedFields?.length > 0) {
      const _fields = selectedFields.map((e: any) => e.id).join(",");
      params.append("fields", _fields);
      localStorage.setItem("fields", _fields);
    } else if (fieldIds?.length > 0) {
      params.append("fields", fieldIds.join(","));
      localStorage.setItem("fields", fieldIds.join(","));
    } else {
      localStorage.removeItem("fields");
    }

    params.append("page", page.toString());
    const paramsArray = Array.from(params.keys());
    setFiltersCount(paramsArray.length - 1);

    const queryString = params.toString();

    if (topicId) {
      await httpsClient
        .get(`/api/v1/topics/${topicId}`)
        .then((response) => {
          setSelectedTopic(response.data);
          if (isMobile) {
            setOpen(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    await httpsClient
      .get(`/api/v1/topics?${queryString}`)
      .then((response) => {
        setTopics(response.data.topics);
        setCount(response.data.total);
        setIsFetching(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsFetching(false);
      });
    navigate(`/topics?${queryString}`);
  };

  const handleClearSearch = () => {
    setPage(1);
    setBookmarked(false);
    setSupervised(false);
    setMatch(false);
    setSearch("");
    setLevels({
      bsc: false,
      msc: false,
      phd: false,
    });
    setSelectedTopic(null);
    setUniversity(null);
    setUniversityId("");
    setFaculty(null);
    setFacultyId("");
    setSelectedFields([]);
    localStorage.removeItem("levels");
    localStorage.removeItem("university");
    localStorage.removeItem("faculty");
    localStorage.removeItem("fields");
    navigate(`/topics`);
  };

  const handleCheckboxChange = (option: keyof typeof levels) => {
    setPage(1);
    setLevels((prevLevels) => ({
      ...prevLevels,
      [option]: !prevLevels[option],
    }));
  };

  const handleItemClick = (item: any) => {
    if (!isMobile) {
      scrollToTop();
    }
    setSelectedTopic(item);
    setOpen(true);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setPage(1);
      handleSearch();
    }
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  const openSupervisorConfirmation = () => {
    setIsSupervisorConfirmationOpen(true);
  };

  const closeSupervisorConfirmation = () => {
    setIsSupervisorConfirmationOpen(false);
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isExpert) {
      getUniversities();
    }
    getFields();
  }, [currentUser]);

  useEffect(() => {
    if (isStudent || isSupervisor || university?.id) {
      getFaculties();
    }
  }, [currentUser, university]);

  useEffect(() => {
    if (currentUser) {
      handleSearch();
    }
  }, [currentUser, levels, university, faculty, bookmarked, supervised, selectedFields, match, page]);

  return (
    <div className="bg-white">
      <div className="pb-12 sm:pb-12">
        <div className={classNames(selectedTopic ? "sm:w-1/2" : " w-full", "flex items-center justify-center sm:justify-center")}>
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("common.topics")}</h2>
          <InformationCircleIcon
            type="button"
            className="text-gray-400/70 hover:text-gray-400 w-5 cursor-pointer ml-2"
            aria-hidden="true"
            onClick={() => {
              setOnboardingOpen(true);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <div className={classNames(selectedTopic ? "sm:w-1/2" : "w-full", "")}>
          <div className="relative flex items-center">
            <input
              type="search"
              enterkeyhint="search"
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder={t("topics.search.placeholder")}
              className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-sm sm:leading-6"
              ref={searchRef}
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <button
                onClick={() => {
                  setPage(1);
                  handleSearch();
                }}
                className="inline-flex items-center border-none px-1 text-xs text-gray-400 hover:text-gray-500 font-medium"
              >
                <MagnifyingGlassIcon className="w-5" />
              </button>
            </div>
          </div>
          <Disclosure defaultOpen={false} as="section" aria-labelledby="filter-heading" className="grid items-center">
            {({ open }) => (
              <>
                <div className="flex items-center justify-between">
                  <div className="text-left">
                    <h2 id="filter-heading" className="sr-only">
                      {t("topics.search.filters")}
                    </h2>
                    <div className="relative col-start-1 row-start-1 py-6">
                      <div className="mx-auto flex items-center space-x-4 text-sm text-gray-500">
                        <div>
                          <Disclosure.Button className="group flex items-start">
                            <FunnelIcon
                              className="mr-2 h-5 w-5 flex-none text-gray-300 group-hover:text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="font-medium">{t("topics.search.filters")}</span>
                            {filtersCount > 0 && (
                              <span className="ml-2 rounded-full bg-gray-100 px-2 py-0.5 text-xs font-semibold tabular-nums text-gray-600">
                                {filtersCount}
                              </span>
                            )}
                            <ChevronUpIcon
                              className={`${open ? "rotate-180 transform" : ""
                                } ml-2 h-5 w-5 flex-shrink-0 stroke-2 text-gray-400 group-hover:text-gray-500`}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                        </div>
                        <div
                          onClick={handleMatchFilter}
                          className="flex items-center cursor-pointer group/filter"
                        >
                          <button
                            className={classNames(
                              match
                                ? "bg-gray-900 text-white border-transparent hover:bg-gray-700"
                                : "text-gray-900 border-gray-300 hover:bg-gray-50",
                              "items-center rounded-full px-3 py-1 text-xs font-medium border"
                            )}
                          >Match</button>
                        </div>
                        {(isStudent || isExpert) && (
                          <div
                            onClick={handleBookmarkFilter}
                            className="flex items-center cursor-pointer group/filter"
                          >
                            <BookmarkIcon
                              className={classNames(
                                bookmarked
                                  ? "text-yellow-400 group-hover/filter:text-yellow-300"
                                  : "text-gray-200 group-hover/filter:text-gray-300",
                                "w-5 flex-shrink-0"
                              )}
                              aria-hidden="true"
                            />
                          </div>
                        )}
                        {isSupervisor && (
                          <div
                            onClick={handleSupervisedFilter}
                            className="flex items-center cursor-pointer group/filter"
                          >
                            <AcademicCapIcon
                              className={classNames(
                                supervised
                                  ? "text-yellow-400 group-hover/filter:text-yellow-300"
                                  : "text-gray-300 group-hover/filter:text-gray-400",
                                "w-5 flex-shrink-0"
                              )}
                              aria-hidden="true"
                            />
                          </div>
                        )}
                        {filtersCount > 0 && (
                          <div className="flex items-start gap-x-2 cursor-pointer group/clear">
                            <XCircleIcon
                              onClick={handleClearSearch}
                              className="w-5 h-5 flex-shrink-0 text-gray-400/60 font-medium cursor-pointer group-hover/clear:text-gray-400"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-right py-6 text-gray-500 text-xs">
                    {count > 0 && !isFetching && (
                      <div className="mx-auto flex space-x-2 text-sm text-gray-500">
                        <span className="font-semibold text-gray-700">{count}</span> <span className="font-medium">{t("common.topics")}</span>
                      </div>
                    )}
                  </div>
                </div>
                <DisclosurePanel className="border-b border-gray-100 pb-6 mb-6">
                  <div className="flex flex-col xl:flex-row">
                    <div className="w-full xl:w-2/3">
                      <div className="mb-6">
                        <legend className="block font-medium leading-6 pb-2 text-sm">{t("topics.search.fields")}</legend>
                        <Combobox
                          immediate
                          as="div"
                          value={selectedFields}
                          onChange={(e) => {
                            setPage(1);
                            setSelectedFields(e);
                          }}
                          multiple
                        >
                          {selectedFields.length > 0 && (
                            <div className="pb-0.5">
                              {selectedFields.map((selectedField: any) => (
                                <span
                                  key={selectedField.id}
                                  className="inline-flex items-center gap-x-1 mb-2.5 mr-2.5 px-3 py-1.5 rounded-full px-3 py-1.5 text-xs font-medium border border-gray-300 text-gray-900"
                                >
                                  {t(`fields.${selectedField.name}`)}
                                  <button
                                    type="button"
                                    onClick={() => removeField(selectedField.id)}
                                    className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-900/10"
                                  >
                                    <span className="sr-only">Remove</span>
                                    <svg
                                      viewBox="0 0 14 14"
                                      className="h-3.5 w-3.5 stroke-gray-900"
                                    >
                                      <path d="M4 4l6 6m0-6l-6 6" />
                                    </svg>
                                    <span className="absolute -inset-1" />
                                  </button>
                                </span>
                              ))}
                            </div>
                          )}
                          <div className="relative">
                            <ComboboxInput
                              placeholder={t("topics.search.selectFields")}
                              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 shadow-sm  text-sm sm:leading-6 placeholder:text-gray-400 truncate text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-900 text-sm sm:leading-6 cursor-pointer"
                              onChange={(e) => setQuery(e.target.value)}
                            />
                            <ComboboxButton
                              className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                            >
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </ComboboxButton>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}
                            >
                              <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                {filteredFields.length === 0 && query !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    No field
                                  </div>
                                ) : (
                                  filteredFields.map((field: any) => (
                                    <ComboboxOption
                                      key={`option-${field.id}`}
                                      value={field}
                                      className={({ active }) =>
                                        classNames(
                                          "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer",
                                          active ? "bg-gray-900 text-white" : "text-gray-900"
                                        )
                                      }
                                    >
                                      {({ active, selected }) => (
                                        <>
                                          <span className={classNames("block truncate", selected && "font-semibold")}>
                                            {t(`fields.${field.name}`)}
                                          </span>

                                          {selected && (
                                            <span
                                              className={classNames(
                                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                                active ? "text-white" : "text-gray-900"
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </ComboboxOption>
                                  ))
                                )}
                              </ComboboxOptions>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                      {isExpert && universities?.length > 0 && (
                        <>
                          <legend className="block font-medium leading-6">{t("topics.search.university")}</legend>
                          <Listbox
                            value={university}
                            onChange={(e: any) => {
                              setPage(1);
                              setUniversity(e);
                              setFaculty(null);
                            }}
                          >
                            {({ open }) => (
                              <>
                                <div className="relative mt-2">
                                  <Listbox.Button
                                    className="truncate relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900
                                     shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-900 text-sm sm:leading-6 cursor-pointer"
                                  >
                                    <span className="block truncate">
                                      {university ? (
                                        <>{university?.name}</>
                                      ) : (
                                        <span className="text-gray-400">{t("topics.search.selectUniversity")}</span>
                                      )}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                      <Listbox.Option
                                        key={"allUniversities"}
                                        className={({ active }) =>
                                          classNames(
                                            active ? "bg-gray-900 text-white" : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                                          )
                                        }
                                        value={null}
                                        onClick={() => handleUniversityChange(null)}
                                      >
                                        <>
                                          <span className="font-normal block truncate">
                                            {t("topics.search.allUniversities")}
                                          </span>
                                        </>
                                      </Listbox.Option>
                                      {universities.map((e: any) => (
                                        <Listbox.Option
                                          key={e.id}
                                          className={({ active }) =>
                                            classNames(
                                              active ? "bg-gray-900 text-white" : "text-gray-900",
                                              "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                                            )
                                          }
                                          value={e}
                                        >
                                          {({ university, active }: any) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  university ? "font-semibold" : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {e.name}
                                              </span>

                                              {university ? (
                                                <span
                                                  className={classNames(
                                                    active ? "text-white" : "text-gray-900",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </>
                      )}
                      {(isStudent || isSupervisor || university) && (
                        <>
                          {universities?.length === 0 && (
                            <legend className="block font-medium leading-6">{t("topics.search.faculty")}</legend>
                          )}
                          <Listbox
                            value={faculty}
                            onChange={(e: any) => {
                              setPage(1);
                              setFaculty(e);
                            }}
                          >
                            {({ open }) => (
                              <>
                                <div className="relative mt-2">
                                  <Listbox.Button className="truncate relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-900 text-sm sm:leading-6 cursor-pointer">
                                    <span className="block truncate">
                                      {faculty?.id ? (
                                        <>
                                          <span className="hidden xl:inline">
                                            {faculty?.name} ({faculty?.abbreviation})
                                          </span>
                                          <span className="inline xl:hidden">{faculty?.abbreviation}</span>
                                        </>
                                      ) : (
                                        <span className="text-gray-400">{t("topics.search.selectFaculty")}</span>
                                      )}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                      <Listbox.Option
                                        key={"allFaculties"}
                                        className={({ active }) =>
                                          classNames(
                                            active ? "bg-gray-900 text-white" : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                                          )
                                        }
                                        value={null}
                                        onClick={() => handleFacultyChange(null)}
                                      >
                                        <>
                                          <span className="font-normal block truncate">
                                            {t("topics.search.allFaculties")}
                                          </span>
                                        </>
                                      </Listbox.Option>
                                      {faculties.map((e: any) => (
                                        <Listbox.Option
                                          key={e.id}
                                          className={({ active }) =>
                                            classNames(
                                              active ? "bg-gray-900 text-white" : "text-gray-900",
                                              "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                                            )
                                          }
                                          value={e}
                                        >
                                          {({ faculty, active }: any) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  faculty ? "font-semibold" : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                <span className="hidden xl:inline">
                                                  {e?.name} ({e?.abbreviation})
                                                </span>
                                                <span className="inline xl:hidden">{e?.abbreviation}</span>
                                              </span>
                                              {faculty ? (
                                                <span
                                                  className={classNames(
                                                    active ? "text-white" : "text-gray-900",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </>
                      )}
                    </div>
                    <div className="w-full xl:w-1/3 pt-6 xl:pt-0 xl:pl-6">
                      <legend className="block font-medium leading-6">{t("topics.search.level")}</legend>
                      <div className="mt-2">
                        {filters.levels.map((option, optionIdx) => (
                          <div
                            key={option.value}
                            className={classNames(optionIdx === 2 ? "mb-0" : "mb-3", "flex items-center text-sm")}
                          >
                            <input
                              id={`level-${optionIdx}`}
                              name="level[]"
                              defaultValue={option.value}
                              type="checkbox"
                              className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-gray-900 focus:ring-gray-800 cursor-pointer"
                              onChange={() => handleCheckboxChange(option.value as keyof typeof levels)}
                              checked={levels[option.value as keyof typeof levels]}
                              defaultChecked={option.checked}
                            />
                            <label
                              htmlFor={`level-${optionIdx}`}
                              className="ml-3 min-w-0 flex-1 text-gray-900 font-medium cursor-pointer"
                            >
                              {t(`level.${option.label}`)}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </DisclosurePanel>
              </>
            )}
          </Disclosure>
          {(isFetching || isLoading) && (
            <div className="flex justify-center items-center">
              <Loader />
            </div>
          )}
          {!isFetching && !isLoading && (
            <>
              <ul role="list" className="divide-y divide-gray-100 -mt-4">
                {topics.map((topic: any) => (
                  <li
                    key={topic.id}
                    onClick={() => handleItemClick(topic)}
                    className="flex flex-shrink-0 justify-between cursor-pointer relative gap-x-8 py-8 group/topic"
                  >
                    <div className="min-w-0 flex-auto">
                      <div className="flex items-start">
                        {topic.company && (
                          <div className="mr-6 sm:mr-8 flex-shrink-0">
                            <NavLink
                              to={`/companies/${topic.company?.id}`}
                              className="whitespace-nowrap flex items-center gap-x-1 hover:opacity-75"
                            >
                              {topic.company?.picture && (
                                <img
                                  className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                                  src={`https://resources.thesisnavigator.com/${topic.company?.picture}`}
                                  alt=""
                                />
                              )}
                              {!topic.company?.picture && (
                                <div className="h-14 w-14 sm:h-20 sm:w-20 p-2 rounded-md">
                                  <BuildingOffice2Icon className="fill-gray-200" />
                                </div>
                              )}
                            </NavLink>
                          </div>
                        )}
                        {topic.university && (
                          <div className="mr-6 sm:mr-8 flex-shrink-0">
                            <NavLink
                              to={`/universities/${topic.university?.id}`}
                              className="whitespace-nowrap flex items-center gap-x-1 hover:opacity-75"
                            >
                              {topic.university?.picture && (
                                <img
                                  className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                                  src={`https://resources.thesisnavigator.com/${topic.university?.picture}`}
                                  alt=""
                                />
                              )}
                              {!topic.university?.picture && (
                                <div className="h-14 w-14 sm:h-20 sm:w-20 p-2 rounded-md">
                                  <AcademicCapIcon className="fill-gray-200" />
                                </div>
                              )}
                            </NavLink>
                          </div>
                        )}
                        {topic.university && (
                          <div className="flex-1 min-w-0">
                            <div className="flex items-start gap-x-4 text-xs">
                              <div className="flex flex-wrap items-center gap-x-2 text-xs leading-5 text-gray-500 overflow-hidden">
                                <NavLink
                                  to={`/universities/${topic.university.id}`}
                                  className="flex whitespace-nowrap truncate items-center gap-x-2 hover:opacity-75"
                                >
                                  <span className="font-semibold text-gray-900">{topic.university.name}</span>{" "}
                                  {topic.supervisors?.length > 0 && (<CheckBadgeIcon className="h-5 text-yellow-400"/>)}
                                </NavLink>
                                {topic.faculty?.name && (
                                  <>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300">
                                      <circle cx={1} cy={1} r={1} />
                                    </svg>
                                    <p className="text-gray-500">
                                      {topic.faculty.name}{" "}
                                      {topic.faculty.abbreviation && (
                                        <span className="sm:inline-block">({topic.faculty.abbreviation})</span>
                                      )}
                                    </p>
                                  </>
                                )}
                                {currentUser.fields?.some((item: any) => topic.topic_fields?.map((e: any) => e.fields.id)?.includes(item)) && (
                                  <div className="sm:flex flex-wrap items-center ml-2 py-1.5">
                                    <span className="items-center rounded-full px-3 py-1.5 text-xs font-medium bg-gray-900 text-white">
                                      Match
                                    </span>
                                  </div>
                                )}
                              </div>
                              {(isStudent || isExpert) && (
                                <div className="inline-block relative group/bookmark ml-auto">
                                  <BookmarkIcon
                                    onClick={(e: any) => handleBookmark(topic.id, topic.isBookmarked, e)}
                                    className={classNames(
                                      topic.isBookmarked
                                        ? "text-yellow-400 hover:text-yellow-300"
                                        : "text-gray-200 hover:text-gray-300",
                                      "w-5 flex-shrink-0 z-10"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <div className="hidden sm:block absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-20 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                                    {t("common.save")}
                                    <div className="invisible absolute left-9 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="group relative overflow-hidden">
                              <h3
                                className={classNames(
                                  topic.id === selectedTopic?.id
                                    ? "underline underline-offset-2"
                                    : "",
                                  "text-xl font-bold leading-7 text-gray-900 group-hover:text-gray-700 mt-1"
                                )}
                              >
                                {topic.title}
                                {topic.internship === "yes" ?
                                  <div className="inline-block relative group/bookmark ml-3">
                                    <BriefcaseIcon className="-mt-1.5 h-5 w-5 text-gray-400/60 inline" aria-hidden="true" />
                                    <div className="hidden sm:block absolute transition delay-300 duration-300 left-1/2 bottom-9 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-20 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                                      {t("common.internship")}
                                      <div className="invisible absolute left-9 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                                    </div>
                                  </div> :
                                  null}
                              </h3>
                              <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-xs mt-3 text-gray-500">
                                <time className="text-gray-400 mr-2">
                                  {!topic.updated
                                    ? moment(topic.created).locale(currentLanguage).format("lll")
                                    : moment(topic.updated).locale(currentLanguage).format("MMM DD, YYYY")
                                  }
                                </time>
                                {topic.university && !topic.active && <>({t("topics.search.inactive")})</>}
                                {topic.supervisors && topic.supervisors[0] && (
                                  <div className="flex shrink-0 items-center gap-x-6">
                                    <div className="flex min-w-0 flex-1 items-center space-x-3">
                                      <div className="flex-shrink-0">
                                        {topic.supervisors[0]?.image ? (
                                          <img
                                            className="w-6 h-6 rounded-full border object-cover"
                                            src={`https://resources.thesisnavigator.com/${topic.supervisors[0]?.image}`}
                                            alt=""
                                          />
                                        ) : (
                                          <img className="w-6 h-6 rounded-full" src={topic.supervisors[0]?.picture} alt="" />
                                        )}
                                      </div>
                                      <div className="min-w-0 flex-1">
                                        {topic.supervisors[0] && (
                                          <div className="flex items-center space-x-3">
                                            <p className="truncate text-xs text-gray-500 leading-6">
                                              {topic.supervisors[0]?.first_name}{" "}
                                              {topic.supervisors[0]?.last_name}{" "}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {topic.topic_fields?.map((field: any) => (
                                  <span
                                    key={field.fields.id}
                                    className={classNames(selectedFields.some((e: any) => e.id === field.fields.id) ? "border-gray-900" : "border-gray-300", "items-center rounded-full px-3 py-1.5 text-xs font-medium border text-gray-900")}
                                  >
                                    {t(`fields.${field.fields.name}`)}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        {topic.company && (
                          <div className="group relative w-full overflow-hidden sm:overflow-visible">
                            <div className="flex items-start gap-x-2 text-xs text-gray-500">
                              <div className="flex flex-wrap items-center gap-x-2 leading-5">
                                <NavLink
                                  to={`/companies/${topic.company?.id}`}
                                  className="whitespace-nowrap flex items-center gap-x-2 hover:opacity-75"
                                >
                                  <span className="font-semibold text-gray-900">{topic.company?.name}</span>{" "}
                                  <CheckBadgeIcon className="h-5 text-yellow-400" />
                                </NavLink>
                                {topic.company?.addresses && (
                                  <>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300 hidden sm:flex">
                                      <circle cx={1} cy={1} r={1} />
                                    </svg>
                                    <div className="hidden sm:flex flex-wrap items-center gap-x-2">
                                      <span className="truncate leading-6 text-gray-500">
                                        {topic.company?.addresses?.city}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {topic.company?.size && (
                                  <>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300 hidden sm:flex">
                                      <circle cx={1} cy={1} r={1} />
                                    </svg>
                                    <div className="hidden sm:flex flex-wrap items-center gap-x-2 ">
                                      <span className="leading-6 text-gray-500">
                                        {t(`company.size.type.${topic.company?.size}`)}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {currentUser.fields?.some((item: any) => topic.topic_fields?.map((e: any) => e.fields.id)?.includes(item)) && (
                                  <div className="sm:flex flex-wrap items-center ml-2 py-1.5">
                                    <span className="items-center rounded-full px-3 py-1.5 text-xs font-medium bg-gray-900 text-white">
                                      Match
                                    </span>
                                  </div>
                                )}
                              </div>
                              {(isStudent || isExpert) && (
                                <div className="inline-block relative group/bookmark ml-auto items-center mt-1">
                                  <BookmarkIcon
                                    onClick={(e: any) => handleBookmark(topic.id, topic.isBookmarked, e)}
                                    className={classNames(
                                      topic.isBookmarked
                                        ? "text-yellow-400 hover:text-yellow-300"
                                        : "text-gray-300 hover:text-gray-400",
                                      "w-5 flex-shrink-0 z-10"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <div className="hidden sm:block absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-22 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                                    {t("common.save")}
                                    <div className="invisible absolute left-9 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                                  </div>
                                </div>
                              )}
                              {isSupervisor && (
                                <div className="inline-block relative group/bookmark ml-auto mt-1">
                                  <AcademicCapIcon
                                    onClick={(e: any) => handleSupervisorTopicInterest(topic.id, topic.interestedInSupervising, e)}
                                    className={classNames(
                                      topic.interestedInSupervising
                                        ? "text-yellow-400 hover:text-yellow-300"
                                        : "text-gray-300 hover:text-gray-400",
                                      "w-5 flex-shrink-0 z-10"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <div className="hidden sm:block absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-22 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                                    {t("topics.supervisor.interestedInSupervising")}
                                    <div className="invisible absolute left-9 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <h3
                              className={classNames(
                                topic.id === selectedTopic?.id
                                  ? "underline underline-offset-2"
                                  : "",
                                "text-xl font-bold leading-7 text-gray-900 group-hover:text-gray-700 mt-1"
                              )}
                            >
                              {topic.title}
                              {topic.internship === "yes" ?
                                <div className="inline-block relative group/bookmark ml-3">
                                  <BriefcaseIcon className="-mt-1.5 h-5 w-5 text-gray-400/60 inline" aria-hidden="true" />
                                  <div className="hidden sm:block absolute transition delay-300 duration-300 left-1/2 bottom-9 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-20 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                                    {t("common.internship")}
                                    <div className="invisible absolute left-9 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                                  </div>
                                </div> :
                                null}
                            </h3>
                            <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-xs mt-3 text-gray-500">
                              <time className="text-gray-400 mr-2">
                                {!topic.updated
                                  ? moment(topic.created).locale(currentLanguage).format("lll")
                                  : moment(topic.updated).locale(currentLanguage).format("MMM DD, YYYY")
                                }
                              </time>
                              {topic.university && !topic.active && <>({t("topics.search.inactive")})</>}
                              {topic.topic_experts && topic.topic_experts[0] && (
                                <div className="flex shrink-0 items-center gap-x-6">
                                  <div className="flex min-w-0 flex-1 items-center space-x-3">
                                    <div className="flex-shrink-0">
                                      {topic.topic_experts[0]?.experts.users.image ? (
                                        <img
                                          className="w-6 h-6 rounded-full border object-cover"
                                          src={`https://resources.thesisnavigator.com/${topic.topic_experts[0]?.experts.users.image}`}
                                          alt=""
                                        />
                                      ) : (
                                        <img className="w-6 h-6 rounded-full" src={topic.topic_experts[0]?.experts.users.picture} alt="" />
                                      )}
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      {topic.topic_experts[0] && (
                                        <div className="flex items-center space-x-3">
                                          <p className="truncate text-xs text-gray-500 leading-6">
                                            {topic.topic_experts[0]?.experts.users.first_name}{" "}
                                            {topic.topic_experts[0]?.experts.users.last_name}{" "}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {topic.topic_fields?.map((field: any) => (
                                <span
                                  key={field.fields.id}
                                  className={classNames(selectedFields.some((e: any) => e.id === field.fields.id) ? "border-gray-900" : "border-gray-300", "items-center rounded-full px-3 py-1.5 text-xs font-medium border text-gray-900")}
                                >
                                  {t(`fields.${field.fields.name}`)}
                                </span>
                              ))}
                            </div>
                            {topic.supervisor_topic_interests?.length > 0 && (
                              <div className="mt-3 flex items-center space-x-4">
                                <div className="relative group/bookmark">
                                  <AcademicCapIcon
                                    className="text-yellow-400 hover:text-yellow-300 w-5 flex-shrink-0 z-10"
                                    aria-hidden="true"
                                  />
                                  <div className="hidden sm:block absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-28 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                                    {t("topics.supervisor.interestedInSupervising")}
                                    <div className="invisible absolute left-12 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                                  </div>
                                </div>
                                <div className="flex -space-x-1.5 overflow-hidden items-center flex-shrink-0">
                                  {topic.supervisor_topic_interests.slice(0, 2).map((e: any) =>
                                    e?.supervisors.users.image ? (
                                      <img
                                        className="inline-block h-6 w-6 rounded-full border object-cover"
                                        src={`https://resources.thesisnavigator.com/${e.supervisors.users.image}`}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="inline-block h-6 w-6 rounded-full border object-cover"
                                        src={e.supervisors.users.picture}
                                        alt=""
                                      />
                                    )
                                  )}
                                </div>
                                <div className="text-gray-500 text-xs inline-block">
                                  {topic.supervisor_topic_interests.length === 1 ? t("topics.supervisor.interestedOne", { value: `${topic.supervisor_topic_interests[0].supervisors.users.first_name} ${topic.supervisor_topic_interests[0].supervisors.users.last_name}` }) : t("topics.supervisor.interestedMany", { value1: `${topic.supervisor_topic_interests[0].supervisors.users.first_name} ${topic.supervisor_topic_interests[0].supervisors.users.last_name}`, value2: topic.supervisor_topic_interests.length - 1 })}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="flex flex-none items-center gap-x-4">
                      <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </div> */}
                  </li>
                ))}
              </ul>
              {count > 0 && (
                <nav className="flex items-center justify-between bg-white py-6" aria-label="Pagination">
                  <div className="sm:block">
                    <p className="text-sm text-gray-700">
                      {count >= PAGE_SIZE && (
                        <>
                          <span className="font-semibold">{PAGE_SIZE * (page - 1) + 1}</span> {t("common.to")}{" "}
                          <span className="font-semibold">{page >= Math.ceil(count / PAGE_SIZE) ? count : PAGE_SIZE * page}</span> {t("common.of")}
                        </>
                      )}{" "}
                      <span className="font-semibold">{count}</span> {t("common.topics")}
                    </p>
                  </div>
                  <div className="flex flex-1 justify-end">
                    <button
                      onClick={handlePrevPage}
                      disabled={page === 1}
                      className="relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus-visible:outline-offset-0 disabled:opacity-50"
                    >
                      {t("common.previous")}
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={page >= Math.ceil(count / PAGE_SIZE)}
                      className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus-visible:outline-offset-0 disabled:opacity-50"
                    >
                      {t("common.next")}
                    </button>
                  </div>
                </nav>
              )}
            </>
          )}
          {!isFetching && !isLoading && topics.length === 0 && (
            <div className="bg-white">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="mt-12 text-2xl font-bold tracking-tight text-gray-900">
                  {t("topics.search.noResults.title")}
                </h1>
                <p className="mt-2 leading-7 text-gray-600 sm:mt-6 sm:leading-8">
                  {t("topics.search.noResults.description")}
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <button
                    onClick={handleClearSearch}
                    className="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                  >
                    {t("topics.search.noResults.button")}
                  </button>
                  <NavLink to="/feedback" className="text-sm font-semibold text-gray-900 underline">
                    {t("topics.search.noResults.secondaryButton")} <span aria-hidden="true">&rarr;</span>
                  </NavLink>
                </div>
              </div>
            </div>
          )}
        </div>
        {selectedTopic && (
          <div className="w-1/2 pl-8 hidden sm:block">
            <div className="fixed top-14 border-l border-gray-100 bottom-0 overflow-auto" ref={scrollRef}>
              <div className="max-h-full">
                <button
                  type="button"
                  className="absolute top-8 right-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none outline-none z-10"
                  onClick={() => {
                    setOpen(false);
                    setSelectedTopic(null);
                  }}
                >
                  <span className="absolute -inset-2.5" />
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                {selectedTopic && (
                  <Topic
                    topic={selectedTopic}
                    onClose={() => {
                      setOpen(false);
                      setSelectedTopic(null);
                    }}
                    onBookmark={() => bookmarkTopic(selectedTopic.id)}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {selectedTopic && (
          <Transition.Root
            show={open}
            as={Fragment}
            beforeEnter={isMobile ? () => (document.body.style.overflow = "hidden") : undefined}
            beforeLeave={isMobile ? () => (document.body.style.overflow = "auto") : undefined}
          >
            <Dialog as="div" className="relative z-50 block sm:hidden" onClose={setOpen}>
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-300 sm:duration-500"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-300 sm:duration-500"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                          <div className="px-6">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900"></Dialog.Title>
                              <div className="ml-3 flex h-7 absolute right-5 top-8 z-10 items-center">
                                <button
                                  type="button"
                                  className="relative rounded-md text-gray-400 hover:text-gray-500 focus:outline-none outline-none"
                                  onClick={() => {
                                    setOpen(false);
                                  }}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="relative flex-1">
                            <Topic
                              topic={selectedTopic}
                              onClose={() => {
                                setOpen(false);
                              }}
                            />
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </div>
      <Transition.Root show={onboardingOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOnboardingOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                      <RectangleGroupIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t(`topics.onboarding.${currentUser?.type}.title`)}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          <ul
                            role="list"
                            className="text-left mt-4 sm:mt-8 max-w-xl space-y-4 sm:space-y-8 text-gray-600"
                          >
                            <li className="flex gap-x-3">
                              <CheckCircleIcon
                                className="sm:mt-1 h-6 w-6 flex-none text-gray-900"
                                aria-hidden="true"
                              />
                              <span
                                className="self-center"
                                dangerouslySetInnerHTML={{
                                  __html: t(`topics.onboarding.${currentUser?.type}.item1`),
                                }}
                              />
                            </li>
                            <li className="flex gap-x-3">
                              <CheckCircleIcon
                                className="sm:mt-1 h-6 w-6 flex-none text-gray-900"
                                aria-hidden="true"
                              />
                              <span
                                className="self-center"
                                dangerouslySetInnerHTML={{
                                  __html: t(`topics.onboarding.${currentUser?.type}.item2`),
                                }}
                              />
                            </li>
                            <li className="flex gap-x-3">
                              <CheckCircleIcon
                                className="sm:mt-1 h-6 w-6 flex-none text-gray-900"
                                aria-hidden="true"
                              />
                              <span
                                className="self-center"
                                dangerouslySetInnerHTML={{
                                  __html: t(`topics.onboarding.${currentUser?.type}.item3`),
                                }}
                              />
                            </li>
                            {isExpert && (
                              <li className="flex gap-x-3">
                                <CheckCircleIcon
                                  className="sm:mt-1 h-6 w-6 flex-none text-gray-900"
                                  aria-hidden="true"
                                />
                                <span
                                  className="self-center"
                                  dangerouslySetInnerHTML={{
                                    __html: t(`topics.onboarding.${currentUser?.type}.item4`),
                                  }}
                                />
                              </li>
                            )}
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus:outline-none"
                      onClick={() => {
                        localStorage.setItem("onboarding-topics", "true");
                        setOnboardingOpen(false);
                      }}
                    >
                      {t(`topics.onboarding.${currentUser?.type}.button`)}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal isOpen={isSupervisorConfirmationOpen} onClose={closeSupervisorConfirmation}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Confirm}
          title={t("topics.supervisor.apply.confirm.title")}
          body={t("topics.supervisor.apply.confirm.description")}
          cancel={t("common.cancel")}
          save={t("common.confirm")}
          onCancel={closeSupervisorConfirmation}
          onSave={applySupervisorTopicInterest}
        />
      </Modal>
    </div>
  );
};

export default Topics;
