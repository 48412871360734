import {createContext} from "react";
import {UserType} from "../models";

export type AuthUser = {
  id: string;
  entityId: string;
  role?: string | null;
  type?: UserType | null;
  image?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  company?: string | null;
  companyId?: string | null;
  university?: string | null;
  universityId?: string | null;
  universityPicture?: string | null;
  sysadmin?: boolean;
  fields?: string[] | [];
  offer_interviews?: boolean;
  research_area?: string | null;
}

export interface CurrentUserContextType {
  currentUser: AuthUser;
  setCurrentUser: (user: Partial<CurrentUserContextType["currentUser"]>) => void;
}

export const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUser: {
    id: "",
    entityId: "",
  }, setCurrentUser: () => {
  }
});
