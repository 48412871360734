/* tslint:disable */
/* eslint-disable */
/**
 * Thesis Navigator API
 * All documentation for the Thesis Navigator API.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ThesisProject } from '../model';
// @ts-ignore
import type { ThesisProjectPage } from '../model';
/**
 * SupervisorThesisProjectsApi - axios parameter creator
 * @export
 */
export const SupervisorThesisProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all Supervisor Thesis Projects
         * @param {Array<FindAllSupervisorThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {boolean} [me] Is Thesis Project assigned to the current user.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSupervisorThesisProjects: async (state?: Array<FindAllSupervisorThesisProjectsStateEnum>, me?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/supervisor/thesis-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (me !== undefined) {
                localVarQueryParameter['me'] = me;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupervisorThesisProject: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSupervisorThesisProject', 'id', id)
            const localVarPath = `/api/v2/supervisor/thesis-projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupervisorThesisProjectsApi - functional programming interface
 * @export
 */
export const SupervisorThesisProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupervisorThesisProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find all Supervisor Thesis Projects
         * @param {Array<FindAllSupervisorThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {boolean} [me] Is Thesis Project assigned to the current user.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSupervisorThesisProjects(state?: Array<FindAllSupervisorThesisProjectsStateEnum>, me?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllSupervisorThesisProjects(state, me, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorThesisProjectsApi.findAllSupervisorThesisProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSupervisorThesisProject(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSupervisorThesisProject(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorThesisProjectsApi.findSupervisorThesisProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupervisorThesisProjectsApi - factory interface
 * @export
 */
export const SupervisorThesisProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupervisorThesisProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find all Supervisor Thesis Projects
         * @param {SupervisorThesisProjectsApiFindAllSupervisorThesisProjectsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSupervisorThesisProjects(requestParameters: SupervisorThesisProjectsApiFindAllSupervisorThesisProjectsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProjectPage> {
            return localVarFp.findAllSupervisorThesisProjects(requestParameters.state, requestParameters.me, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {SupervisorThesisProjectsApiFindSupervisorThesisProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupervisorThesisProject(requestParameters: SupervisorThesisProjectsApiFindSupervisorThesisProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProject> {
            return localVarFp.findSupervisorThesisProject(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findAllSupervisorThesisProjects operation in SupervisorThesisProjectsApi.
 * @export
 * @interface SupervisorThesisProjectsApiFindAllSupervisorThesisProjectsRequest
 */
export interface SupervisorThesisProjectsApiFindAllSupervisorThesisProjectsRequest {
    /**
     * The state of the Thesis Project. Possible values: applied, agreed
     * @type {Array<'applied' | 'withdrawn' | 'rejected' | 'agreed' | 'canceled' | 'completed'>}
     * @memberof SupervisorThesisProjectsApiFindAllSupervisorThesisProjects
     */
    readonly state?: Array<FindAllSupervisorThesisProjectsStateEnum>

    /**
     * Is Thesis Project assigned to the current user.
     * @type {boolean}
     * @memberof SupervisorThesisProjectsApiFindAllSupervisorThesisProjects
     */
    readonly me?: boolean

    /**
     * Page number, default is 1
     * @type {number}
     * @memberof SupervisorThesisProjectsApiFindAllSupervisorThesisProjects
     */
    readonly page?: number

    /**
     * Page size, default is 50
     * @type {number}
     * @memberof SupervisorThesisProjectsApiFindAllSupervisorThesisProjects
     */
    readonly size?: number
}

/**
 * Request parameters for findSupervisorThesisProject operation in SupervisorThesisProjectsApi.
 * @export
 * @interface SupervisorThesisProjectsApiFindSupervisorThesisProjectRequest
 */
export interface SupervisorThesisProjectsApiFindSupervisorThesisProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof SupervisorThesisProjectsApiFindSupervisorThesisProject
     */
    readonly id: string
}

/**
 * SupervisorThesisProjectsApi - object-oriented interface
 * @export
 * @class SupervisorThesisProjectsApi
 * @extends {BaseAPI}
 */
export class SupervisorThesisProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Find all Supervisor Thesis Projects
     * @param {SupervisorThesisProjectsApiFindAllSupervisorThesisProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorThesisProjectsApi
     */
    public findAllSupervisorThesisProjects(requestParameters: SupervisorThesisProjectsApiFindAllSupervisorThesisProjectsRequest = {}, options?: RawAxiosRequestConfig) {
        return SupervisorThesisProjectsApiFp(this.configuration).findAllSupervisorThesisProjects(requestParameters.state, requestParameters.me, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Thesis Project by ID.
     * @param {SupervisorThesisProjectsApiFindSupervisorThesisProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorThesisProjectsApi
     */
    public findSupervisorThesisProject(requestParameters: SupervisorThesisProjectsApiFindSupervisorThesisProjectRequest, options?: RawAxiosRequestConfig) {
        return SupervisorThesisProjectsApiFp(this.configuration).findSupervisorThesisProject(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllSupervisorThesisProjectsStateEnum = {
    Applied: 'applied',
    Withdrawn: 'withdrawn',
    Rejected: 'rejected',
    Agreed: 'agreed',
    Canceled: 'canceled',
    Completed: 'completed'
} as const;
export type FindAllSupervisorThesisProjectsStateEnum = typeof FindAllSupervisorThesisProjectsStateEnum[keyof typeof FindAllSupervisorThesisProjectsStateEnum];
