import {useAuth0} from "@auth0/auth0-react";
import {useContext, useEffect, useState} from "react";
import {CurrentUserContext} from "../../contexts/userContext";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import {ArrowPathIcon, CheckBadgeIcon, CheckIcon, ChevronUpDownIcon, PlusIcon} from "@heroicons/react/24/solid";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/react";
import Api from "../../api/client";
import {TopicPage} from "@api/generated";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemContent,
  ListItemLineStatus,
  ListItemTitle,
  ListNoData,
  ListPagination
} from "../../components/list";
import {Breadcrumb, BreadcrumbList, BreadcrumbListItem, BreadcrumbSeparator} from "../../components/breadcrumb";
import {ListItemLineStatusBadge} from "../../components/list/ListItemLineStatus";
import { AvatarType } from "src/components/list/ListItemAvatar";

const topicsStates = [
  {id: 1, value: 'all'},
  {id: 2, value: 'published'},
  {id: 3, value: 'draft'}
]

export default function SupervisorTopics() {
  const {currentUser} = useContext(CurrentUserContext);
  const {isAuthenticated, isLoading} = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [topicPage, setTopicPage] = useState<TopicPage | undefined>(undefined);
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const pageParam = searchParams.get("page");
  const parsedPage = pageParam ? parseInt(pageParam, 10) || 1 : 1;
  const [page, setPage] = useState<number>(parsedPage);
  const initialTopicsStateFromURL = searchParams.get("state");
  const initialTopicsStateFromLocalStorage = localStorage.getItem("supervisor-topics-state");
  const initialTopicsState = initialTopicsStateFromURL || initialTopicsStateFromLocalStorage || 'all';
  const [selectedTopicsState, setSelectedTopicsState] = useState(initialTopicsState);

  const getTopics = async () => {
    setIsFetching(true);
    let published: boolean | undefined = undefined;
    if (selectedTopicsState === "published") {
      published = true
    } else if (selectedTopicsState === "draft") {
      published = false
    }
    await Api.SupervisorTopics.findAllSupervisorTopics({
      type: "me",
      published: published,
      page: page,
    })
      .then((response) => {
        setTopicPage(response.data)
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        }
        console.error("Error fetching data:", error);
      });
  };

  const handleTopicsStateChange = (value: string) => {
    setPage(1);
    setSelectedTopicsState(value);
  };

  const handleRemoveTopicsState = () => {
    setPage(1);
    setSelectedTopicsState("all");
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  const handleReloadTopics = async () => {
    if (page > 1) {
      setPage(1);
    } else {
      await getTopics();
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();

    if (selectedTopicsState === 'all') {
      localStorage.removeItem('supervisor-topics-state');
    } else {
      localStorage.setItem('supervisor-topics-state', selectedTopicsState);
    }

    if (selectedTopicsState !== 'all') {
      params.append("state", selectedTopicsState.toString());
    }
    params.append("page", page.toString());
    navigate(`?${params.toString()}`, {replace: true});
  }, [selectedTopicsState, page]);

  useEffect(() => {
    getTopics();
  }, [currentUser, selectedTopicsState, page]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbListItem>
            <NavLink to={`/user/profile`}>
              {t("common.myProfile")}
            </NavLink>
          </BreadcrumbListItem>
          <BreadcrumbSeparator/>
          <BreadcrumbListItem>
            <NavLink to={`/supervisor/topics`}>
              {t("common.myTopics")}
            </NavLink>
          </BreadcrumbListItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="pb-6 sm:pb-6">
        <div className="mt-2 flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl tracking-tight">
              {t("common.myTopics")}
            </h2>
          </div>
        </div>
      </div>
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          {t("common.filters")}
        </h2>
        <div className="border-b border-gray-100 bg-white pb-6 mb-6">
          <div className="flex items-center justify-between space-x-6">
            <div className="w-44 sm:w-56">
              <Listbox value={selectedTopicsState} onChange={setSelectedTopicsState}>
                <div className="relative">
                  <ListboxButton
                    className="relative w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none text-sm leading-6 cursor-pointer">
                    <span
                      className={classNames(selectedTopicsState === 'all' ? "text-gray-400" : "text-gray-900", "block truncate")}>{t(`common.${selectedTopicsState}`)}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400"/>
                    </span>
                  </ListboxButton>
                  <ListboxOptions
                    transition
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in text-sm"
                  >
                    {topicsStates.map((state) => (
                      <ListboxOption
                        key={state.id}
                        value={state.value}
                        className="group relative cursor-pointer select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-gray-900 data-[focus]:text-white"
                        onClick={() => handleTopicsStateChange(state.value)}
                      >
                        <span className="block truncate font-normal group-data-[selected]:font-semibold">{t(`common.${state.value}`)}</span>
                        <span
                          className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-900 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                          <CheckIcon aria-hidden="true" className="h-5 w-5"/>
                        </span>
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
              </Listbox>
            </div>
            <div className="flex items-center space-x-6">
              <div className="flex flex-shrink-0">
                <NavLink
                  className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 overflow-hidden"
                  to="/supervisor/topics/new"
                >
                  <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
                  <span className="truncate max-w-20 sm:max-w-none">
                    {t("company.topicsList.newTopic")}
                  </span>
                </NavLink>
              </div>
              <button
                type="button"
                className="h-6 w-6 text-gray-400 hover:text-gray-600"
                onClick={() => handleReloadTopics()}
              >
                <ArrowPathIcon/>
              </button>
            </div>
          </div>
        </div>
        {(selectedTopicsState !== 'all') && (
          <div className="bg-gray-50 -mx-6 sm:-mx-8 mb-6">
            <div className="mx-auto px-8 py-3 sm:flex sm:items-center">
              <h3 className="text-sm font-medium text-gray-500 hidden sm:block">
                {t("common.filters")}
                <span className="sr-only">, active</span>
              </h3>
              <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"/>
              <div className="mt-0 sm:ml-4">
                <div className="-m-1 flex flex-wrap items-center">
                  {selectedTopicsState !== "all" && (
                    <span
                      key={selectedTopicsState}
                      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                    >
                      <span>{t(`common.${selectedTopicsState}`)}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                        onClick={handleRemoveTopicsState}
                      >
                        <span className="sr-only">Remove filter for {selectedTopicsState}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7"/>
                        </svg>
                      </button>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader/>
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <List className="[&>*:first-child]:pt-0 items-start">
          {
            topicPage?.items.map((topic) => {
              const badges: ListItemLineStatusBadge[] = [];
              if (topic.published === false) {
                badges.push({label: t("common.draft"), color: "yellow"})
              }
              topic.fields?.forEach((field) => badges.push({label: t(`fields.${field.name}`)}))
              return (
                <ListItem key={topic.id}>
                  <ListItemAvatar avatar={topic.university.picture} avatarLink={`/universities/${topic.university.id}`} avatarType={AvatarType.University}></ListItemAvatar>
                  <ListItemContent className="items-start">
                    <NavLink
                      to={`/universities/${topic?.university?.id}`}
                      className="whitespace-nowrap flex items-center gap-x-2 mb-1 hover:opacity-75"
                    >
                      <span className="font-semibold text-xs text-gray-900">{topic?.university?.name}</span>{" "}
                      <CheckBadgeIcon className="h-5 text-yellow-400" />
                    </NavLink>
                    <NavLink to={`/supervisor/topics/${topic.id}`}>
                      <ListItemTitle>{topic.title}</ListItemTitle>
                    </NavLink>
                    <ListItemLineStatus
                      created={topic.created}
                      updated={topic.updated}
                      users={topic.supervisors.map(e => e.user)}
                      badges={badges}
                      thesisProjects={topic.thesis_projects?.length || 0}>
                    </ListItemLineStatus>
                  </ListItemContent>
                </ListItem>
              )
            })
          }
        </List>
      )}
      {isAuthenticated && !isLoading && !isFetching && topicPage?.items.length === 0 && (
        <ListNoData type="topic">
          {t("common.noTopics")}
        </ListNoData>
      )}
      {
        !isLoading && !isFetching && topicPage && topicPage.total > 0 && (
          <ListPagination
            page={topicPage.page}
            size={topicPage.size}
            total={topicPage.total}
            totalPage={topicPage.totalPages}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            singularKey="common.topic"
            pluralKey="common.topics"
          />
        )
      }
    </div>
  );
}
