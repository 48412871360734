import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
// => Tiptap packages
import { useEditor, EditorContent, Editor, BubbleMenu, Extension } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Link from "@tiptap/extension-link";
import Bold from "@tiptap/extension-bold";
// import Underline from "@tiptap/extension-underline";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import History from "@tiptap/extension-history";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import Placeholder from "@tiptap/extension-placeholder";
import * as Icons from "./Icons";
import { LinkModal } from "./LinkModal";
import { useTranslation } from "react-i18next";
import { HardBreak } from '@tiptap/extension-hard-break';

export interface TextEditorProps {
  placeholder: string;
  content: string;
  onChange: (content: string) => void;
  disabled?: boolean;
}

export function TextEditor({ content, placeholder, onChange, disabled = false }: TextEditorProps) {
  const ShiftEnterExtension = Extension.create({
    addKeyboardShortcuts() {
      return {
        "Shift-Enter": ({ editor }) => {
          editor.commands.enter();
          return true;
        },
      };
    },
  });
  const editor = useEditor({
    extensions: [
      Document,
      History,
      Paragraph,
      Text,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Bold,
      // Underline,
      Italic,
      Strike,
      ListItem,
      BulletList,
      OrderedList,
      Placeholder.configure({
        placeholder,
      }),
      HardBreak
      // ShiftEnterExtension,
    ],
    content,
    onUpdate: ({ editor }) => {
      onChange(
        editor
          .getHTML()
          .replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, "<li>$1<$2$3>")
      );
    },
  }) as Editor;
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState<string>("");
  const { t, i18n } = useTranslation();

  const openModal = useCallback(
    (e: any) => {
      e.preventDefault();
      setUrl(editor.getAttributes("link").href);
      setIsOpen(true);
    },
    [editor]
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setUrl("");
  }, []);

  const saveLink = useCallback(() => {
    if (url) {
      editor.chain().focus().extendMarkRange("link").setLink({ href: url, target: "_blank" }).run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    closeModal();
  }, [editor, url, closeModal]);

  const removeLink = useCallback(
    (e: any) => {
      e.preventDefault();
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      closeModal();
    },
    [editor, closeModal]
  );

  const toggleBold = useCallback(
    (e: any) => {
      e.preventDefault();
      editor.chain().focus().toggleBold().run();
    },
    [editor]
  );

  const toggleUnderline = useCallback(
    (e: any) => {
      e.preventDefault();
      editor.chain().focus().toggleUnderline().run();
    },
    [editor]
  );

  const toggleItalic = useCallback(
    (e: any) => {
      e.preventDefault();
      editor.chain().focus().toggleItalic().run();
    },
    [editor]
  );

  const toggleStrike = useCallback(
    (e: any) => {
      e.preventDefault();
      editor.chain().focus().toggleStrike().run();
    },
    [editor]
  );

  const toggleOrderedList = useCallback(
    (e: any) => {
      e.preventDefault();
      editor.chain().focus().toggleOrderedList().run();
    },
    [editor]
  );

  const toggleBulletList = useCallback(
    (e: any) => {
      e.preventDefault();
      editor.chain().focus().toggleBulletList().run();
    },
    [editor]
  );

  useEffect(() => {
    editor?.setOptions({ editable: !disabled });
    if (editor && disabled) {
      editor.setOptions({
        editorProps: {
          attributes: {
            class: "disabled",
          },
        },
      });
    } else {
      editor.setOptions({
        editorProps: {
          attributes: {
            class: "enabled",
          },
        },
      });
    }
  }, [editor, disabled]);

  useEffect(() => {
    if (!editor) return;
    for (const extension of editor.extensionManager.extensions) {
      if (extension.name === Placeholder.name) {
        extension.options.placeholder = placeholder;
      }
    }
    editor.setOptions();
  }, [editor, placeholder]);

  useEffect(() => {
    if (editor?.isEmpty && content) {
      editor.commands.setContent(content.replace(/\n/g, "<p>"));
    }
    editor?.setOptions({ editable: !disabled });
  }, [editor, content]);

  if (!editor) {
    return null;
  }

  return (
    <div className="editor">
      <div className={classNames(disabled ? "disabled" : "", "menu")}>
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("bold"),
          })}
          onClick={toggleBold}
          disabled={disabled}
        >
          <Icons.Bold />
        </button>
        {/* <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("underline"),
          })}
          onClick={toggleUnderline}
          disabled={disabled}
        >
          <Icons.Underline />
        </button> */}
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("italic"),
          })}
          onClick={toggleItalic}
          disabled={disabled}
        >
          <Icons.Italic />
        </button>
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("strike"),
          })}
          onClick={toggleStrike}
          disabled={disabled}
        >
          <Icons.Strikethrough />
        </button>
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("link"),
          })}
          onClick={openModal}
          disabled={disabled}
        >
          <Icons.Link />
        </button>
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("bulletList"),
          })}
          onClick={toggleBulletList}
          disabled={disabled}
        >
          <Icons.BulletList />
        </button>
        <button
          className={classNames("menu-button", {
            "is-active": editor.isActive("orderedList"),
          })}
          onClick={toggleOrderedList}
          disabled={disabled}
        >
          <Icons.OrderedList />
        </button>
      </div>

      {!disabled && (
        <BubbleMenu
          className="bubble-menu-light"
          tippyOptions={{ duration: 150 }}
          editor={editor}
          shouldShow={({ editor, view, state, oldState, from, to }) => {
            return from === to && editor.isActive("link");
          }}
        >
          <button type="button" onClick={openModal} className="text-sm font-semibold leading-6 text-gray-900">
            {t("common.edit")}
          </button>
          <button
            type="submit"
            onClick={removeLink}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            {t("common.remove")}
          </button>

          {/* <button className="button" onClick={openModal}>
          Edit
        </button>
        <button className="button-remove" onClick={removeLink}>
          Remove
        </button> */}
        </BubbleMenu>
      )}

      <div className="max-h-128 overflow-y-auto rounded ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-gray-900 shadow-sm">
        <EditorContent editor={editor} />
      </div>

      {!disabled && (
        <LinkModal
          url={url}
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Edit Link Modal"
          closeModal={closeModal}
          onChangeUrl={(e: any) => setUrl(e.target.value)}
          onSaveLink={saveLink}
          onRemoveLink={removeLink}
        />
      )}
    </div>
  );
}
