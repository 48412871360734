/* tslint:disable */
/* eslint-disable */
/**
 * Thesis Navigator API
 * All documentation for the Thesis Navigator API.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Topic } from '../model';
// @ts-ignore
import type { TopicPage } from '../model';
/**
 * StudentTopicsApi - axios parameter creator
 * @export
 */
export const StudentTopicsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all Topics
         * @param {Array<string>} [industries] Industries ID
         * @param {Array<FindAllStudentTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {string} [search] Search terms.
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllStudentTopics: async (industries?: Array<string>, levels?: Array<FindAllStudentTopicsLevelsEnum>, search?: string, faculty?: string, bookmarked?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/student/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (industries) {
                localVarQueryParameter['industries'] = industries;
            }

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (faculty !== undefined) {
                localVarQueryParameter['faculty'] = faculty;
            }

            if (bookmarked !== undefined) {
                localVarQueryParameter['bookmarked'] = bookmarked;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStudentTopicById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findStudentTopicById', 'id', id)
            const localVarPath = `/api/v2/student/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentTopicsApi - functional programming interface
 * @export
 */
export const StudentTopicsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentTopicsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find all Topics
         * @param {Array<string>} [industries] Industries ID
         * @param {Array<FindAllStudentTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {string} [search] Search terms.
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllStudentTopics(industries?: Array<string>, levels?: Array<FindAllStudentTopicsLevelsEnum>, search?: string, faculty?: string, bookmarked?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllStudentTopics(industries, levels, search, faculty, bookmarked, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentTopicsApi.findAllStudentTopics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStudentTopicById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStudentTopicById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentTopicsApi.findStudentTopicById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StudentTopicsApi - factory interface
 * @export
 */
export const StudentTopicsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentTopicsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find all Topics
         * @param {StudentTopicsApiFindAllStudentTopicsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllStudentTopics(requestParameters: StudentTopicsApiFindAllStudentTopicsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<TopicPage> {
            return localVarFp.findAllStudentTopics(requestParameters.industries, requestParameters.levels, requestParameters.search, requestParameters.faculty, requestParameters.bookmarked, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {StudentTopicsApiFindStudentTopicByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStudentTopicById(requestParameters: StudentTopicsApiFindStudentTopicByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.findStudentTopicById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findAllStudentTopics operation in StudentTopicsApi.
 * @export
 * @interface StudentTopicsApiFindAllStudentTopicsRequest
 */
export interface StudentTopicsApiFindAllStudentTopicsRequest {
    /**
     * Industries ID
     * @type {Array<string>}
     * @memberof StudentTopicsApiFindAllStudentTopics
     */
    readonly industries?: Array<string>

    /**
     * The level of the topic. Possible values: bsc, msc, phd
     * @type {Array<'bsc' | 'msc' | 'phd'>}
     * @memberof StudentTopicsApiFindAllStudentTopics
     */
    readonly levels?: Array<FindAllStudentTopicsLevelsEnum>

    /**
     * Search terms.
     * @type {string}
     * @memberof StudentTopicsApiFindAllStudentTopics
     */
    readonly search?: string

    /**
     * Faculty ID
     * @type {string}
     * @memberof StudentTopicsApiFindAllStudentTopics
     */
    readonly faculty?: string

    /**
     * Is Topic Bookmarked
     * @type {boolean}
     * @memberof StudentTopicsApiFindAllStudentTopics
     */
    readonly bookmarked?: boolean

    /**
     * Page number, default is 1
     * @type {number}
     * @memberof StudentTopicsApiFindAllStudentTopics
     */
    readonly page?: number

    /**
     * Page size, default is 50
     * @type {number}
     * @memberof StudentTopicsApiFindAllStudentTopics
     */
    readonly size?: number
}

/**
 * Request parameters for findStudentTopicById operation in StudentTopicsApi.
 * @export
 * @interface StudentTopicsApiFindStudentTopicByIdRequest
 */
export interface StudentTopicsApiFindStudentTopicByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof StudentTopicsApiFindStudentTopicById
     */
    readonly id: string
}

/**
 * StudentTopicsApi - object-oriented interface
 * @export
 * @class StudentTopicsApi
 * @extends {BaseAPI}
 */
export class StudentTopicsApi extends BaseAPI {
    /**
     * 
     * @summary Find all Topics
     * @param {StudentTopicsApiFindAllStudentTopicsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentTopicsApi
     */
    public findAllStudentTopics(requestParameters: StudentTopicsApiFindAllStudentTopicsRequest = {}, options?: RawAxiosRequestConfig) {
        return StudentTopicsApiFp(this.configuration).findAllStudentTopics(requestParameters.industries, requestParameters.levels, requestParameters.search, requestParameters.faculty, requestParameters.bookmarked, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Topic by ID.
     * @param {StudentTopicsApiFindStudentTopicByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentTopicsApi
     */
    public findStudentTopicById(requestParameters: StudentTopicsApiFindStudentTopicByIdRequest, options?: RawAxiosRequestConfig) {
        return StudentTopicsApiFp(this.configuration).findStudentTopicById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllStudentTopicsLevelsEnum = {
    Bsc: 'bsc',
    Msc: 'msc',
    Phd: 'phd'
} as const;
export type FindAllStudentTopicsLevelsEnum = typeof FindAllStudentTopicsLevelsEnum[keyof typeof FindAllStudentTopicsLevelsEnum];
