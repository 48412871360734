import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../../contexts/userContext";
import Loader from "../../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../../config/https";
import { useTranslation } from "react-i18next";
import { ArrowPathIcon, FolderIcon } from "@heroicons/react/24/solid";
import "moment/locale/de";
import { ThesisProjectState, UserType } from "../../../models";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import ThesisProjects from "../../thesis-projects/ThesisProjects";

const PAGE_SIZE = 50;

export default function AdminThesisProjects() {
  const { currentUser } = useContext(CurrentUserContext);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [token, setToken] = useState("");
  const [thesisProjects, setThesisProjects] = useState<any>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialStatesFromURL = searchParams.getAll("state");
  const initialStatesFromLocalStorage = JSON.parse(localStorage.getItem("admin-thesis-project-states") || "[]");
  const initialStates = initialStatesFromURL?.length > 0 ? initialStatesFromURL : initialStatesFromLocalStorage;
  const [selectedStates, setSelectedStates] = useState<string[]>(initialStates);
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const isStudent = currentUser?.type === UserType.Student;
  const isExpert = currentUser?.type === UserType.Expert;
  const currentLanguage = i18n.language;
  const [count, setCount] = useState(0);
  const pageParam = searchParams.get("page");
  const parsedPage = pageParam ? parseInt(pageParam, 10) || 1 : 1;
  const [page, setPage] = useState<number>(parsedPage);
  const [companies, setCompanies] = useState<any>([]);
  const initialCompanyFromURL = searchParams.get("company");
  const initialCompanyFromLocalStorage = localStorage.getItem("admin-thesis-project-company");
  const initialCompany = initialCompanyFromURL ? initialCompanyFromURL : initialCompanyFromLocalStorage;
  const [selectedCompany, setSelectedCompany] = useState(initialCompany);
  const [isFetchingCompanies, setIsFetchingCompanies] = useState(true);

  const getThesisProjects = async () => {
    setIsFetching(true);
    const params = new URLSearchParams();
    selectedStates.forEach((state) => params.append("state", state));
    if (selectedCompany) {
      params.append("company", selectedCompany.toString());
    }
    params.append("page", page.toString());
    params.append("size", PAGE_SIZE.toString());

    await httpsClient
      .get(`/api/v2/admin/thesis-projects?${params.toString()}`)
      .then((response) => {
        const sortedItems = response.data?.items?.sort((a: any, b: any) => {
          const aDate = a.updated ? new Date(a.updated) : new Date(a.created);
          const bDate = b.updated ? new Date(b.updated) : new Date(b.created);
          return bDate.getTime() - aDate.getTime();
        });
        setThesisProjects(sortedItems || []);
        setCount(response.data?.total);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        }
        console.error("Error fetching data:", error);
      });
  };

  const getCompanies = async () => {
    setIsFetching(true);
    await httpsClient
      .get(`/api/v1/companies`)
      .then((response) => {
        setCompanies(response.data);
        setIsFetchingCompanies(false);
      })
      .catch((error) => {
        setIsFetchingCompanies(false);
      });
  };

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  useEffect(() => {
    if (currentUser?.id) {
      getThesisProjects();
    }
  }, [currentUser, selectedStates, selectedCompany, page]);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    localStorage.setItem("admin-thesis-project-states", JSON.stringify(selectedStates));
    if (selectedCompany) {
      localStorage.setItem("admin-thesis-project-company", selectedCompany);
    } else {
      localStorage.removeItem("admin-thesis-project-company");
    }
    const params = new URLSearchParams();
    selectedStates?.forEach((state) => params.append("state", state));
    params.append("page", page.toString());
    if (selectedCompany) {
      params.append("company", selectedCompany.toString());
    }
    navigate(`?${params.toString()}`, { replace: true });
  }, [selectedStates, selectedCompany, page]);

  const handleCheckboxChange = (option: string) => {
    setPage(1);
    setCount(0);
    setSelectedStates((prevStates) =>
      prevStates.includes(option) ? prevStates.filter((state) => state !== option) : [...prevStates, option]
    );
  };

  const handleCompanyChange = (id: string) => {
    setPage(1);
    setCount(0);
    setSelectedCompany(id);
  };

  const handleRemoveCompany = () => {
    setPage(1);
    setCount(0);
    setSelectedCompany("");
  };

  const handleNextPage = () => {
    setCount(0);
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  const handleReloadThesisProjects = () => {
    setCount(0);
    if (page > 1) {
      setPage(1);
    } else {
      getThesisProjects();
    }
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      <div className="pb-6">
        <div className="mt-2 flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl tracking-tight">
              {t("common.thesisProjects")}
            </h2>
          </div>
        </div>
      </div>
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          {t("common.filters")}
        </h2>
        <div className="border-b border-gray-100 bg-white pb-6 mb-6">
          <div className="mx-auto flex items-center justify-between">
            <Listbox value={selectedCompany} onChange={handleCompanyChange}>
              <div className="relative w-44 sm:w-56">
                <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none text-sm sm:leading-6 cursor-pointer">
                  <span className="block truncate">
                    {selectedCompany && !isFetchingCompanies ? (
                      <>{companies.filter((company: any) => company.id === selectedCompany)[0]?.name}</>
                    ) : (
                      <span className="text-gray-400">{t("common.allCompanies")}</span>
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  </span>
                </ListboxButton>
                <ListboxOptions
                  transition
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in text-sm"
                >
                  <Listbox.Option
                    key={"allCompanies"}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-gray-900 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                      )
                    }
                    value={null}
                    onClick={() => handleCompanyChange("")}
                  >
                    <>
                      <span className="font-normal block truncate">{t("common.allCompanies")}</span>
                    </>
                  </Listbox.Option>
                  {companies.map((company: any) => (
                    <ListboxOption
                      key={company.id}
                      value={company.id}
                      className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-gray-900 data-[focus]:text-white cursor-pointer"
                    >
                      <span className="block truncate font-normal group-data-[selected]:font-semibold">
                        {company.name}
                      </span>

                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-900 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                        <CheckIcon aria-hidden="true" className="h-5 w-5" />
                      </span>
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </Listbox>
            <div className="block">
              <div className="flex">
                <PopoverGroup className="-mx-4 flex items-center">
                  <Popover className="relative inline-block pr-4 text-left">
                    <PopoverButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      <span>State</span>
                      {selectedStates?.length > 0 && (
                        <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                          {selectedStates.length}
                        </span>
                      )}
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </PopoverButton>
                    <PopoverPanel
                      transition
                      className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <form className="space-y-4">
                        {Object.values(ThesisProjectState).map((state) => (
                          <div key={state} className="flex items-center">
                            <input
                              id={`filter-${state}`}
                              name={`${state}`}
                              type="checkbox"
                              checked={selectedStates.includes(state)}
                              onChange={() => handleCheckboxChange(state)}
                              className="h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-indigo-500 cursor-pointer"
                            />
                            <label
                              htmlFor={`filter-${state}`}
                              className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 cursor-pointer"
                            >
                              {t(`thesisProjects.state.${state}`)}
                            </label>
                          </div>
                        ))}
                      </form>
                    </PopoverPanel>
                  </Popover>
                </PopoverGroup>
                <button
                  type="button"
                  className="ml-6 h-6 w-6 text-gray-400 hover:text-gray-600"
                  onClick={() => handleReloadThesisProjects()}
                >
                  <ArrowPathIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        {(selectedCompany || selectedStates?.length > 0) && (
          <div className="bg-gray-50 -mx-6 sm:-mx-8 mb-6">
            <div className="mx-auto px-6 sm:px-8 py-3 sm:flex sm:items-center">
              <h3 className="text-sm font-medium text-gray-500 hidden sm:block flex-shrink-0">
                {t("common.filters")}
                <span className="sr-only">, active</span>
              </h3>
              <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />
              <div className="mt-0 sm:ml-4">
                <div className="-m-1 flex flex-wrap items-center">
                  {selectedCompany && !isFetchingCompanies && (
                    <span
                      key={selectedCompany}
                      className="m-1 inline-flex items-center rounded-full border border-yellow-600/20 bg-yellow-50 py-1.5 pl-3 pr-2 text-sm font-medium text-yellow-800"
                    >
                      <span>{companies.filter((company: any) => company.id === selectedCompany)[0]?.name}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-yellow-700 hover:bg-yellow-200 hover:text-yellow-800"
                        onClick={() => handleRemoveCompany()}
                      >
                        <span className="sr-only">Remove filter for {selectedCompany}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                      </button>
                    </span>
                  )}
                  {selectedStates.map((state) => (
                    <span
                      key={state}
                      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                    >
                      <span>{t(`thesisProjects.state.${state}`)}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                        onClick={() => handleCheckboxChange(state)}
                      >
                        <span className="sr-only">Remove filter for {state}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <ThesisProjects thesisProjects={thesisProjects} />
      )}
      {isAuthenticated && !isLoading && !isFetching && thesisProjects?.length === 0 && (
        <div className="text-center">
          <FolderIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">{t("common.noThesisProjects")}</h3>
        </div>
      )}
      {count > 0 && (
        <nav className="flex items-center justify-between bg-white py-6" aria-label="Pagination">
          <div className="sm:block">
            <p className="text-sm text-gray-700">
              {count >= PAGE_SIZE && (
                <>
                  <span className="font-semibold">{PAGE_SIZE * (page - 1) + 1}</span> {t("common.to")}{" "}
                  <span className="font-semibold">{page >= Math.ceil(count / PAGE_SIZE) ? count : PAGE_SIZE * page}</span> {t("common.of")}
                </>
              )}{" "}
              <span className="font-semibold">{count}</span> {count === 1 ? t("common.thesisProject") : t("common.thesisProjects")}
            </p>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              onClick={handlePrevPage}
              disabled={page === 1}
              className="relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus-visible:outline-offset-0 disabled:opacity-50"
            >
              {t("common.previous")}
            </button>
            <button
              onClick={handleNextPage}
              disabled={page >= Math.ceil(count / PAGE_SIZE)}
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus-visible:outline-offset-0 disabled:opacity-50"
            >
              {t("common.next")}
            </button>
          </div>
        </nav>
      )}
    </div>
  );
}
