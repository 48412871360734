import React from "react";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import { ThesisProjectState } from "../../models";
import { CheckIcon } from "@heroicons/react/24/solid";

const steps = [
  { id: "1", name: "applied", items: ["item1", "item2", "item3"] },
  { id: "2", name: "agreed", items: ["item1", "item2"] },
  { id: "3", name: "completed" },
];

interface Props {
  state: ThesisProjectState; // e.g. "applied" | "agreed" | "completed"
}

const ThesisProjectStep: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation();

  /**
   * Check if a step should be "checked" (dark background with a check icon).
   */
  const checkIsCompleted = (stepName: string): boolean => {
    switch (state) {
      case ThesisProjectState.Applied: // "applied"
        // Step 1 is checked, Steps 2 & 3 are not
        return stepName === "applied";

      case ThesisProjectState.Agreed: // "agreed"
        // Step 1 & 2 are checked, Step 3 is not
        return stepName === "applied" || stepName === "agreed";

      case ThesisProjectState.Completed: // "completed"
        // All steps are checked
        return true;

      default:
        return false;
    }
  };

  /**
   * Check if a step is the "current" step (dark border).
   */
  const checkIsCurrent = (stepName: string): boolean => {
    switch (state) {
      case ThesisProjectState.Applied:
        // Step 2 (agreed) is the border
        return stepName === "agreed";

      case ThesisProjectState.Agreed:
        // Step 3 (completed) is the border
        return stepName === "completed";

      case ThesisProjectState.Completed:
        // None is the border, since everything is completed
        return false;

      default:
        return false;
    }
  };

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-white/5 shadow-sm ring-1 ring-gray-900/5 rounded-lg md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => {
          // Determine visual state
          const completed = checkIsCompleted(step.name);
          const current = checkIsCurrent(step.name);

          return (
            <li key={step.name} className="relative md:flex md:flex-1">
              {/* Step display */}
              <div className="flex items-start px-6 py-4 text-sm font-medium">
                <span className="flex-shrink-0">
                  {completed ? (
                    // Checked step (dark background + check icon)
                    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-900">
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  ) : current ? (
                    // Current step (dark border)
                    <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-900">
                      <span className="text-gray-900 font-semibold">{step.id}</span>
                    </span>
                  ) : (
                    // Default step (gray border)
                    <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                      <span className="text-gray-500 font-semibold">{step.id}</span>
                    </span>
                  )}
                </span>

                <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                  {/* Step Title */}
                  <span
                    className={`text-sm font-semibold pt-2 ${
                      completed || current ? "text-gray-900" : "text-gray-500"
                    }`}
                  >
                    {t(`thesisProjects.state.${step.name}`)}
                  </span>

                  {/* Step items (if any) */}
                  {step.items && (
                    <ul className="list-disc text-gray-500 font-normal text-xs pt-3 pl-6 gap-2 leading-4">
                      {step.items.map((item) => (
                        <li key={`step-${step.name}-${item}`} className="pb-1">
                          {t(`thesisProjects.steps.${step.name}.${item}`)}
                        </li>
                      ))}
                    </ul>
                  )}
                </span>
              </div>

              {/* Connector for next step, except for the last item */}
              {stepIdx !== steps.length - 1 && (
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-200"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentColor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default ThesisProjectStep;
